// import JSEncrypt from 'jsencrypt/bin/jsencrypt.min'

// 密钥对生成 http://web.chacuo.net/netrsakeypair

const publicKey = 'MFwwDQYJKoZIhvcNAQEBBQADSwAwSAJBAKoR8mX0rGKLqzcWmOzbfj64K8ZIgOdH\n' +
  'nzkXSOVOZbFu/TJhZ7rFAN+eaGkl3C4buccQd/EjEsj9ir7ijT7h96MCAwEAAQ=='

const privateKey = 'MIIBVAIBADANBgkqhkiG9w0BAQEFAASCAT4wggE6AgEAAkEAqhHyZfSsYourNxaY\n' +
  '7Nt+PrgrxkiA50efORdI5U5lsW79MmFnusUA355oaSXcLhu5xxB38SMSyP2KvuKN\n' +
  'PuH3owIDAQABAkAfoiLyL+Z4lf4Myxk6xUDgLaWGximj20CUf+5BKKnlrK+Ed8gA\n' +
  'kM0HqoTt2UZwA5E2MzS4EI2gjfQhz5X28uqxAiEA3wNFxfrCZlSZHb0gn2zDpWow\n' +
  'cSxQAgiCstxGUoOqlW8CIQDDOerGKH5OmCJ4Z21v+F25WaHYPxCFMvwxpcw99Ecv\n' +
  'DQIgIdhDTIqD2jfYjPTY8Jj3EDGPbH2HHuffvflECt3Ek60CIQCFRlCkHpi7hthh\n' +
  'YhovyloRYsM+IS9h/0BzlEAuO0ktMQIgSPT3aFAgJYwKpqRYKlLDVcflZFCKY7u3\n' +
  'UP8iWi1Qw0Y='

// 加密
export function encrypt(txt) {
  // const encryptor = new JSEncrypt()
  // encryptor.setPublicKey(publicKey) // 设置公钥
  // return encryptor.encrypt(txt) // 对数据进行加密
  return txt // TODO 暂不加密 liyuy
}

/**
 * 使用指定的密钥加密字符串
 * @param {String} txt 需要加密的内容
 * @param {String} key 加密的key
 * @returns 加密后的字符串
 */
export function encryptWithKey(txt, key) {
  // const encryptor = new JSEncrypt()
  // encryptor.setPublicKey(key) // 设置公钥
  // return encryptor.encrypt(txt) // 对数据进行加密
  return txt // TODO 暂不加密 liyuy
}

// 解密
export function decrypt(txt) {
  // const encryptor = new JSEncrypt()
  // encryptor.setPrivateKey(privateKey) // 设置私钥
  // return encryptor.decrypt(txt) // 对数据进行解密
  return txt // TODO 暂不加密 liyuy
}

/**
 * 使用指定的密钥解密字符串
 * @param {String} txt 需要解密的内容
 * @param {String} key 解密的key
 * @returns 解密后的字符串
 */
export function decryptWithKey(txt, key) {
  // const encryptor = new JSEncrypt()
  // encryptor.setPrivateKey(key) // 设置私钥
  // return encryptor.decrypt(txt) // 对数据进行解密
  return txt // TODO 暂不加密 liyuy
}

