<template>
  <div class="app-container">
    <navbar />
    <!-- 搜索工作栏 -->
    <div class="page_contain">
      <!-- 左侧编目维度 -->
      <div class="catalog_dimension">
        <div>
          <el-input v-model="treeName1" placeholder="请输入内容" clearable size="middle" prefix-icon="el-icon-search"
            style="margin: 20px 0; width: 85%;  left: -8px;" />
          <svg-icon icon-class="setting" class="btn_setting" @click="showDialogRootCatalog" />
        </div>
        <div>
          <el-tree :data="treeOptions" :props="defaultProps" :expand-on-click-node="false" lazy :load="loadNode"
            :filter-node-method="filterNode" ref="tree" :highlight-current="true" @node-click="handleNodeClick" />
        </div>
      </div>

      <!-- 右侧列表 -->
      <div class="portal_container">
        <div>
          <el-form :model="queryParams" ref="queryForm" size="middle" :inline="true" @submit.native.prevent>
            <el-form-item>
              <div>
                <el-input v-model="treeName2" placeholder="请输入内容" clearable size="middle" prefix-icon="el-icon-search" />
              </div>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" icon="el-icon-search" @click="handleQuery">查询</el-button>
              <el-button type="primary" @click="(showDialogCatalog(null, true))">新增</el-button>
            </el-form-item>
          </el-form>

          <!-- 查询结果 -->
          <el-table v-loading="loading" :data="catalogListSearch" row-key="id" :default-expand-all="isExpandAll"
            :tree-props="{ children: 'children', hasChildren: 'hasChildren' }" stripe border>
            <el-table-column label="编目名称" key="catalogName" prop="catalogName" :show-overflow-tooltip="true" />
            <el-table-column align="center" label="编目编码" key="catalogCode" prop="catalogCode"
              :show-overflow-tooltip="true" />
            <el-table-column align="center" label="编目描述" key="catalogDescription" prop="catalogDescription"
              :show-overflow-tooltip="true" />
            <el-table-column align="center" min-width="100px" label="操作" class-name="small-padding fixed-width">
              <template v-slot="scope">
                <el-button size="mini" type="text" @click="showDialogCatalog(scope.row, true)">新增</el-button>
                <el-button v-if="scope.row.catalogPid" size="mini" type="text" @click="showDialogCatalog(scope.row, false)">编辑</el-button>
                <el-button size="mini" type="text" @click="handleDelete(scope.row)">删除</el-button>
                <el-button size="mini" type="text" @click="showDialogUser(scope.row)">用户管理</el-button>
                <!-- <el-button size="mini" type="text" @click="handleUpdate(scope.row)">上移</el-button>
                <el-button size="mini" type="text" @click="handleUpdate(scope.row)">下移</el-button> -->
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>

    <!-- 根编目管理 -->
    <dialogRootCatalog :root_dialog_open="root_dialog_open" :rootList="rootList" :rootTableKey="rootTableKey"
      @closeDialog="closeDialog" @readyAddRoot="readyAddRoot" @addRoot="addRoot" @deleteRoot="deleteRoot"
      @updateRoot="updateRoot" @cancelRoot="cancelRoot" @editCatalog="editCatalog" />

    <!-- 编目管理 -->
    <dialogCatalog ref="dialogCatalog" :catalog_dialog_open="catalog_dialog_open" :userList="userList"
      :catalogList="allCatalogs" :catalogParent="catalogParent" @closeDialog="closeDialog" @addCatalog="addCatalog"
      @editCatalog="editCatalog" :dialogCatalogTitle="dialogCatalogTitle" :treeOptions="treeOptions"
      @loadNode="loadNode" />

    <!-- 用户管理 -->
    <dialogUser :user_dialog_open="user_dialog_open" :userList="userList" :catalogUserList="catalogUserList"
      @closeDialog="closeDialog" @addUser="addUser" @deleteUser="deleteUser" />
  </div>
</template>

<script>
import {
  addRootCatalogRequest, deleteCatalogRequest, addCatalogRequest, editCatalogRequest,
  listCatalogUser, addCatalogUser, deleteCatalogUser
} from "@/api/system/catalog";
import { listSimpleDepts, rootCatalog, childCatalog, listUser } from "@/api/system/dept";
import Navbar from '@/components/common/Navbar.vue'
import dialogRootCatalog from "./dialogs/dialogRootCatalog.vue";
import dialogCatalog from "./dialogs/dialogCatalog.vue";
import dialogUser from "./dialogs/dialogUser.vue"

export default {
  name: "Catalog",
  components: { Navbar, dialogRootCatalog, dialogCatalog, dialogUser },
  data() {
    return {
      // 是否展开，默认全部展开
      isExpandAll: true,
      // 遮罩层
      loading: true,
      // 总条数
      total: 0,
      // 目录列表数据
      catalogList: [],
      // 目录列表数据（过滤之后的）
      catalogListSearch: [],
      allCatalogs:[], // 懒加载但记录所有编目，用于dialogCatalog中懒加载el-select el-tree的回显
      // 用户下拉列表
      userList: [],
      // 已关联用户
      catalogUserList: [],
      // 编目根节点数据
      // rootMap: {},
      rootList: [],
      // 编目列表
      treeList: [],
      // 弹出层标题
      title: "",
      // 新增编目父节点
      catalogParent: undefined,
      // 部门树选项
      treeOptions: undefined,
      // 是否显示弹出层
      root_dialog_open: false,
      catalog_dialog_open: false,
      user_dialog_open: false,
      dialogCatalogTitle: '',   // 编目弹窗标题
      // 编目维度名称，搜索框用*2
      treeSearch: undefined,
      treeName1: undefined,
      treeName2: undefined,
      defaultProps: {
        children: "children",
        isLeaf: "leaf",
        label: "name"
      },
      // 查询参数
      queryParams: {
        pageNo: 1,
        pageSize: 10,
        name: '',
        catalogId: 1,
        sort: 1  // 1更新时间 2更新周期 3访问排序
      },
      userDialogId: null, // 关联用户弹窗，当前catalogId
      // table绑定key，更新key可以重新渲染table
      rootTableKey: 1
    };
  },
  watch: {
    // 根据名称筛选部门树
    treeName1(val) {
      if(!val && this.treeName2) return;
      this.treeName2 = undefined;
      this.treeSearch = val;
    },
    // treeName2(val) {
    //   if(!val && this.treeName1) return;
    //   this.treeName1 = undefined;
    //   this.treeSearch = val;
    // },
    treeSearch(val){
      this.$refs.tree.filter(val);
      console.log('catalogList: ', this.catalogList)
      if(!val) return this.catalogListSearch = this.catalogList
      const regex = RegExp(val, 'i')
      this.catalogListSearch = this.catalogList.filter(i => i.name.match(regex))
    },
    catalogList: {
      deep: true, 
      handler(val) {
          this.catalogListSearch = val
      }
    }
  },
  created() {
    this.refreshTree();
    this.getUserList();

  },
  methods: {
    // 获取用户下拉列表
    getUserList() {
      listUser().then(response => {
        if (response.data) {
          this.userList = response.data.list || [];
        }
      }
      );
    },
    refreshTree() {
      this.treeList = []
      this.getTreeRoot(this.getTreeFirstLever) // 懒加载
    },
    // 查询编目维度根节点
    getTreeRoot(callback) {
      rootCatalog().then(response => {
        this.rootList = this.formatTreeNode(response.data)
        this.catalogList = this.rootList
        callback && callback()
      });
    },
    getTreeFirstLever() {
      this.treeOptions = [];
      this.treeOptions.push(...this.handleTree(this.rootList, "id"));
      this.loading = false
    },
    // 懒加载树
    loadNode(node, resolve) {
      // console.log("lazy load", node, resolve)
      if (!node.id || !node.data || node.data instanceof Array) return resolve([])
      this.getTreeChild(node.data, resolve)
    },
    // 查询根节点下的编目，待优化
    getTreeChild(root, callback) {
      childCatalog({ id: root.catalogId }).then(response => {
        if (response.data) {
          let res_arr = this.formatTreeNode(response.data)
          this.loading = false;
          res_arr.forEach(i => {
            !this.treeList.find(item => item.id == i.id) && this.treeList.push(i)
          })

          // this.treeList = this.treeList.concat(res_arr)
          this.allCatalogs = this.rootList.concat(this.treeList)
          this.catalogList = [];
          this.catalogList.push(...this.handleTree(this.allCatalogs, "id"));
          // console.log('get tree child catalog list', this.catalogList, this.allCatalogs);
          callback && callback(res_arr)
        }
      }, err => {
        this.$message.error(err.message)
      })

    },
    // 预处理树状结构
    formatTreeNode(arr) {
      if (!arr || !arr instanceof Array) return []
      arr.forEach(i => {
        i.id = i.id || i.catalogId
        i.name = i.name || i.catalogName
        i.parentId = i.parentId || i.catalogPid || 0
        i.isEdit = false // 是否编辑
        i.isAdd = false  // 是否新增
      })
      return arr
    },
    // 筛选节点
    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    // 节点单击事件
    handleNodeClick(data) {
      // console.log('nodeclick------', data)
    },
    /** 搜索按钮操作 */
    handleQuery() {
      console.log(this.treeName2, this.treeName1)
      if(!this.treeName2 && this.treeName1) return;
      this.treeName1 = undefined;
      this.treeSearch = this.treeName2;
    },
    // 删除编目二次确认
    handleDelete(row) {
      this.$confirm('此操作将永久删除此信息，确定继续？')
        .then(() => {
          deleteCatalogRequest(row.id).then(res => {
            this.$message.success('删除成功')
            // 请求刷新：非根编目被删除，重新请求编目 TODO 待优化？
            this.refreshTree()
          }, err => {
            this.$message.error(err)
          })
        })

        .catch(() => {
          // this.$message.info('取消')
        })
    },
    //-------------------------------------------------
    // 根编目管理弹窗
    showDialogRootCatalog() {
      this.root_dialog_open = true
    },
    // 增加编目
    showDialogCatalog(row, isAdd) {
      if (isAdd) {
        let parent = row ? row : undefined
        this.catalog_dialog_open = true
        this.dialogCatalogTitle = "新增编目"
        this.$refs.dialogCatalog.reset(parent)
      } else {
        this.catalog_dialog_open = true
        this.dialogCatalogTitle = "编辑编目"
        this.$refs.dialogCatalog.edit(row)
      }
    },
    // 用户管理弹窗
    showDialogUser(row) {
      listCatalogUser(row.id).then(res => {
        this.catalogUserList = res.data
      }, err => {
        this.catalogUserList = []
        this.$message.error(err)
      })
      this.user_dialog_open = true
      this.userDialogId = row.id
    },
    // 关闭弹窗
    closeDialog() {
      this.root_dialog_open = false
      this.catalog_dialog_open = false
      this.user_dialog_open = false
    },
    //-------------------------------------------------
    readyAddRoot() {
      this.rootList.push({
        catalogId: this.rootList.length + 1,
        catalogName: '',
        isEdit: true,
        isAdd: true
      })
    },
    addRoot(row) {
      addRootCatalogRequest({ catalogName: row.catalogName }).then(res => {
        this.$message.success('新增成功')
        // 请求刷新
        this.refreshTree()
        this.closeDialog()
      }, err => {
        this.$message.error(err)
      })
    },
    editCatalog(row) {
      editCatalogRequest(row).then(res => {
        this.$message.success('更新成功')
        // 请求刷新
        this.refreshTree()
        this.closeDialog()
      }, err => {
        this.$message.error(err)
      })
    },
    deleteRoot(row) {
      this.handleDelete(row)
    },
    updateRoot(row) {
      // 备份原始数据
      row['oldRow'] = JSON.parse(JSON.stringify(row))
      row.isEdit = true
      this.rootTableKey = Math.random()
    },
    cancelRoot(row, index) {
      // 如果是新增的数据
      if (row.isAdd) {
        this.rootList.splice(index, 1)
      } else {
        // 不是新增的数据  还原数据
        for (const i in row.oldRow) {
          row[i] = row.oldRow[i]
        }
      }
      this.rootTableKey = Math.random()
    },
    //-------------------------------------------------
    addCatalog(form) {
      addCatalogRequest(form).then(res => {
        this.$message.success('新增成功')
        // 请求刷新
        this.refreshTree()
        this.closeDialog()
      }, err => {
        this.$message.error(err)
      })
    },
    //-------------------------------------------------
    addUser(userIds) {
      addCatalogUser({ catalogId: this.userDialogId, userIds }).then(res => {
        this.$message.success('关联成功')
        this.showDialogUser({ id: this.userDialogId })
      }, err => {
        this.$message.error(err)
      })
    },
    deleteUser(id) {
      deleteCatalogUser(id).then(res => {
        this.showDialogUser({ id: this.userDialogId })
      }, err => {
        this.$message.error(err)
      })
    }
  },

};
</script>
<style lang="less" scoped>
.page_contain {
  position: relative;
  height: 930px;
  margin: 0 20px;
}

/* 编目维度 */
.catalog_dimension {
  text-align: center;
  position: absolute;
  left: 0;
  padding: 0 10px !important;
  background-color: #06346e;
  /* border: 3px solid #05559b; */
  border-radius: 5px;
  height: 100%;
  width: 16%;
  background: url('../../assets/svg/bg_tree.svg');
  background-size: 100% 100%;
}

/* 搜索结果 */
.portal_container {
  position: absolute;
  right: 0;
  /* border: 2px solid #555; */
  width: 83%;
}

.btn_setting {
  font-size: 21px;
  color: #82ceff;
}

.btn_setting:hover {
  cursor: pointer;
  color: #9ee6ff;
}

</style>
