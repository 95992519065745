<template>
  <div class="app-container">
    <navbar />
    <!-- 列表展示 -->
    <div class="page_contain" v-show="showList">
      <el-form :model="queryParams" ref="queryForm" size="middle" :inline="true">
        <el-row>
          <el-form-item>
            <el-radio-group v-model="queryParams.stateArr" @change="handleQuery">
              <el-radio-button v-for="item in dict_dir_subscribe_manager_type" :label="parseInt(item.value)">
                {{ item.label }}
              </el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item>
            <el-input v-model="queryParams.name" placeholder="请输入内容" clearable prefix-icon="el-icon-search" />
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="handleQuery">查询</el-button>
          </el-form-item>
        </el-row>
      </el-form>
      <!-- 表格展示 -->
      <div class="portal_container">
        <!-- 查询结果 -->
        <el-table ref="form" v-loading="loading" :data="subscribeList" row-key="id" stripe border>
          <el-table-column align="center" width="58" label="序号" key="id" prop="id">
            <template slot-scope="scope">
              {{ (queryParams.pageNo - 1) * queryParams.pageSize + scope.$index + 1 }}
            </template>
          </el-table-column>
          <el-table-column align="center" min-width="200px" label="目录名称" key="dirName" prop="dirName" />
          <el-table-column align="center" label="发布部门" key="sourceDeptName" prop="sourceDeptName" />
          <el-table-column align="center" label="订阅部门" key="deptName" prop="deptName" />
          <el-table-column align="center" label="订阅类型" key="purpose" prop="purpose" :formatter="purposeFormat" />
          <el-table-column align="center" label="订阅任务发起时间" key="createTime" prop="createTime"
            :formatter="timeFormatter" />
          <el-table-column align="center" label="操作" class-name="small-padding fixed-width">
            <template v-slot="scope">
              <el-button size="mini" type="text" @click="rowDetail(scope.row)">详情</el-button>
              <!-- <el-button size="mini" type="text" @click="rowNoPass(scope.row)">驳回</el-button>
              <el-button size="mini" type="text" @click="rowHandle(scope.row)">处理</el-button> -->
            </template>
          </el-table-column>
        </el-table>
        <pagination v-show="total > 0" :total="total" :page.sync="queryParams.pageNo" :limit.sync="queryParams.pageSize"
          @pagination="handleQuery" />
      </div>
    </div>

    <SubscribeDetail class="page_contain" v-show="!showList" ref="detail" :catalog="catalog" @clickBack="clickBack"
      :rootMap="rootMap" :userList="userList" />
    <DialogNoPass ref="noPass" :noPassDialogOpen="noPassDialogOpen" @closeDialog="closeDialog" @saveNoPass="saveNoPass" />
  </div>
</template>

<script>
import { rootCatalog, listUser } from "@/api/system/dept";
import { pageSubscribe } from "@/api/system/subscribe"
import { detailDatadir } from "@/api/system/catalog";
import { userDepts } from "@/api/system/dept";
import Navbar from '@/components/common/Navbar.vue';
import DialogNoPass from './dialogNoPass.vue'
import SubscribeDetail from './subscribeDetail.vue'

import dict from '../mixins/dict.js'
export default {
  name: "SubscribeManager",
  components: { Navbar, DialogNoPass, SubscribeDetail },
  mixins: [dict],
  data() {
    return {
      // 遮罩层
      loading: true,
      // 查询参数
      queryParams: {
        pageNo: 1,
        pageSize: 10,
        serverType: undefined,
        stateArr: 2,  // 2未处理 3已驳回 4已处理
        name: '',
      },
      subscribeList: [],
      total: 0,
      // 弹窗
      noPassDialogOpen: false,
      showList: true, // 查看列表
      catalog: {},
      // 部门下拉
      departList: [],
      // 用户下拉
      userList: [],
      // 根编目列表
      rootList: [],
      // 根编目节点对应关系
      rootMap: {},
    };
  },
  created() {
    this.getTreeRoot()
    this.getUserList()
    this.getDepartList()
    this.handleQuery()
  },
  methods: {
    // 刷新订阅列表
    handleQuery() {
      this.getSubscribedList()
    },
    // 请求订阅列表
    getSubscribedList(callback) {
      pageSubscribe(this.queryParams).then(
        response => {
          if (response) {
            this.subscribeList = response.list || [];
            // let arr = response.list || [];
            // this.subscribeList = this.datadirFilter(arr)
            this.total = response.total;
            this.loading = false
            callback && callback()
          }
        }, err => {
          this.$message.error(err.message)
        })
    },
    // 获取部门下拉列表
    getDepartList() {
      userDepts().then(response => {
        if (response.data) {
          this.departList = response.data || [];
        }
      }, err => {
        this.$message.error(err.message)
      });
    },
    // 获取用户下拉列表
    getUserList() {
      listUser().then(response => {
        if (response.data) {
          this.userList = response.data.list || [];
        }
      }, err => {
        this.$message.error(err.message)
      });
    },
    // 查询编目维度根节点
    getTreeRoot(callback) {
      rootCatalog().then(response => {
        this.rootList = this.formatTreeNode(response.data)
        this.rootList.forEach(item => {
          if (/部门/.test(item.catalogName)) {
            return this.rootMap[item.catalogId] = 'dept'
          }
          if (/基础/.test(item.catalogName)) {
            return this.rootMap[item.catalogId] = 'basic'
          }
          if (/主题/.test(item.catalogName)) {
            return this.rootMap[item.catalogId] = 'topic'
          }
        })
        // this.rootList.sort((a, b) => a.catalogId - b.catalogId)  // 按照根节点的 catalogId 排序
        callback && callback()
      });
    },
    // 预处理树状结构
    formatTreeNode(arr) {
      if (!arr || !arr instanceof Array) return []
      arr.forEach(i => {
        i.id = i.id || i.catalogId
        i.name = i.name || i.catalogName
        i.parentId = i.parentId || i.catalogPid || 0
      })
      return arr
    },
    // 处理来源组织（所属部门）label_dept
    datadirFilter(arr) {
      arr.forEach(item => {
        if (item.catalogs instanceof Array) {
          item.catalogs.forEach(catalog => {
            let key = 'label_' + this.rootMap[catalog.catalogRootId]
            item[key] = catalog.catalogName
          })
        }
      })
      this.tableKey = Math.random()
    },
    clickBack() {
      this.showList = true
    },
    // 弹窗--------------------------------------------
    closeDialog(server) {
      this.noPassDialogOpen = false
      if (server) {
        this.handleQuery()  // 请求刷新
      }
    },
    // 发送驳回请求
    saveNoPass(formNoPass) {
      console.log('saveNoPass', formNoPass);
      // TODO 发送请求

      this.noPassDialogOpen = false
    },
    // 表格--------------------------------------------
    rowDetail(row) {
      let obj = row
      detailDatadir(row.dirId).then(res => {
        if (res && res.data) {
          this.catalog = res.data
          this.$refs['detail'].init(row, res.data)
        } else {
          this.catalog = {}
          this.$refs['detail'].init({})
          this.$message.error('系统繁忙')
        }
      }, err => {
        this.$message.error(err.message)
      });
      this.activeTab = 'first'
      this.showList = false
    },
    rowNoPass(row) {
      this.noPassDialogOpen = true
      this.$refs.noPass.reset(row)
    },
    rowHandle(row) {

    },
  },

};
</script>
<style lang="less" scoped>
.page_contain {
  position: relative;
  min-height: 930px;
  margin: 0 20px;
}

/deep/ .el-button--info,
.el-button--info:focus,
.el-button--info:hover {
  background: #1f499b;
  border: 1px solid #045b9f;
}

.btn_change {
  padding: 12px 12px;
}
</style>
