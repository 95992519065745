<template>
    <!-- 挂载数据-文件 -->
    <div>
        <el-button type="text" @click="handleClickBack">返回</el-button>
        <el-button type="primary" @click="handleClickBind">保存</el-button>
        <div class="bind_catalog">
            <el-form ref="form" :model="form" label-width="180px" :rules="rules">
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="目录名称" prop="name">{{ datadir.name }}</el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="文件服务器" prop="dsId">
                            <el-select v-model="form.dsId" placeholder="请选择">
                                <el-option v-for="item in datasourceList" :key="parseInt(item.dsId)" :label="item.dsName"
                                    :value="parseInt(item.dsId)"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="文件路径" prop="path">
                            <el-input v-model="form.path" placeholder="请输入内容"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="文件名" prop="fileName">
                            <el-input v-model="form.fileName" placeholder="请输入内容"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="上传文件" prop="file">
                            <el-upload class="upload-demo" ref="upload" :action="uploadFileUrl" :on-change="handleChange"
                                :on-remove="handleRemove" :data="form" :headers="headers" :file-list="fileList"
                                :auto-upload="true" :on-success="handleUploadSuccess" :on-error="handleUploadError">
                                <el-button slot="trigger" size="small" type="primary">上传</el-button>
                            </el-upload>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>

        </div>
    </div>
</template>

<script>
import { listDatasource, bindFile } from "@/api/system/bindsource";
import { getAccessToken } from '@/utils/auth'
export default {
    name: "DatadirBindFILE",
    props: [
        'clickBack',
        // 'datadir',
    ],
    data() {
        return {
            path: null, // 上传文件返回的文件地址
            form: {
                path: null, // 前端设置的上传地址
                dirId: null
            },
            uploadFileUrl: process.env.VUE_APP_BASE_API + "/admin-api/rdm/resDir/bind/file/upload", // 请求地址
            headers: { Authorization: "Bearer " + getAccessToken() }, // 设置上传的请求头部
            // 表单校验
            rules: {
                dsId: [{ required: true, message: "文件服务器不能为空", trigger: "blur" }],
                path: [{ required: true, message: "文件路径不能为空", trigger: "blur" }],
                fileName: [{ required: true, message: "文件名不能为空", trigger: "blur" }],
            },
            datasourceList: [], // 数据源
            fileList: [],
            datadir: {},
        }
    },
    created() {
        this.getDatasourceList()
    },
    methods: {
        handleClickBack() { this.$emit('clickBack') },
        // 表单重置
        reset(obj) {
            this.fileList = []
            this.datadir = obj ? obj : {}
            let fileVoList = obj.fileVoList || []
            if (fileVoList.length > 0) {
                this.form = JSON.parse(JSON.stringify(fileVoList[0]))
                delete this.form.id
                this.fileList.push({ name: this.form.fileName, url: this.form.path })
            } else {
                this.form = {}
            }
            this.form.dirId = this.datadir.id
        },
        getDatasourceList() {
            listDatasource({ category: 'FILE' }).then(response => {
                if (response.data) {
                    this.datasourceList = response.data || [];
                }
            }, err => {
                this.$message.error(err.message)
            })
        },
        handleClickBind() {
            if (!this.path) return this.$message.error('请先上传文件')
            this.$refs['form'].validate(valid => {
                if (!valid) return
                bindFile(Object.assign({}, this.form, { dirId: this.datadir.id })).then(res => {
                    this.$message.success('挂载成功')
                    this.$emit('clickBack')
                }, err => {
                    this.$message.error(err)
                });
            })
        },
        // 上传文件---------------------------------------
        handleChange(file, fileList) {
            this.file = file.raw;
            if (fileList.length > 1) {
                fileList.shift()
            }
        },
        handleRemove() {
            this.fileList = []  // 只有一个，删掉就是空的
            this.path = undefined
        },
        handleUploadSuccess(res, file) {
            if (res.data) {
                if (res.data) this.path = res.data
            } else {
                this.$message.error(res.msg || "文件上传失败");
            }
        },
        handleUploadError() {
            this.$message.error(res.msg || "文件上传失败");
        },

    }
}
</script>

<style lang="less" scoped>
.bind_catalog {
    background-color: #0f347e;
    width: 1880px;
    height: 919px;
    border: 1px solid #067cdb;
    border-radius: 5px;
    padding: 20px;
}

.add_title {
    height: 40px;
}

/deep/.el-form-item__content {
    font-size: 18px;
}

/deep/.el-button--info,
.el-button--info:focus,
.el-button--info:hover {
    background: #1f499b;
    border: 1px solid #045b9f;
}
</style>