<template>
  <!-- 弹窗新增数据项 -->
  <el-dialog :title="datadirTitle" :visible="item_dialog_show" width="1200px" append-to-body center @close="close">
    <div slot="footer" class="dialog-footer">
      <el-form ref="form" :model="form" label-width="200px" :rules="rules">
        <el-row>
          <el-col :span="12">
            <el-form-item label="数据项名称" prop="itemName" style="text-align: left;">
              <el-input v-model="form.itemName" placeholder="请输入内容"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="字段描述" prop="itemDesc" style="text-align: left;">
              <el-input v-model="form.itemDesc" placeholder="请输入内容"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="数据类型" prop="dataType">
              <el-input v-model="form.dataType" placeholder="请输入内容"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="数据项长度" prop="dataLength" style="text-align: left;">
              <el-input v-model.number="form.dataLength" placeholder="请输入内容"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="取值范围" prop="valueRange">
              <el-input v-model="form.valueRange" placeholder="请输入内容"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="是否为空" prop="isEmpty" style="text-align: left;">
              <el-radio :label="1" v-model="form.isEmpty">是</el-radio>
              <el-radio :label="0" v-model="form.isEmpty">否</el-radio>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="是否为核心数据项" prop="isCore" style="text-align: left;">
              <el-radio :label="1" v-model="form.isCore">是</el-radio>
              <el-radio :label="0" v-model="form.isCore">否</el-radio>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="数据项样例" prop="dataExample">
              <el-input v-model="form.dataExample" placeholder="请输入内容"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="数据分级" prop="dataGrade">
              <el-select v-model="form.dataGrade" placeholder="请选择">
                <el-option v-for="item in dict_data_grade" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="共享类型" prop="shareType" style="text-align: left;">
              <el-select v-model="form.shareType" placeholder="请选择">
                <el-option v-for="item in dict_share_type" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="共享方式" prop="shareWay">
              <el-select v-model="form.shareWay" placeholder="请选择">
                <el-option v-for="item in dict_share_way" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="共享条件" prop="shareCondition" style="text-align: left;">
              <el-select v-model="form.shareCondition" placeholder="请选择">
                <el-option v-for="item in dict_share_condition" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="不予共享依据" prop="notShareReason">
              <el-input v-model="form.notShareReason" placeholder="请输入内容"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="开放类型" prop="openType">
              <el-select v-model="form.openType" placeholder="请选择">
                <el-option v-for="item in dict_open_type" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="开放条件" prop="openCondition" style="text-align: left;">
              <el-input v-model="form.openCondition" placeholder="请输入内容"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-button v-if='isAdd' type="primary" @click="saveAdd">确 定</el-button>
      <el-button v-if='!isAdd' type="primary" @click="saveEdit">确 定</el-button>
      <el-button @click="close">取消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import dict from '../mixins/dict.js'
export default {
  name: "DialogDataItem",
  mixins: [dict],
  props: [
    'datadirTitle',
    'item_dialog_show',
    'closeDialog',
    'addDataItem',
    'editDataItem',
  ],
  data() {
    return {
      rules: {
        dataType: [{ required: true, message: "数据类型不能为空", trigger: "blur" }],
        itemName: [{ required: true, message: "数据项名称不能为空", trigger: "blur" }],
        dataLength: [{ required: true, message: "数据项长度不能为空", trigger: "blur" },
        { type: 'number', message: "数据项长度必须为数字类型", trigger: 'blur' }],
      },
      form: {},
      isAdd: false,
      item_id: 0,
    }
  },
  methods: {
    close() {
      this.$emit('closeDialog')
    },
    // 表单重置
    reset() {
      this.isAdd = true
      this.resetForm("form");
      this.form = {};
    },
    edit(datadir) {
      // console.log('edit', datadir);
      this.isAdd = false
      this.form = JSON.parse(JSON.stringify(datadir))
    },
    saveAdd() {
      this.$refs['form'].validate(valid => {
        if (!valid) return
        this.$emit('addDataItem', Object.assign({}, this.form, { data_id: 'new_id_' + this.getId() }))
      })
    },
    saveEdit() {
      this.$refs['form'].validate(valid => {
        if (!valid) return
        this.$emit('editDataItem', this.form)
      })
    },
    getId() {
      const gid = ++this.item_id
      return gid
    }
  }
}
</script>

<style lang="less" scoped></style>