import {EnumFactory} from '@/utils/enumFactory'

// 日期单位类型
export const UNIT_TYPE = new EnumFactory([
  [0, '小时'],
  [1, '天'],
  [2, '月'],
  [3, '季'],
  [4, '年'],
])

// 指标类型
export const INDICATOR_TYPE = new EnumFactory([
  [0, '基础指标', 'BASIC'],
  [1, '融合指标', 'COMPOSE'],
  // [2, '衍生指标', 'DERIVE'],
  [3, '手动指标', 'MANUAL'],
  [4, '增强指标', 'ENHANCE'],
])

export const indicatorTypeMap = {
  BasicIndicator: '0',
  CompositeIndicator: '1',
  DerivationIndicator: '2',
}

// 偏移量单位
export const OFFSET_UNIT = new EnumFactory([
  [0, '天'],
  [1, '月'],
  [2, '年'],
])

// 精度列表
export const PRECISION_LIST = new EnumFactory([
  ['1', '1'],
  ['0.1', '0.1'],
  ['0.01', '0.01'],
  ['0.001', '0.001'],
  ['0.0001', '0.0001'],
  ['0.00001', '0.00001'],
])

//
export const precisionMap = {
  1: 0,
  0.1: 1,
  0.01: 2,
  0.001: 3,
  0.0001: 4,
  0.00001: 5,
}
