<template>
    <div class="main">
        <div class="btn-back" @click="handleClickBack">
            <div class="svg-back"></div>返回
        </div>
        <div style="height: 30px;"></div>

        <div>
            <div class="basic-title">基本信息</div>
            <el-form :model="datadir" label-width="240px">
                <el-row>
                    <el-col :span="12" class="basic-item">
                        <el-form-item label="部门分类：">{{ datadir.label_dept }}</el-form-item>
                    </el-col>
                    <el-col :span="12" class="basic-item">
                        <el-form-item label="目录名称：">{{ datadir.name }}</el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12" class="basic-item">
                        <el-form-item label="关联基础：">{{ datadir.label_basic }}</el-form-item>
                    </el-col>
                    <el-col :span="12" class="basic-item">
                        <el-form-item label="关联主题：">{{ datadir.label_topic }}</el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12" class="basic-item">
                        <el-form-item label="统一社会信用代码：">{{ datadir.socialCreditCode }}</el-form-item>
                    </el-col>
                    <el-col :span="12" class="basic-item">
                        <el-form-item label="目录简称：">{{ datadir.simpleName }}</el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12" class="basic-item">
                        <el-form-item label="资源描述：">{{ datadir.resourceDescribe }}</el-form-item>
                    </el-col>
                    <el-col :span="12" class="basic-item">
                        <el-form-item label="资源格式：">
                            {{ formatFromDict(datadir.resourceType, dict_resource_type) }}
                        </el-form-item></el-col>
                </el-row>
                <el-row>
                    <el-col :span="12" class="basic-item">
                        <el-form-item label="资源格式属性：">
                            {{ formatFromDict(datadir.resourceTypeAttr, dict_resource_type_attr) }}
                        </el-form-item></el-col>
                    <el-col :span="12" class="basic-item">
                        <el-form-item label="更新周期：">
                            {{ formatFromDict(datadir.updateCycle, dict_update_cycle) }}
                        </el-form-item></el-col>
                </el-row>
                <el-row>
                    <el-col :span="12" class="basic-item">
                        <el-form-item label="关联资源目录名称：">{{ datadir.relevanceResource }}</el-form-item></el-col>
                    <el-col :span="12" class="basic-item">
                        <el-form-item label="职责事项：">{{ datadir.dutyName }}</el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12" class="basic-item">
                        <el-form-item label="所用信息系统：">{{ datadir.deptName }}</el-form-item>
                    </el-col>
                    <el-col :span="12" class="basic-item">
                        <el-form-item label="审核人：">{{ formatAuditor(datadir.auditorId) }}</el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12" class="basic-item">
                        <el-form-item label="文件格式：">{{ datadir.fileFormat }}</el-form-item>
                    </el-col>
                </el-row>
            </el-form>

            <div class="basic-title">共享属性</div>
            <el-form :model="datadir" label-width="240px">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="共享方式：">
                            {{ formatFromDict(datadir.shareWay, dict_share_way) }}
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="共享类型：" prop="shareType">
                            {{ formatFromDict(datadir.shareType, dict_share_type) }}
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>

            <div class="basic-title">开放属性</div>
            <el-form :model="datadir" label-width="240px">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="开放类型：" prop="openType">
                            {{ formatFromDict(datadir.openType, dict_open_type) }}
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="开放条件：" prop="openCondition"> {{ datadir.openCondition }} </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </div>

        <div class="basic-title">数据项</div>

        <!-- 查询结果 -->
        <el-table :data="datadir.dataItems" stripe border>
            <el-table-column align="center" width="58" label="序号" key="id" prop="id">
                <template slot-scope="scope">{{ scope.$index + 1 }}</template>
            </el-table-column>
            <el-table-column align="center" width="120" label="数据项名称" key="itemName" prop="itemName" />
            <el-table-column align="center" width="120" label="数据项描述" key="itemDesc" prop="itemDesc" />
            <el-table-column align="center" width="auto" label="数据类型" key="dataType" prop="dataType" />
            <el-table-column align="center" width="auto" label="数据长度" key="dataLength" prop="dataLength" />
            <el-table-column align="center" width="auto" label="数据分级" key="dataGrade" prop="dataGrade"
                :formatter="dataGradeFormat" />
            <el-table-column align="center" width="auto" label="共享类型" key="shareType" prop="shareType"
                :formatter="shareTypeFormat" />
            <el-table-column align="center" width="auto" label="共享条件" key="shareCondition" prop="shareCondition"
                :formatter="shareConditionFormat" />
            <el-table-column align="center" width="140" label="不予共享依据" key="notShareReason" prop="notShareReason" />
            <el-table-column align="center" width="auto" label="开放类型" key="openType" prop="openType"
                :formatter="openTypeFormat" />
            <el-table-column align="center" width="auto" label="开放条件" key="openCondition" prop="openCondition" />
            <el-table-column align="center" width="150" label="是否为核数据项" key="isCore" prop="isCore"
                :formatter="booleanFormat" />
            <el-table-column align="center" width="auto" label="是否非空" key="isEmpty" prop="isEmpty"
                :formatter="booleanFormat" />
            <el-table-column align="center" width="auto" label="取值范围" key="valueRange" prop="valueRange" />
        </el-table>

    </div>
</div></template>


<script>
import dict from '../mixins/dict.js'
export default {
    name: "DatadirDetail",
    mixins: [dict],
    // components: { DialogDataItem },
    props: [
        'rootMap',
        'userList',
        'clickBack',
        'datadir'
    ],
    data() {
        return {
        }
    },
    methods: {
        handleClickBack() { this.$emit('clickBack') },
        // 处理：部门、主题、基础
        init() {
            // if (Object.keys(this.rootMap).length < 3) return // 说明根节点未加载或未配置好
            // console.log('root map-----', this.rootMap, this.datadir);
        },
        // 审核人格式化
        formatAuditor(auditorId) {
            let auditor = this.userList.find(item => item.id == auditorId)
            return auditor && auditor.nickname ? auditor.nickname : auditorId
        },
    }
}
</script>

<style lang="less" scoped>
.main {
    background-color: #123271;
    padding: 0 20px;
}

/deep/.el-button--default:hover {
    border: solid 1px #007cd5;
    background-color: #1f499b;
    color: #7ac4fd;
}

.btn-back {
    position: relative;
    // left: 10px;
    top: 14px;
    width: 80px;
    height: 40px;
    padding: 5px 0;
    cursor: pointer;
    color: #7ac4fd;
}

.svg-back {
    display: inline-block;
    margin: 0 10px;
    width: 14px;
    height: 14px;
    background-image: url("../../assets/svg/btn/back.svg");
}

.svg-back:hover {
    background-image: url("../../assets/svg/btn/back_hover.svg");
}

.basic-title {
    background-color: #2650a2;
    padding: 13px;
    margin-bottom: 20px;
}

.basic-item span {
    line-height: 45px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

/deep/.el-form-item__content {
    font-size: 18px;
}

/deep/.el-form-item {
    margin-bottom: 14px;
}
</style>