<template>
  <!-- 驳回理由 -->
  <el-dialog title="驳回理由" :visible="noPassDialogOpen" width="660px" append-to-body center @close="close">
      <el-form ref="form" :model="form" label-width="200px">
        <el-row>
          <el-col :span="24">
            <el-input v-model="form.approveComments" type="textarea"
              :autosize="{ minRows: 3 }" placeholder="请填写驳回理由"></el-input>
          </el-col>
        </el-row>
        
      </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="info" @click="close">取消</el-button>
      <el-button type="primary" @click="save">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "DialogNoPass",
  props: ['noPassDialogOpen', 'closeDialog'],
  data() {
    return {
      form: {
        approveComments: ''
      }
    }
  },
  methods: {
    reset(sub){
      console.log(sub)
      this.form.approveComments = ''
    },
    save() {
      this.$emit('saveNoPass', this.form)
    },
    close() {
      this.$emit('closeDialog')
    }
  },
}
</script>

<style lang="less" scoped>
/deep/.el-textarea__inner {
    border: #1B6CAC 1px solid;
    border-radius: 3px;
    background-color: #092864;
    color: #fff;
}
</style>