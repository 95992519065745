<template xmlns="">
  <div class="container">
    <!-- <div class="logo"></div> -->
    <!-- 登录区域 -->
    <div class="content">
      <div class="itemLeft">
        <div class="title">共享能力价值分析平台</div>
        <!-- 配图 -->
        <div class="pic"></div>
      </div>
      <div class="itemRight">
        <!-- 表单 -->
        <div class="field">

          <!-- 表单 -->
          <div class="form-cont">
            <h2 class="helloCont">Hi，<p>欢迎使用</p>
            </h2>
            <el-tabs class="form" v-model="loginForm.loginType" style="float: none" @tab-click="handleClick">
              <el-tab-pane label="用户密码登录" name="uname"></el-tab-pane>
              <el-tab-pane label="手机验证登录" name="sms"></el-tab-pane>
            </el-tabs>
            <div style="margin-top: 15px;">
              <el-form ref="loginForm" :model="loginForm" :rules="LoginRules" class="login-form">

                <!-- 用户名称密码登录 -->
                <div v-if="loginForm.loginType === 'uname'">
                  <el-form-item prop="username">
                    <el-input v-model="loginForm.username" type="text" auto-complete="off" placeholder="请输入用户名称">
                      <svg-icon slot="prefix" icon-class="loginUser" class="el-input__icon input-icon" />
                    </el-input>
                  </el-form-item>
                  <el-form-item prop="password">
                    <el-input v-model="loginForm.password" type="password" auto-complete="off" placeholder="请输入密码"
                      @keyup.enter.native="getCode">
                      <svg-icon slot="prefix" icon-class="loginPwd" class="el-input__icon input-icon" />
                    </el-input>
                  </el-form-item>

                  <el-form-item prop="code">
                    <el-input v-model="loginForm.code" type="text" auto-complete="off" placeholder="短信验证码"
                      class="sms-login-mobile-code-prefix" @keyup.enter.native="handleLogin">
                      <template>
                        <svg-icon slot="prefix" icon-class="msg" class="el-input__icon input-icon" />
                      </template>
                    </el-input>
                    <template>
                      <el-button type="primary" v-if="codeTimer <= 0" size="mini" @click="getSmsCode('uname')"
                        class="mobileCode">获取验证码</el-button>
                      <el-button type="primary" v-if="codeTimer > 0" size="mini" disabled
                        class="mobileCode  is-disabled">{{ codeTimer }}秒</el-button>
                    </template>
                  </el-form-item>
                </div>
                <div v-else>
                  <el-form-item prop="mobile">
                    <el-input v-model="loginForm.mobile" type="text" auto-complete="off" placeholder="请输入手机号">
                      <svg-icon slot="prefix" icon-class="phone" class="el-input__icon input-icon" />
                    </el-input>
                  </el-form-item>
                  <!-- 图形验证码 -->
                  <el-form-item class="identifyCode" prop="userIdentifyCode">
                    <el-input v-model="loginForm.userIdentifyCode" placeholder="图形验证码">
                      <svg-icon slot="prefix" icon-class="graph" class="el-input__icon input-icon" />
                    </el-input>
                    <img class="identifyCodeImg" :src="codeUrl" alt="" @click="getIdentifyCode()">
                  </el-form-item>
                  <el-form-item prop="code">
                    <el-input v-model="loginForm.code" type="text" auto-complete="off" placeholder="短信验证码"
                      class="sms-login-mobile-code-prefix" @keyup.enter.native="handleLogin">
                      <template>
                        <svg-icon slot="prefix" icon-class="msg" class="el-input__icon input-icon" />
                      </template>

                    </el-input>
                    <template>
                      <el-button type="primary" v-if="codeTimer <= 0" size="mini" @click="getSmsCode('mobile')"
                        class="mobileCode">获取验证码</el-button>
                      <el-button type="primary" v-if="codeTimer > 0" size="mini" class="mobileCode is-disabled">{{
                        codeTimer }}秒</el-button>
                    </template>
                  </el-form-item>
                </div>

              </el-form>
              <!-- 下方的登录按钮 -->
              <el-button :loading="loading" size="medium" type="primary" native-type="submit" class="loginBtn"
                @click.native.prevent="getCode">
                <span v-if="!loading">登 录</span>
                <span v-else>登 录 中...</span>
              </el-button>
            </div>
          </div>
        </div>
        <div class="coverField"></div>
      </div>
    </div>

  </div>
</template>

<script>
//解决屏幕缩放比问题
// import { detectZoom } from '@/utils/detectZoom.js';
// const m = detectZoom();
// document.body.style.zoom = 125 / Number(m);//开发是缩放比为125%
import { sendCmccSmsCode, socialAuthRedirect, reqCheck } from "@/api/login";
import { getTenantIdByName } from "@/api/system/tenant";
import { SystemUserSocialTypeEnum } from "@/utils/constants";
import { getCaptchaEnable, getTenantEnable } from "@/utils/ruoyi";
import SIdentify from "@/components/VerificationCode/index";
import {
  getPassword,
  getRememberMe,
  getTenantName,
  getUsername,
  removePassword,
  removeRememberMe,
  removeTenantName,
  removeUsername,
  setPassword,
  setRememberMe,
  setTenantId,
  setTenantName,
  setUsername,
  getUpdatePassword
} from "@/utils/auth";
import { reqGet } from "@/api/login"

export default {
  name: "Login",
  components: { SIdentify },
  data() {
    return {
      codeUrl: "",
      CaptchaKey: '',
      captchaEnable: true,
      tenantEnable: true,
      codeTimer: 0,
      loginForm: {
        loginType: "uname",
        username: "",
        password: "",
        // username: "admin",
        // password: "admin123@",
        captchaVerification: "",
        mobile: "",
        code: "",
        rememberMe: false,
        tenantName: "产品底座",
        userIdentifyCode: "",
      },
      scene: 21,

      LoginRules: {
        username: [
          { required: true, trigger: "blur", message: "用户名称不能为空" },
        ],
        password: [
          { required: true, trigger: "blur", message: "密码不能为空" },
        ],
        userIdentifyCode: [
          { required: true, trigger: "blur", message: "验证码不能为空" },
        ],
        mobile: [
          { required: true, trigger: "blur", message: "手机号不能为空" },
          {
            validator: function (rule, value, callback) {
              if (/^1[3456789]\d{9}$/.test(value) === false) {
                callback(new Error("手机号格式错误"));
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
        code: [
          { required: true, trigger: "blur", message: "短信验证码不能为空" },
        ],
        tenantName: [
          { required: true, trigger: "blur", message: "租户不能为空" },
          {
            validator: (rule, value, callback) => {
              getTenantIdByName(value).then((res) => {
                const tenantId = res.data;
                if (tenantId && tenantId >= 0) {
                  // 设置租户
                  setTenantId(tenantId);
                  callback();
                } else {
                  callback("租户不存在");
                }
              });
            },
            trigger: "blur",
          },
        ],
      },
      identifyCode: "",
      identifyCodes: "1234567890abcdefjhijklinopqrsduvwxyz",

      loading: false,
      redirect: undefined,
      // 枚举
      SysUserSocialTypeEnum: SystemUserSocialTypeEnum,
    };
  },
  created() {

    // 租户开关
    this.tenantEnable = getTenantEnable();
    // 获取化验证码
    this.getIdentifyCode()
    if (this.tenantEnable) {
      //暂无租户需求，前端写死值为1
      setTenantId(1);
    }
    // 验证码开关
    this.captchaEnable = getCaptchaEnable();
    // 重定向地址
    this.redirect = this.$route.query.redirect
      ? decodeURIComponent(this.$route.query.redirect)
      : undefined;
    this.getCookie();
  },
  methods: {
    handleClick() {
      this.getIdentifyCode();
      this.codeTimer = 0
      this.$refs.loginForm.resetFields()
      // 清空表单校验
      this.$refs.loginForm.clearValidate();
    },
    getIdentifyCode() {
      reqGet().then((res) => {
        this.codeUrl = res.captchaImg
        this.CaptchaKey = res.captchaKey
      })
    },
    getCode() {
      //弹出验证码先校验一边表单,不然做完验证码才提示校验信息太奇怪了
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          // 情况一，未开启：则直接登录
          // if (!this.captchaEnable) {
          this.handleLogin({});
          //   return;
          // }

          // 情况二，已开启：则展示验证码；只有完成验证码的情况，才进行登录
          // 弹出验证码
          // this.$refs.verify.show();
        }
      })
    },
    getCookie() {
      const username = getUsername();
      const password = getPassword();
      const rememberMe = getRememberMe();
      const tenantName = getTenantName();
      this.loginForm = {
        ...this.loginForm,
        username: username ? username : this.loginForm.username,
        password: password ? password : this.loginForm.password,
        rememberMe: rememberMe ? getRememberMe() : false,
        tenantName: tenantName ? tenantName : this.loginForm.tenantName,
      };
    },
    handleLogin(captchaParams) {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          // 设置 Cookie
          if (this.loginForm.rememberMe) {
            setUsername(this.loginForm.username);
            setPassword(this.loginForm.password);
            setRememberMe(this.loginForm.rememberMe);
            setTenantName(this.loginForm.tenantName);
          } else {
            removeUsername();
            removePassword();
            removeRememberMe();
            removeTenantName();
          }
          this.loginForm.captchaVerification = captchaParams.captchaVerification;
          if (this.loginForm.loginType === 'uname') {
            this.$store
              .dispatch(
                this.loginForm.loginType === "sms" ? "CmccSmsLogin" : "Login",
                this.loginForm
              )
              .then(() => {
                if (getUpdatePassword()) {
                  this.$router.push({ path: "/user/profile" })
                } else {
                  window.location.href = window.location.origin + '/exchange'
                }

              })
              .catch(() => {
                this.loading = false;
              });
          } else {
            // 图形校验
            reqCheck(this.loginForm.userIdentifyCode, this.CaptchaKey).then((res) => {
              if (res) {
                this.$store
                  .dispatch(
                    this.loginForm.loginType === "sms" ? "CmccSmsLogin" : "CmccSmsLogin1",
                    this.loginForm
                  )
                  .then(() => {
                    if (getUpdatePassword()) {
                      this.$router.push({ path: "/user/profile" })
                    } else {
                      window.location.href = window.location.origin + '/exchange'
                    }

                  })
                  .catch(() => {
                    this.loading = false;
                  });
              } else {
                this.$message({
                  message: '登录失败，图形验证码不正确',
                  type: 'error'
                })
              }
              this.loading = false;
            })
          }


        }
      });
    },
    async doSocialLogin(socialTypeEnum) {
      // 设置登录中
      this.loading = true;
      let tenant = false;
      if (this.tenantEnable) {
        await this.$prompt("请输入租户名称", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        })
          .then(async ({ value }) => {
            await getTenantIdByName(value).then((res) => {
              const tenantId = res.data;
              tenant = true;
              if (tenantId && tenantId >= 0) {
                setTenantId(tenantId);
              }
            });
          })
          .catch(() => {
            // 取消登录按钮 loading状态
            this.loading = false;

            return false;
          });
      } else {
        tenant = true;
      }
      if (tenant) {
        // 计算 redirectUri
        const redirectUri =
          location.origin +
          "/social-login?" +
          encodeURIComponent(
            "type=" +
            socialTypeEnum.type +
            "&redirect=" +
            (this.redirect || "/")
          );
        // 进行跳转
        socialAuthRedirect(
          socialTypeEnum.type,
          encodeURIComponent(redirectUri)
        ).then((res) => {
          // console.log(res.url);
          window.location.href = res.data;
        });
      }
    },
    /** ========== 以下为升级短信登录 ========== */
    getSmsCode(type) {
      this.$refs.loginForm.clearValidate(this.loginForm.code)
      if (type === 'mobile') {
        if (this.codeTimer > 0) return;
        let validStr = ''
        this.$refs.loginForm.validateField('mobile', (valid) => {
          if (valid) {
            validStr = valid
          }
        })
        this.$refs.loginForm.validateField('userIdentifyCode', (valid) => {
          if (valid) {
            validStr += valid
          }
        })
        if (validStr.length > 0) return false
        reqCheck(this.loginForm.userIdentifyCode, this.CaptchaKey).then((res) => {
          if (res) {
            this.$store.dispatch('SmsLogin', this.loginForm)
              .then((data) => {
                this.$modal.msgSuccess("获取验证码成功");
                this.codeTimer = 60;
                let msgTimer = setInterval(() => {
                  this.codeTimer = this.codeTimer - 1;
                  if (this.codeTimer <= 0) {
                    clearInterval(msgTimer);
                  }
                }, 1000);
              })
              .catch(() => {
                this.loading = false;
              });
          } else {
            this.$message({
              message: '登录失败，图形验证码不正确',
              type: 'error'
            })
          }
        })
      } else {
        if (this.codeTimer > 0) return;
        let validStr = ''
        this.$refs.loginForm.validateField('username', (valid) => {
          if (valid) {
            validStr = valid
          }
        })
        this.$refs.loginForm.validateField('password', (valid) => {
          if (valid) {
            validStr += valid
          }
        })
        if (validStr.length > 0) return false;
        this.$store.dispatch('SmsLogin', this.loginForm)
          .then((data) => {
            this.$modal.msgSuccess("获取验证码成功");
            this.codeTimer = 60;
            let msgTimer = setInterval(() => {
              this.codeTimer = this.codeTimer - 1;
              if (this.codeTimer <= 0) {
                clearInterval(msgTimer);
              }
            }, 1000);
          })
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@/assets/styles/login.scss";

body {
  font-family: PingFangSCMedium;
}

.oauth-login {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.oauth-login-item {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.oauth-login-item img {
  height: 25px;
  width: 25px;
}

.oauth-login-item span:hover {
  text-decoration: underline red;
  color: red;
}

.sms-login-mobile-code-prefix {
  :deep(.el-input__prefix) {
    transform: none;
    // top: 22%;
  }
}

.identifyCode {
  :deep(.el-input) {
    width: 100%;
    float: left;
  }
}

.getMobileCode {
  display: inline-block;
  width: 76px;
  text-align: center;
}

.form-cont {
  position: relative;
  width: 290px;
  height: 90%;

  h2,
  p {
    font-family: PingFangSCMedium;
    margin: 0;
    color: #2576F4;
  }

  :deep(.el-input__inner) {
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0;
  }

  :deep(.el-input-group__append) {
    border-left: 1px solid #DCDFE6;
  }
}

.itemLeft {
  overflow: hidden;
  width: 50%;
  height: 100%;
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.itemRight {
  overflow: hidden;
  width: 50%;
  height: 100%;
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;

  :deep(.el-tabs__header) {
    margin: 0;
    text-align: center;
  }

  :deep(.el-tabs__content) {
    padding: 0;
  }
}

.loginBtn {
  width: 100%;
  background-image: linear-gradient(264deg, #6BA8FF 0%, #3B87F8 100%);
  border-radius: 4px;
  height: 50px;
  font-size: 16px;
  font-family: PingFangSCMedium;
  position: absolute !important;
  bottom: 0;
}

.el-form-item {
  margin-bottom: 16px;
}

:deep(.el-input__prefix,
  .el-input__suffix) {
  color: #545454;
}

.identifyCodeImg {
  position: absolute;
  right: 0;
  bottom: 10px;
  width: 100px;
}

.mobileCode {
  cursor: pointer;
  position: absolute;
  display: block;
  right: 0;
  bottom: 7px;
  font-weight: 400;
}

/* 谷歌 */
.el-input__inner::-webkit-input-placeholder {
  color: #31E5F5;
}

/* 火狐 */
.el-input__inner:-moz-placeholder {
  color: #31E5F5;
}

/*ie*/
.el-input__inner:-ms-input-placeholder {
  color: #31E5F5;
}

:deep(.el-input__inner, .el-input__inner:hover) {
  background-color: #fff;
  color: #000;
}
</style>
