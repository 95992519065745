<template>
    <!-- 前置节点新增、编辑 -->
    <el-dialog :title="serverDialogTitle" :visible="serverDialogOpen" width="600px" append-to-body @close="close">
        <el-form ref="form" :model="form" label-width="140px" :rules="rules">
            <el-row>
                <el-col :span="24">
                    <el-form-item label="部门名称" prop="deptId">
                        <el-select v-model="form.deptId" placeholder="请选择" @change="changeDept">
                            <el-option v-for="item in departList" :key="parseInt(item.id)" :label="item.name"
                                :value="parseInt(item.id)"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="24">
                    <el-form-item label="部门编码" prop="deptCode">
                        <el-input v-model="form.deptCode" placeholder="请输入内容" disabled></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="24">
                    <el-form-item label="服务器ip" prop="host">
                        <el-input v-model="form.host" placeholder="请输入内容"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="24">
                    <el-form-item label="服务器端口" prop="port">
                        <el-input v-model="form.port" placeholder="请输入内容"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="24">
                    <el-form-item label="服务器用户名" prop="username">
                        <el-input v-model="form.username" placeholder="请输入内容"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="24">
                    <el-form-item label="服务器密码" prop="password">
                        <el-input v-model="form.password" placeholder="请输入内容" type="password"
                            autocomplete="new-password"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="24">
                    <el-form-item label="服务器私钥" prop="accessKey">
                        <el-input v-model="form.accessKey" placeholder="请输入内容"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="24">
                    <el-form-item label="服务器名称" prop="serverName">
                        <el-input v-model="form.serverName" placeholder="请输入内容"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="24">
                    <el-form-item label="服务器类型" prop="serverType">
                        <el-radio v-for="server in dict_server_type" :label="server.value" v-model='form.serverType'>
                            {{ server.label }}
                        </el-radio>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button v-if='isAdd' type="primary" @click="saveAdd">确 定</el-button>
            <el-button v-if='!isAdd' type="primary" @click="saveEdit">确 定</el-button>
            <el-button type="info" @click="close">取消</el-button>
        </div>
    </el-dialog>
</template>

<script>
import { addExchServer, editExchServer, detailExchServer, checkExchServer } from "@/api/system/exch-server"
import dict from '../mixins/dict.js'
export default {
    name: "DialogServer",
    mixins: [dict],
    props: [
        'serverDialogOpen',
        'serverDialogTitle',
        'departList',
        'closeDialog'
    ],
    data() {
        return {
            isAdd: true, // true新增  false编辑
            // 树下拉框
            tree_select_disable: false,
            selectValue: "",
            // 表单参数
            form: {},
            // 表单校验
            rules: {
                deptId: [{ required: true, message: "部门名称不能为空", trigger: "blur" }],
                deptCode: [{ required: true, message: "部门不能为空", trigger: "blur" }],
                host: [{ required: true, message: "服务器ip不能为空", trigger: "blur" }],
                port: [{ required: true, message: "服务器端口不能为空", trigger: "blur" }],
                username: [{ required: true, message: "用户名不能为空", trigger: "blur" }],
                password: [{ required: true, message: "服务器密码不能为空", trigger: "blur" }],
                serverName: [{ required: true, message: "服务器类型", trigger: "blur" }],
            },
        }
    },
    methods: {
        close() {
            this.$emit('closeDialog')
        },
        // 表单重置
        reset() {
            this.isAdd = true
            this.resetForm("form");
            this.form = {};
        },
        edit(server) {
            this.isAdd = false
            detailExchServer(server.id).then(
                response => {
                    this.serverDetail = response.data
                    this.form = JSON.parse(JSON.stringify(this.serverDetail));
                }, err => {
                    this.$message.error(err.message)
                })
        },
        saveAdd() {
            this.$refs['form'].validate(valid => {
                if (!valid) return
                checkExchServer(this.form).then(res => {
                    if (res.data.hasError) {
                        this.$message.error(res.data.errorMsg || '连接服务器失败')
                    } else {
                        this.$message.success('连接成功')
                    }
                }, err => {
                    this.$message.error('连接服务器失败')
                })
                addExchServer(this.form).then(res => {
                    this.$message.success('新增成功')
                    this.$emit('closeDialog', Object.assign({}, this.form, { id: res.data }))
                }, err => {
                    this.$message.error(err)
                })
            })
        },
        saveEdit() {
            this.$refs['form'].validate(valid => {
                if (!valid) return
                this.$refs['form'].validate(valid => {
                    if (!valid) return
                    checkExchServer(this.form).then(res => {
                        if (res.data.hasError) {
                            this.$message.error(res.data.errorMsg || '连接服务器失败')
                        } else {
                            this.$message.success('连接成功')
                        }
                    }, err => {
                        this.$message.error('连接服务器失败')
                    })
                    editExchServer(this.form).then(res => {
                        this.$message.success('编辑成功')
                        this.$emit('closeDialog', this.form)
                    }, err => {
                        this.$message.error(err)
                    })
                })
            })
        },
        changeDept(id) {
            let dept = this.departList.find(item => item.id == id)
            this.form.deptCode = 'dept' + dept.id
            this.form.deptName = dept.name
        },
    }
}
</script>

<style lang="less" scoped>
.btn_add {
    text-align: right;
    padding: 0 0 10px 0;
    color: #82ceff;
    cursor: pointer;
}

/deep/ .el-button--info,
.el-button--info:focus,
.el-button--info:hover {
    background: #1f499b;
    border: 1px solid #045b9f;
}

/deep/.el-textarea__inner,
/deep/.el-input--prefix .el-input__inner {
    border: #1B6CAC 1px solid;
    border-radius: 3px;
    background-color: #092864;
    color: #fff;
}

/deep/.el-textarea .el-input__count {
    background-color: #092864;
}

.tree_options:hover {
    background: none;
}

/deep/ .el-select-dropdown__item.selected {
    color: transparent;
}
</style>