import request from '@/utils/request'
import { praseStrEmpty } from "@/utils/ruoyi";
const adminurl = '/admin-api'

// 前置服务器列表（所有）
export function listExchServer(query) {
  return request({
    url: adminurl + '/rdm/exch-server/list',
    method: 'get',
    params: query
  })
}

// 前置服务器列表（分页）
export function pageExchServer(query) {
  if (query) {
    query.query = praseStrEmpty(query.query)
    query.serverType = praseStrEmpty(query.serverType)
  }
  return request({
    url: adminurl + '/rdm/exch-server/page',
    method: 'get',
    params: query
  })
}

// 新增前置服务器
export function addExchServer(data) {
  return request({
    url: adminurl + '/rdm/exch-server/create',
    method: 'post',
    data: data
  })
}

// 编辑前置服务器
export function editExchServer(data) {
  return request({
    url: adminurl + '/rdm/exch-server/update',
    method: 'put',
    data: data
  })
}

// 删除前置服务器
export function deleteExchServer(id) {
  return request({
    url: adminurl + '/rdm/exch-server/' + praseStrEmpty(id),
    method: 'delete',
  })
}

// 前置服务器详情
export function detailExchServer(id) {
  return request({
    url: adminurl + '/rdm/exch-server/' + praseStrEmpty(id),
    method: 'get'
  })
}

// 检查配置信息
export function checkExchServer(query) {
  return request({
    url: adminurl + '/rdm/exch-server/check',
    method: 'post',
    data: query
  })
}

// 检查配置信息
export function runInfoExchServer(id) {
  return request({
    url: adminurl + '/rdm/exch-server/' + praseStrEmpty(id) + '/runInfo',
    method: 'get',
  })
}