import 'element-ui/lib/theme-chalk/index.css'
import './style.css'
import './assets/platform.less' // platform css
import '@/assets/styles/element-variables.scss'
import '@/assets/styles/index.scss' // global css
import '@/assets/styles/ruoyi.scss' // ruoyi css
import "@/assets/icons"; // svg-icon

import App from './App.vue'
import Vue from 'vue'
import router from './router'
import store from './store'

// UI 框架
import ElementUI from 'element-ui'
import ElFormRenderer from '@femessage/el-form-renderer'
import ElDataTable from '@/components/common/el-data-table'
import Tree from '@/package/tree/src/tree.vue'
// axios
import axios from '@/utils/axios'

// directive
import directive from '@/directive'

// components
import Pagination from "@/components/Pagination";
import RightToolbar from "@/components/RightToolbar"
import SvgIcon from '@/components/SvgIcon'// svg component

import { handleTree, resetForm, parseTime } from "@/utils/ruoyi";
import { DICT_TYPE, getDictDataLabel, getDictDatas, getDictDatas2 } from "@/utils/dict";
Vue.use(ElementUI)
Vue.use(directive)

Vue.component(Tree.name, Tree)
Vue.component('el-form-renderer', ElFormRenderer)
Vue.component('el-data-table', ElDataTable)
Vue.component('Pagination', Pagination)
Vue.component('RightToolbar', RightToolbar)
Vue.component('svg-icon', SvgIcon)

Vue.prototype.$axios = axios
Vue.prototype.handleTree = handleTree
Vue.prototype.resetForm = resetForm
Vue.prototype.parseTime = parseTime
Vue.prototype.DICT_TYPE = DICT_TYPE
Vue.prototype.getDictDatas = getDictDatas
Vue.prototype.getDictDatas2 = getDictDatas2
Vue.prototype.getDictDataLabel = getDictDataLabel

Vue.config.productionTip = false
//底座项目修改——注入微应用启动程序
let instance = null
function render(props = {}) {
  const { container } = props
  instance = new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount(container ? container.querySelector('#app') : '#app') //这里是挂载到自己的html中，基座会拿到这个挂载后的html，将其插入进去
}
// qiankun 将会在微应用 bootstrap 之前注入一个运行时的 publicPath 变量，我们需要做的是在微应用的 main.js 的顶部添加如下代码：
if (window.__POWERED_BY_QIANKUN__) {
  __webpack_public_path__ = window.__INJECTED_PUBLIC_PATH_BY_QIANKUN__
} // or此处我们也可以在 src 目录新增 public-path.js，在main.js中引入该文件（import './public-path';），内部代码内容和上述一样。

//如何独立运行微应用
if (!window.__POWERED_BY_QIANKUN__) {
  //不是在qiankun环境下的话，独立运行
  render()
}

/**
 * bootstrap 只会在微应用初始化的时候调用一次，下次微应用重新进入时会直接调用 mount 钩子，不会再重复触发 bootstrap。
 * 通常我们可以在这里做一些全局变量的初始化，比如不会在 unmount 阶段被销毁的应用级别的缓存等。
 */
export async function bootstrap() {
  console.log('vue app bootstraped')
}

/**
 * 应用每次进入都会调用 mount 方法，通常我们在这里触发应用的渲染方法
 */
export async function mount(props) {
  //props含有主应用传递进来的数据
  render(props)
}

/**
 * 应用每次 切出/卸载 会调用的方法，通常在这里我们会卸载微应用的应用实例
 */
export async function unmount() {
  instance.$destroy()
}

/**
 * 可选生命周期钩子，仅使用 loadMicroApp 方式加载微应用时生效
 */
export async function update(props) {
  console.log('update props', props)
}
