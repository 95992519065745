<template>
    <!-- 挂载数据-数据库 -->
    <div>
        <el-button type="text" @click="handleClickBack">返回</el-button>
        <el-button type="primary" @click="handleClickBind">保存</el-button>
        <div class="bind_catalog">
            <el-form ref="form" :model="form" label-width="180px" :rules="rules">
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="目录名称" prop="name">{{ datadir.name }}</el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="数据源" prop="dsId">
                            <el-select v-model="form.dsId" placeholder="请选择" @change="changeSelectDs">
                                <el-option v-for="item in datasourceList" :key="parseInt(item.dsId)" :label="item.host"
                                    :value="parseInt(item.dsId)"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="数据库" prop="dbId">
                            <el-select v-model="form.dbId" placeholder="请选择" @change="changeSelectDb">
                                <el-option v-for="item in datasourceList" :key="parseInt(item.dsId)" :label="item.dsName"
                                    :value="parseInt(item.dsId)"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="表名" prop="tableName">
                            <el-select v-model="form.tableName" placeholder="请选择" @change="getColumnList">
                                <el-option v-for="item in tableList" :key="item" :label="item" :value="item"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="主键" prop="pkName">
                            <el-select v-model="form.pkName" placeholder="请选择">
                                <el-option v-for="item in columnList" :key="item" :label="item" :value="item"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="挂载类型" prop="mountType">
                            <el-select v-model="form.mountType" placeholder="请选择" @change="getBridgeList">
                                <el-option v-for="item in dict_bind_type" :label="item.label"
                                    :value="item.value"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>

            <div class="bind_title">桥接关系</div>
            <el-table :data="bridgings" stripe border>
                <el-table-column align="center" width="58" label="序号" key="data_id" prop="data_id">
                    <template slot-scope="scope">{{ scope.$index + 1 }}</template>
                </el-table-column>
                <el-table-column align="center" label="数据项" key="dataItemName" prop="dataItemName" />
                <el-table-column align="center" min-width="100px" label="字段名称" key="colName" prop="colName">
                    <template slot-scope="scope">
                        <!-- <el-input v-model="scope.row.colName" placeholder="请输入内容"></el-input> -->
                        <el-select v-model="scope.row.colName" placeholder="请选择">
                            <el-option v-for="item in bridgeList" :key="item" :label="item" :value="item"></el-option>
                        </el-select>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
import dict from '../mixins/dict.js'
import { listDatasource, listTables, listColumns, listBridges, bindDb } from "@/api/system/bindsource";
export default {
    name: "DatadirBindDB",
    mixins: [dict],
    props: [
        'clickBack',
        // 'datadir',
    ],
    created() {
        this.getDatasourceList()
    },
    data() {
        return {
            form: {},
            // 表单校验
            rules: {
                dsId: [{ required: true, message: "数据源不能为空", trigger: "blur" }],
                dbId: [{ required: true, message: "数据库不能为空", trigger: "blur" }],
                tableName: [{ required: true, message: "表名不能为空", trigger: "blur" }],
                mountType: [{ required: true, message: "挂载类型不能为空", trigger: "blur" }],
            },
            datasourceList: [], // 数据库
            tableList: [], // 表
            columnList: [], // 字段
            bridgeList: [], // 桥接关系下拉
            bridgings: [], // 桥接关系结果
            datadir: {},
        }
    },
    methods: {
        // select---------------------------------------------------
        getDatasourceList() {
            listDatasource({ category: 'DB' }).then(response => {
                if (response.data) {
                    this.datasourceList = response.data || [];
                }
            }, err => {
                this.$message.error(err.message)
            })
        },
        // 数据源数据项一对一绑定
        changeSelectDs() {
            this.form.dbId = this.form.dsId
            this.getTableList()
        },
        changeSelectDb() {
            this.form.dsId = this.form.dbId
            this.getTableList()
        },
        getTableList() {
            listTables(this.form.dsId).then(response => {
                if (response) {
                    this.tableList = response.data || [];
                }
            }, err => {
                this.$message.error(err.message)
            })
        },
        getColumnList() {
            let obj = { id: this.form.dsId, table: this.form.tableName }
            listColumns(obj).then(response => {
                if (response.data) {
                    this.columnList = response.data || [];
                }
            }, err => {
                this.$message.error(err.message)
            })
            this.getBridgeList()
        },
        getBridgeList() {
            this.$refs['form'].validate(valid => {
                if (!valid) return
                // this.bridgings.forEach(i => { i.colName = '' })  // reset
                let obj = { id: this.form.dsId, table: this.form.tableName, mountType: this.form.mountType }
                listBridges(obj).then(response => {
                    if (response.data) {
                        this.bridgeList = response.data || [];
                    }
                }, err => {
                    this.$message.error(err.message)
                })
            })
        },
        // -----------------------------------------------------------
        handleClickBack() { this.$emit('clickBack') },
        // 表单重置
        reset(obj) {
            this.bridgings = []
            this.datadir = obj ? obj : {}
            let tableVoList = this.datadir.tableVoList || []
            if (tableVoList.length > 0) {
                this.form = JSON.parse(JSON.stringify(tableVoList[0]))
                this.changeSelectDs()
                this.$nextTick(() => { this.getBridgeList() })
            } else {
                this.form = {}
            }


            // this.form.bridgings 是历史桥接关系；  this.bridings 是本轮操作的桥接关系
            // 没有挂载数据，桥接关系初始化
            if (!(this.form.bridgings instanceof Array) || this.form.bridgings.length == 0) {
                return this.datadir.dataItems.forEach(item => {
                    let obj = {
                        dataItemName: item.itemName,
                        colName: '',
                        dataItemId: item.id,
                    }
                    this.bridgings.push(obj)
                })
            }

            // 有挂载数据，桥接关系回显。 考虑挂在数据后又去修改数据项的情况
            this.datadir.dataItems.forEach(item => {
                let has_bridge = this.form.bridgings.find(el => el.dataItemId == item.id) || { colName: '' }
                let obj = {
                    dataItemName: item.itemName,
                    colName: has_bridge.colName,
                    dataItemId: item.id,
                }
                this.bridgings.push(obj)
            })
        },

        // -----------------------------------------------------------
        handleClickBind() {
            this.$refs['form'].validate(valid => {
                if (!valid) return
                bindDb(Object.assign({}, this.form, { dirId: this.datadir.id, bridgings: this.bridgings })).then(res => {
                    this.$message.success('挂载成功')
                    this.$emit('clickBack')
                }, err => {
                    this.$message.error(err)
                });
            })
        },
    }
}
</script>

<style lang="less" scoped>
.bind_catalog {
    background-color: #0f347e;
    width: 1880px;
    height: 919px;
    border: 1px solid #067cdb;
    border-radius: 5px;
    padding: 20px;
}

.bind_title {
    height: 40px;
}

/deep/.el-button--default:hover {
    border: solid 1px #007cd5;
    background-color: #1f499b;
    color: #7ac4fd;
}

/deep/.el-form-item__content {
    font-size: 18px;
}
</style>