import { render, staticRenderFns } from "./datadirBindDB.vue?vue&type=template&id=f7e90f1e&scoped=true&"
import script from "./datadirBindDB.vue?vue&type=script&lang=js&"
export * from "./datadirBindDB.vue?vue&type=script&lang=js&"
import style0 from "./datadirBindDB.vue?vue&type=style&index=0&id=f7e90f1e&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f7e90f1e",
  null
  
)

export default component.exports