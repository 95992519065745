import request from '@/utils/request'
import { praseStrEmpty } from "@/utils/ruoyi";
const adminurl = '/admin-api'

// 获取数据源列表
export function listDatasource(query) {
  return request({
    url: adminurl + '/rdm/datasource/list',
    method: 'get',
    params: query
  })
}

// 获取数据库的表
export function listTables(id) {
  return request({
    url: adminurl + '/rdm/datasource/' + praseStrEmpty(id) + '/tables',
    method: 'get',
  })
}

// 获取表的字段
export function listColumns(query) {
  return request({
    url: adminurl + '/rdm/datasource/' + praseStrEmpty(query.id) + '/columns',
    method: 'get',
    params: query
  })
}

// 获取桥接关系
export function listBridges(query) {
  return request({
    url: adminurl + '/rdm/datasource/' + praseStrEmpty(query.id) + '/columns_compare',
    method: 'get',
    params: query
  })
}

export function bindApi(data){
  return request({
    url: adminurl + '/rdm/resDir/bind/api',
    method: 'post',
    data: data
  })
}

export function bindDb(data){
  return request({
    url: adminurl + '/rdm/resDir/bind/db',
    method: 'post',
    data: data
  })
}

export function bindFile(data){
  return request({
    url: adminurl + '/rdm/resDir/bind/file',
    method: 'post',
    data: data
  })
}