<template>
    <!-- 前置服务器拓扑结构 -->
    <div :class="'server s' + index">
        <div :class="'arrow a' + index"></div>
        <div v-if="server.serverType != '3'" class="dept"></div>
        <div v-if="server.serverType == '3'" class="file"></div>
        <div class="tag">{{ server.serverName }}</div>
    </div>
</template>

<script>
import dict from '../mixins/dict.js'
export default {
    name: "NodeServer",
    mixins: [dict],
    props: [
        'server',
        'index'
    ],
    data() {
        return {
        }
    },
    created() {
        // console.log('server', this.server, this.index)
    },
    methods: {
    }
}
</script>

<style lang="less" scoped>
.tag {
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 0);
    width: 130px;
    height: 30px;
    background-color: rgba(242, 242, 242, 1);
    border: none;
    border-radius: 8px;
    color: #0C72BD;
    text-align: center;
    line-height: 30px;
}

.server {
    position: absolute;
    width: 147px;
    height: 139px;
    cursor: pointer;
}

.dept {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 91px;
    height: 139px;
    background-image: url('../../assets/images/server/department.png');
    background-size: contain;
}

.file {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 86px;
    height: 81px;
    background-image: url('../../assets/images/server/file.png');
    background-size: contain;
}


// 每个节点的位置
.s0 {
    top: 220px;
    left: 1115px;
    z-index: 1;
}

.s1 {
    top: 220px;
    left: 580px;
    z-index: 1;
}

.s2 {
    top: 400px;
    right: 695px;
    z-index: 1;
}

.s3 {
    top: 400px;
    left: 700px;
    z-index: 1;
}

.s4 {
    top: 50px;
    left: 700px;
    z-index: 1;
}

.s5 {
    top: 50px;
    right: 695px;
    z-index: 1;
}

.s6 {
    top: -50px;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 1;
}

.s7 {
    bottom: -50px;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 1;
}

.s8 {
    top: 0;
    left: 515px;
}

.s9 {
    top: 130px;
    left: 415px;
}

.s10 {
    top: 280px;
    left: 390px;
}

.s11 {
    top: 440px;
    left: 500px;
}

.s12 {
    bottom: -120px;
    left: 35%;
}

.s13 {
    bottom: -120px;
    right: 35%;
}

.s17 {
    top: 0;
    right: 515px;
}

.s16 {
    top: 130px;
    right: 400px;
}

.s15 {
    top: 280px;
    right: 370px;
}

.s14 {
    top: 440px;
    right: 490px;
}

// arrow
.arrow {
    border-image-source: url('../../assets/images/server/arrow.png');
    border-image-slice: 23 13 23 13 fill;
    border-top: 23px solid;
    border-right: 13px solid;
    border-bottom: 23px solid;
    border-left: 13px solid;
    height: 100px;
    width: 26px;
}

.a0 {
    height: 150px;
    position: absolute;
    left: -30px;
    transform: rotate(90deg);
}


.a1 {
    height: 180px;
    position: absolute;
    left: 138px;
    transform: rotate(90deg);
}


.a2 {
    height: 120px;
    position: absolute;
    left: 30px;
    transform: rotate(135deg);
}

.a3 {
    height: 120px;
    position: absolute;
    left: 100px;
    transform: rotate(45deg);
}

.a4 {
    height: 120px;
    position: absolute;
    left: 100px;
    top: 30px;
    transform: rotate(135deg);
}

.a5 {
    height: 150px;
    position: absolute;
    left: 20px;
    top: 20px;
    transform: rotate(45deg);
}

.a6 {
    height: 150px;
    position: absolute;
    left: 50px;
    top: 80px;
}

.a7 {
    height: 150px;
    position: absolute;
    left: 50px;
    top: -80px;
}

.a8 {
    height: 289px;
    position: absolute;
    left: 174px;
    top: 12px;
    transform: rotate(120deg);
}

.a9 {
    height: 339px;
    position: absolute;
    left: 220px;
    top: -60px;
    transform: rotate(100deg);
}

.a10 {
    height: 339px;
    position: absolute;
    left: 250px;
    top: -100px;
    transform: rotate(85deg);
}

.a11 {
    height: 310px;
    position: absolute;
    left: 166px;
    top: -161px;
    transform: rotate(67deg);
}

.a12 {
    height: 300px;
    position: absolute;
    left: 150px;
    top: -180px;
    transform: rotate(30deg);
}

.a13 {
    height: 303px;
    position: absolute;
    left: -38px;
    top: -180px;
    transform: rotate(-30deg);
}

.a14 {
    height: 303px;
    position: absolute;
    left: -63px;
    top: -158px;
    transform: rotate(-60deg);
}

.a15 {
    height: 380px;
    position: absolute;
    left: -127px;
    top: -120px;
    transform: rotate(-84deg);
}

.a16 {
    height: 353px;
    position: absolute;
    left: -121px;
    top: -83px;
    transform: rotate(-101deg);
}

.a17 {
    height: 321px;
    position: absolute;
    left: -46px;
    top: -21px;
    transform: rotate(-120deg);
}
</style>