
import { DICT_TYPE, getDictDatas } from "@/utils/dict";
import { parseTime } from "@/utils/ruoyi"

export default {
  data() {
    return {
    }
  },
  computed: {
    // 数据字典，尝试解决异步问题
    dict_state() { return this.getDictDatas(DICT_TYPE.RDM_RESDIR_STATE) },
    dict_state_view() { return this.getDictDatas(DICT_TYPE.RDM_RESDIR_STATE_VIEW) },
    dict_open_type() { return this.getDictDatas(DICT_TYPE.RDM_OPEN_TYPE) },
    dict_share_way() { return getDictDatas(DICT_TYPE.RDM_SHARE_WAY) },
    dict_share_type() { return getDictDatas(DICT_TYPE.RDM_SHARE_TYPE) },
    dict_share_condition() { return getDictDatas(DICT_TYPE.RDM_SHARE_CONDITION) },
    dict_file_type_format() { return getDictDatas(DICT_TYPE.RDM_FILE_TYPE_FORMAT) },
    dict_resdir_state() { return getDictDatas(DICT_TYPE.RDM_RESDIR_STATE) },
    dict_resource_type() { return getDictDatas(DICT_TYPE.RDM_RESOURCE_TYPE) },
    dict_resource_type_attr() { return getDictDatas(DICT_TYPE.RDM_RESOURCE_TYPE_ATTR) },
    dict_data_grade() { return getDictDatas(DICT_TYPE.RDM_DATA_GRADE) },
    dict_update_cycle() { return getDictDatas(DICT_TYPE.RDM_UPDATE_CYCLE) },
    dict_bind_type() { return this.getDictDatas(DICT_TYPE.RDM_BIND_TYPE) },
    dict_bind_api_method() { return this.getDictDatas(DICT_TYPE.RDM_BIND_API_METHOD) },
    dict_bind_api_protocol() { return this.getDictDatas(DICT_TYPE.RDM_BIND_API_PROTOCOL) },
    dict_server_type() { return this.getDictDatas(DICT_TYPE.RDM_SERVER_TYPE) },
    dict_dir_subscribe_type() { return this.getDictDatas(DICT_TYPE.RDM_DIR_SUBSCRIBE_STATE) },
    dict_dir_subscribe_manager_type() { return this.getDictDatas(DICT_TYPE.RDM_DIR_SUBSCRIBE_MANAGER_STATE) },
  },
  methods: {
    // 字典格式化
    formatFromDict(id, dict) {
      if (!id) {
        return '无';
      }
      for (const i of dict) {
        if (id == i.value) {
          return i.label;
        }
      }
      return id;
    },
    shareTypeFormat(row, col, cell, index) {
      return this.formatFromDict(cell, this.dict_share_type)
    },
    openTypeFormat(row, col, cell, index) {
      return this.formatFromDict(cell, this.dict_open_type)
    },
    resourceTypeFormat(row, col, cell, index) {
      return this.formatFromDict(cell, this.dict_resource_type)
    },
    updateCycleFormat(row, col, cell, index) {
      return this.formatFromDict(cell, this.dict_update_cycle)
    },
    stateFormat(row, col, cell, index) {
      return this.formatFromDict(cell, this.dict_state)
    },
    shareConditionFormat(row, col, cell, index) {
        return this.formatFromDict(cell, this.dict_share_condition)
    },
    dataGradeFormat(row, col, cell, index) {
        return this.formatFromDict(cell, this.dict_data_grade)
    },
    timeFormatter(row, col, cell, index) {
      return parseTime(cell)
    },
    booleanFormat(row, col, cell, index) {
      return cell ? "是" : "否"
    },
    purposeFormat(row, col, cell, index) {
      switch (cell) {
        case '1': return "学习";
        case '2': return "调研";
        case '3': return "工作";
        case '4': return "业务";
        case '5': return "社会";
        case '6': return "其他";
        default: return;
      }
    },
  }
}
