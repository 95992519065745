<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import store from './store'
import DevicePixelRatio from '@/utils/devicePixelRatio' // 适配屏幕大小，不允许放缩
import {getTenantEnable} from '@/utils/ruoyi'
import {setTenantId} from '@/utils/auth'
export default {
  beforeMount() {
    store.dispatch('dict/loadDictDatas') // 获取字典数据
  },
  created() {
    // new DevicePixelRatio().init()     // 先按照设计稿大小，不进行适配
    console.log('打包日期: ' + process.env.VUE_APP_TIME)
    // 租户开关
    this.tenantEnable = getTenantEnable()
    if (this.tenantEnable) {
      //暂无租户需求，前端写死值为1
      setTenantId(1)
    }
  },
}
</script>
<style lang="less">
#app {
  background-color: #062961;
}
</style>
