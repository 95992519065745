<template>
    <!-- 左侧编目维度 -->
    <div>
        <div>
            <el-input v-model="treeName" placeholder="请输入内容" clearable size="middle" prefix-icon="el-icon-search"
                style="margin: 20px 0; width: 100%;" />
            <!-- <svg-icon icon-class="setting" class="btn_setting" @click="showDialogRootCatalog" /> -->
        </div>
        <div>
            <el-tree :data="treeOptions" :props="defaultProps" :expand-on-click-node="false" lazy :load="loading"
                :filter-node-method="filterNode" ref="tree" :highlight-current="true" @node-click="nodeClick" />
        </div>
    </div>
</template>
  
<script>

export default {
    name: "CatalogTree",
    props: ['treeOptions', 'defaultProps', 'loadNode', 'handleNodeClick'],
    components: {},
    data() {
        return {
            treeName: undefined
        }
    },
    watch: {
        // 根据名称筛选部门树
        treeName(val) {
            this.$refs.tree.filter(val);
        }
    },
    methods: {
        // 懒加载树
        loading(node, resolve) {
            this.$emit('loadNode', node, resolve)
        },
        nodeClick(data) {
            this.$emit('handleNodeClick', data)
        },
        // 筛选节点
        filterNode(value, data) {
            if (!value) return true;
            return data.name.indexOf(value) !== -1;
        },
    },

};
</script>
<style lang="less" scoped></style>
  