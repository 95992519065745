<template>
  <div>
    <!-- <navbar v-if="!isPortal"/> -->
    <navbar />

    <div class="left">
      <div class="breadcrumb">面包屑TODO</div>
      <div class="catalog_dimension">编目维度TODO</div>
    </div>
    <div class="right">
      <el-data-table ref="zb" v-bind="tableConfig" class="table-container" :customQuery.sync="customQuery"
        @selection-change="handleSelectionChange">
        <template slot="search">
          <el-form-item label="统计维度">
            <el-cascader v-model="customQuery.dimId" placeholder="请选择统计维度" :show-all-levels="false"
              :options="parentDimensionOptions" filterable clearable :props="{
                value: 'id',
                label: 'name',
                children: 'subDimensionsInfoVOS',
                checkStrictly: true,
                emitPath: false,
                expandTrigger: 'hover',
              }" />
          </el-form-item>
        </template>
        <template slot="no-data">
          <empty-icon />
        </template>
      </el-data-table>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import EmptyIcon from '@/components/common/empty-icon.vue'
import Navbar from '@/components/common/Navbar.vue'
import { INDICATOR_TYPE } from '@/const/indicator-management'
// import {dimensionsAllList} from '@/api/indicator-classification'
// import {indicatorDimensionList} from '@/api/indicator-dimension'
// import {connectionlist} from '@/api/connection'

export default {
  name: 'flowPage',
  components: {
    EmptyIcon,
    Navbar
  },
  data() {
    return {
      parentDimensionOptions: [],
      dataSourcsList: [],
      constants: {},
      customQuery: {},
      tableConfig: {
        dataPath: 'data.list',
        totalPath: 'data.totalElements',
        paginationSize: 10,
        url: '/indicator-data',
        hasOperation: true,
        hasNew: false,
        hasDelete: false,
        hasEdit: false,
        // paginationLayout: 'total, sizes, prev, pager, next',
        extraButtons: [
          {
            text: '查看',
            show: () => this.permissionList.includes('indicator:data:info'),
            atClick: ({ indicatorCode, indicatorTypeName }) => {
              const { isOther } = this.$route.query || ''

              this.$router.push({
                path: '/target/look-over',
                query: {
                  indicatorCode,
                  indicatorTypeName,
                  isOther,
                },
              })
              return Promise.resolve(false)
            },
          },
        ],
        tableAttrs: {
          stripe: false,
          size: 'medium',
          headerRowClassName: 'table-header-row',
          headerCellStyle: {
            backgroundColor: '#eaf1fc',
            fontWeight: '400',
            color: '#606266',
          },
        },
        columns: [
          {
            label: '指标编码',
            prop: 'indicatorCode',
            showOverflowTooltip: true,
          },
          {
            label: '指标名称',
            prop: 'indicatorName',
            showOverflowTooltip: true,
          },
          {
            label: '指标类型',
            prop: 'indicatorTypeName',
            showOverflowTooltip: true,
          },
          {
            label: '指标目录',
            prop: 'indicatorCat',
            showOverflowTooltip: true,
          },

          {
            label: '统计维度',
            prop: 'indicatorDim',
            showOverflowTooltip: true,
          },
          {
            label: '数据量',
            prop: 'indicatorDataCount',
            showOverflowTooltip: true,
          },
          {
            label: '最新统计时间',
            prop: 'statisticsEndTime',
            showOverflowTooltip: true,
            minWidth: '120',
          },
        ],
        searchForm: [
          {
            type: 'input',
            id: 'name',
            label: '指标名称或编码',
            collapsible: false /* 不被折叠 */,
            el: {
              placeholder: '请输入指标名称或编码',
              clearable: true,
              filterable: true,
              maxlength: 20,
            },
          },
          {
            type: 'select',
            id: 'indicatorType',
            label: '指标类型',
            el: {
              placeholder: '请选择指标类型',
              clearable: true,
              filterable: true,
            },
            $options: INDICATOR_TYPE.options,
          },
        ],
      },
    }
  },
  computed: {
    ...mapState(['isOther', 'permissionList']),
  },
  created() {
    this.getAll()
    this.getAllComm()
    this.getAllDimensionOptions()
  },
  methods: {
    handleSelectionChange() { },

    dataFormatterOptions(data) {
      return data.map(({ name, id }) => ({
        label: name,
        value: id,
      }))
    },

    // 获取维度列表
    async getAll() {
      // try {
      //   const {
      //     data: {data: list},
      //   } = await dimensionsAllList({isAtom: false})

      //   const wdList = this.dataFormatterOptions(list)

      //   this.$nextTick(() => {
      //     this.$refs.zb.$refs.searchForm.setOptions('dimId', wdList)
      //   })
      // } catch (error) {
      //   this.$message.error(error.message)
      // }
    },

    // 获取数据源列表
    async getAllComm() {
      // try {
      //   const {
      //     data: {data},
      //   } = await connectionlist()

      //   const wdList = data.map(({connName, id}) => ({
      //     label: connName,
      //     value: id,
      //   }))

      //   this.$nextTick(() => {
      //     this.$refs.zb.$refs.searchForm.setOptions('dataConnId', wdList)
      //   })
      // } catch (error) {
      //   this.$message.error(error.message)
      // }
    },

    // 获取维度
    async getAllDimensionOptions() {
      const params = {
        page: 1,
        size: 999,
      }
      // try {
      //   const {
      //     status,
      //     message,
      //     data: {
      //       data: {list},
      //     },
      //   } = await indicatorDimensionList(params)

      //   if (+status !== 200) return this.$message.error(message)
      //   this.parentDimensionOptions = list || []
      // } catch (error) {
      //   this.$message.error(error.message)
      // }
    },
  },
}
</script>
<style lang="less" scoped>
@import '@/assets/global.less';

body {
  width: 1080px;
  height: 1920px;
  color: azure;
}

.left {
  width: 23%;
  position: absolute;
  left: 0;
  color: rgb(122, 196, 253);

  .breadcrumb {
    border: 1px solid whitesmoke;
    font-weight: 400;
    font-style: normal;
    font-size: 18px;
  }

  .catalog_dimension {
    border: 1px solid whitesmoke;
    border-width: 0px;
    position: absolute;
    left: -2px;
    top: -2px;
    width: 324px;
    height: 935px;
  }
}

.right {
  width: 75%;
  position: absolute;
  right: 0;
  border: 1px solid whitesmoke
}
</style>
