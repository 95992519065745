<template>
  <div class="app-container">
    <navbar />
    <!-- 搜索工作栏 -->
    <div class="page_contain">
      <!-- 右侧列表 -->
      <el-form :model="queryParams" ref="queryForm" size="middle" :inline="true" @submit.native.prevent>
        <el-row>
          <el-form-item>
            <el-input v-model="queryParams.query" placeholder="请输入内容" clearable prefix-icon="el-icon-search" />
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="handleQuery">查询</el-button>
            <el-button type="primary" @click="handleClickAdd">新增</el-button>
            <!-- 拓扑结构按钮 -->
            <el-button v-show="!show_table" class="btn_change" type="primary">
              <img src="../../assets/svg/btn/menu_active.svg" alt="">
            </el-button>
            <el-button v-show="show_table" class="btn_change" type="info" @click="clickShowMenu">
              <img src="../../assets/svg/btn/menu.svg" alt="">
            </el-button>
            <!-- 列表按钮 -->
            <el-button v-show="show_table" class="btn_change" type="primary">
              <img src="../../assets/svg/btn/list_active.svg" alt="">
            </el-button>
            <el-button v-show="!show_table" class="btn_change" type="info" @click="clickShowTable">
              <img src="../../assets/svg/btn/list.svg" alt="">
            </el-button>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item v-show="show_table" label="服务器类型" prop="serverType">
            <el-radio label="" v-model='queryParams.serverType' @change="handleQuery">全部</el-radio>
            <el-radio v-for="server in dict_server_type" :label="server.value" v-model='queryParams.serverType'
              @change="handleQuery">
              {{ server.label }}
            </el-radio>
          </el-form-item>
        </el-row>
      </el-form>
      <!-- 表格展示 -->
      <div v-show="show_table" class="portal_container">

        <!-- 查询结果 -->
        <el-table ref="form" v-loading="loading" :data="serverList" :key="tableKey" row-key="id" stripe border>
          <el-table-column align="center" width="58" label="序号" key="id" prop="id">
            <template slot-scope="scope">
              {{ (queryParams.pageNo - 1) * queryParams.pageSize + scope.$index + 1 }}
            </template>
          </el-table-column>
          <el-table-column align="center" label="服务器名称" key="serverName" prop="serverName" />
          <el-table-column align="center" label="部门编号" key="deptCode" prop="deptCode" />
          <el-table-column align="center" label="部门名称" key="deptName" prop="deptName" />
          <el-table-column align="center" label="部门前置ip" key="host" prop="host" />
          <el-table-column align="center" label="CPU利用率" key="cpuUsage" prop="cpuUsage">
            <template slot-scope="scope">
              <el-progress :percentage="formatPercentage(scope.row.cpuUsage)" :format="formatPercentShow"></el-progress>
            </template>
          </el-table-column>
          <el-table-column align="center" label="内存利用率" key="memUsage" prop="memUsage">
            <template slot-scope="scope">
              <el-progress :percentage="formatPercentage(scope.row.memUsage)" :format="formatPercentShow"></el-progress>
            </template>
          </el-table-column>
          <el-table-column align="center" label="磁盘利用率" key="diskUsage" prop="diskUsage">
            <template slot-scope="scope">
              <el-progress :percentage="formatPercentage(scope.row.diskUsage)" :format="formatPercentShow"></el-progress>
            </template>
          </el-table-column>
          <el-table-column align="center" label="连接状态" key="connected" prop="connected">
            <template slot-scope="scope">
              <span>{{ scope.row.connected ? '已连接' : '未连接' }}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" min-width="200px" label="操作" class-name="small-padding fixed-width">
            <template v-slot="scope">
              <el-button size="mini" type="text" @click="handleRowEdit(scope.row)">编辑</el-button>
              <el-button size="mini" type="text" @click="handleRowDelete(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <pagination v-show="total > 0" :total="total" :page.sync="queryParams.pageNo" :limit.sync="queryParams.pageSize"
          @pagination="handleQuery" />
      </div>
      <PrintServer v-show="!show_table" class="portal_container" :serverAll="serverAll" />
    </div>

    <DialogServer :serverDialogOpen="serverDialogOpen" :serverDialogTitle="serverDialogTitle" :departList="departList"
      ref="server" @closeDialog="closeDialog" />
  </div>
</template>

<script>
import { listExchServer, pageExchServer, deleteExchServer, runInfoExchServer } from '@/api/system/exch-server'
import { userDepts } from "@/api/system/dept";
import Navbar from '@/components/common/Navbar.vue';
import DialogServer from './dialogServer.vue'
import PrintServer from './printServer.vue'
import dict from '../mixins/dict.js'
export default {
  name: "ExchServer",
  components: { Navbar, DialogServer, PrintServer },
  mixins: [dict],
  data() {
    return {
      // 遮罩层
      loading: true,
      // 页面展示
      show_table: false, // true表格，false拓普关系
      // 表单参数
      form: {},
      // 表单校验
      rules: {
      },
      // 查询参数
      queryParams: {
        pageNo: 1,
        pageSize: 10,
        serverType: undefined,
        query: '',
      },
      total: 0,
      serverAll: [], // 前置服务器列表（全部）
      serverList: [], // 前置服务器列表（分页）
      runInfoList: [], // 运行信息
      // 弹窗
      serverDialogOpen: false,
      serverDialogTitle: '',
      // 部门下拉
      departList: [],
      askingInfo: false, // 
      tableKey: 1,
    };
  },
  created() {
    this.getDepartList()
    this.getAllServer()
    this.getPageServer(this.updateServerListRunInfo)
  },
  methods: {
    // 全部前置服务器 目前使用pageExchServer空查
    // 暂不用listExchServer，它会去查运行信息，返回很慢
    getAllServer() {
      pageExchServer().then(response => {
        if (response.data) {
          this.serverAll = response.data.list || [];
        }
      }, err => {
        this.$message.error(err.message)
      });
    },
    // 分页前置服务器 handleQuery
    handleQuery() {
      this.getPageServer(this.updateServerListRunInfo)
    },
    getPageServer(callback) {
      pageExchServer(this.queryParams).then(
        response => {
          if (response.data) {
            this.serverList = response.data.list || [];
            this.total = response.data.total;
            this.loading = false
            callback && callback()
          }
        }, err => {
          this.$message.error(err.message)
        })
    },
    // 获取部门下拉列表
    getDepartList() {
      userDepts().then(response => {
        if (response.data) {
          this.departList = response.data || [];
        }
      }, err => {
        this.$message.error(err.message)
      });
    },
    // 运行信息----------------------------------------
    getRunInfo(server, callback) {
      runInfoExchServer(server.id).then(res => {
        if (res.data) {
          this.updateRunInfoList(res.data)
          callback && callback()
        }
      }, err => {
        this.$message.error(err.message)
      })
    },
    updateRunInfoList(info) {
      this.runInfoList = this.runInfoList.filter(item => item.id != info.id)
      this.runInfoList.push(info)
    },
    mergeListInfo() {
      // console.log('merge', this.serverList, this.runInfoList);
      this.serverList.map(el => {
        let obj = this.runInfoList.find(item => item.id == el.id)
        if (obj) {
          el.cpuUsage = obj.cpuUsage
          el.memUsage = obj.memUsage
          el.diskUsage = obj.diskUsage
          el.connected = obj.connected
        }
      })
      // 解决数据更新但页面展示滞后问题
      this.$nextTick(() => { this.tableKey++ });
      console.log('merge result', this.serverList);
    },
    // 查询list2中没有的list1元素（按照id查询）
    getDiffList(list1, list2) {
      let diffList = []
      list1.forEach(el => {
        if (!list2.find(item => item.id == el.id))
          diffList.push(el)
      })
      return diffList
    },
    updateServerListRunInfo() {
      // 节流
      if (this.askingInfo == true) return
      this.askingInfo = true
      let diffList = this.getDiffList(this.serverList, this.runInfoList)
      if (diffList.length) {
        for (let i = 0; i < diffList.length; i++) {
          this.getRunInfo(diffList[i], this.mergeListInfo)
          if (i == diffList.length - 1) {
            this.askingInfo = false // 存在异步风险，这里不是waterfall
          }
        }
      } else {
        // 已经都查过了，直接merge
        this.mergeListInfo()
        this.askingInfo = false
      }
    },
    // 切换页面----------------------------------------
    clickShowTable() {
      if (this.show_table) return
      this.show_table = true
    },
    clickShowMenu() {
      this.getAllServer()
      if (!this.show_table) return
      this.show_table = false
    },
    // 弹窗--------------------------------------------
    handleClickAdd() {
      this.serverDialogOpen = true
      this.serverDialogTitle = "新增节点"
      this.$refs.server.reset()
    },
    closeDialog(server) {
      this.serverDialogOpen = false
      if (server) {
        this.getRunInfo(server, this.mergeListInfo)
        this.getAllServer()
        this.handleQuery()  // 请求刷新
      }
    },
    // 表格--------------------------------------------
    handleRowEdit(row) {
      this.serverDialogOpen = true
      this.serverDialogTitle = "编辑节点"
      this.$refs.server.edit(row)
    },
    handleRowDelete(row) {
      this.$confirm('此操作将永久删除此信息，确定继续？').then(() => {
        deleteExchServer(row.id).then().then(res => {
          this.$message.success('删除成功')
          this.handleQuery() // 请求刷新
        }, err => {
          this.$message.error(err)
        })
      }).catch(() => { })
    },
    //-------------------------------------------------------
    formatPercentage(number) {
      return number ? parseFloat((number * 100).toFixed(2)) : 0.00;
    },
    formatPercentShow(percentage) {
      return percentage ? `${percentage}%` : '0.00%';
    },
  },

};
</script>
<style lang="less" scoped>
.page_contain {
  position: relative;
  height: 930px;
  margin: 0 20px;
}

/deep/ .el-button--info,
.el-button--info:focus,
.el-button--info:hover {
  background: #1f499b;
  border: 1px solid #045b9f;
}

.btn_change {
  padding: 12px 12px;
}
</style>
