var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('navbar'),_vm._m(0),_c('div',{staticClass:"right"},[_c('el-data-table',_vm._b({ref:"zb",staticClass:"table-container",attrs:{"customQuery":_vm.customQuery},on:{"update:customQuery":function($event){_vm.customQuery=$event},"update:custom-query":function($event){_vm.customQuery=$event},"selection-change":_vm.handleSelectionChange}},'el-data-table',_vm.tableConfig,false),[_c('template',{slot:"search"},[_c('el-form-item',{attrs:{"label":"统计维度"}},[_c('el-cascader',{attrs:{"placeholder":"请选择统计维度","show-all-levels":false,"options":_vm.parentDimensionOptions,"filterable":"","clearable":"","props":{
              value: 'id',
              label: 'name',
              children: 'subDimensionsInfoVOS',
              checkStrictly: true,
              emitPath: false,
              expandTrigger: 'hover',
            }},model:{value:(_vm.customQuery.dimId),callback:function ($$v) {_vm.$set(_vm.customQuery, "dimId", $$v)},expression:"customQuery.dimId"}})],1)],1),_c('template',{slot:"no-data"},[_c('empty-icon')],1)],2)],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left"},[_c('div',{staticClass:"breadcrumb"},[_vm._v("面包屑TODO")]),_c('div',{staticClass:"catalog_dimension"},[_vm._v("编目维度TODO")])])
}]

export { render, staticRenderFns }