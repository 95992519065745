<template>
  <div class="app-container">
    <navbar />
    <!-- 搜索工作栏 -->
    <div v-show="search_page == 'index'" class="page_contain">
      <!-- 左侧编目维度 -->
      <CatalogTree class="catalog_dimension" :treeOptions="treeOptions" :defaultProps="defaultProps" @loadNode="loadNode"
        @handleNodeClick="handleNodeClick" />

      <!-- 右侧列表 -->
      <div v-show="search_table" class="portal_container">
        <div>
          <el-form :model="queryParams" ref="queryForm" size="middle" :inline="true">
            <div class="querybox">
              <el-button type="primary" @click="handleClickAdd">新增</el-button>
              <el-button @click="handleBatchUpline">批量上线</el-button>
              <el-button @click="handleBatchOffline">批量下线</el-button>
              <el-button @click="handleBatchDelete">批量删除</el-button>
            </div>
            <div class="querybox">
              <el-form-item label="共享类型" prop="datadirName">
                <el-select v-model="queryParams.shareType" placeholder="请选择" clearable>
                  <el-option v-for="item in dict_share_type" :key="parseInt(item.value)" :label="item.label"
                    :value="parseInt(item.value)"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="开放类型" prop="datadirName">
                <el-select v-model="queryParams.openType" placeholder="请选择" clearable>
                  <el-option v-for="item in dict_open_type" :key="parseInt(item.value)" :label="item.label"
                    :value="parseInt(item.value)"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="资源格式" prop="datadirName">
                <el-select v-model="queryParams.resourceType" placeholder="请选择" clearable>
                  <el-option v-for="item in dict_resource_type" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="目录名称" prop="name">
                <el-input v-model="queryParams.name" placeholder="请输入内容" clearable size="middle" />
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="handleQuery">查询</el-button>
              </el-form-item>
            </div>
            <div class="querybox">
              <el-radio-group v-model="queryParams.viewState" @change="handleQuery">
                <el-radio-button :label="undefined">全部</el-radio-button>
                <el-radio-button v-for="item in dict_state_view" :label="parseInt(item.value)">
                  {{ item.label }}
                </el-radio-button>
              </el-radio-group>
            </div>
          </el-form>

          <!-- 查询结果 -->
          <el-table ref="form" v-loading="loading" :data="datadirList" row-key="id" stripe border key="tableKey">
            <el-table-column type="selection" width="40" />
            <el-table-column align="center" width="58" label="序号" key="id" prop="id">
              <template slot-scope="scope">
                {{ (queryParams.pageNo - 1) * queryParams.pageSize + scope.$index + 1 }}
              </template>
            </el-table-column>
            <el-table-column align="center" width="150" label="目录名称" key="name" prop="name" />
            <el-table-column align="center" width="110" label="来源组织" key="label_dept" prop="label_dept" />
            <el-table-column align="center" width="120" label="共享类型" key="shareType" prop="shareType"
              :formatter="shareTypeFormat" />
            <el-table-column align="center" width="120" label="开放类型" key="openType" prop="openType"
              :formatter="openTypeFormat" />
            <el-table-column align="center" width="120" label="目录状态" key="state" prop="state" :formatter="stateFormat" />
            <el-table-column align="center" width="95" label="资源格式" key="resourceType" prop="resourceType"
              :formatter="resourceTypeFormat" />
            <el-table-column align="center" width="95" label="更新周期" key="updateCycle" prop="updateCycle"
              :formatter="updateCycleFormat" />
            <el-table-column align="center" width="190" label="更新时间" key="updateTime" prop="updateTime"
              :formatter="timeFormatter" />
            <el-table-column align="center" min-width="200px" label="操作" class-name="small-padding fixed-width">
              <template v-slot="scope">
                <el-button size="mini" type="text" @click="handleRowDetail(scope.row, true)">详情</el-button>
                <el-button v-if="canEdit(scope.row.state)" size="mini" type="text"
                  @click="handleRowEdit(scope.row)">编辑</el-button>
                <el-button v-if="canRestore(scope.row.state)" size="mini" type="text"
                  @click="handleRowRestore(scope.row)">恢复</el-button>
                <el-button v-if="canDelete(scope.row.state)" size="mini" type="text"
                  @click="handleRowDelete(scope.row)">删除</el-button>
                <el-button v-if="canUpline(scope.row.state)" size="mini" type="text"
                  @click="handleRowUpline(scope.row)">上线</el-button>
                <el-button v-if="canOffline(scope.row.state)" size="mini" type="text"
                  @click="handleRowOffline(scope.row)">下线</el-button>
                <el-button v-if="canBind(scope.row.state)" size="mini" type="text"
                  @click="handleRowBind(scope.row)">挂载数据</el-button>
                <!-- <el-button v-if="canExample(scope.row.state)" size="mini" type="text" @click="handleRowExample(scope.row)">数据预览</el-button> -->
              </template>
            </el-table-column>
          </el-table>
          <pagination v-show="total > 0" :total="total" :page.sync="queryParams.pageNo" :limit.sync="queryParams.pageSize"
            @pagination="getDataDirList" />
        </div>
      </div>

      <!-- 详情 -->
      <DatadirDetail v-show="!search_table" class="portal_container" :datadir="datadirDetail" ref="datadir_detail"
        @clickBack="clickBack" :rootMap="rootMap" :userList="userList" />
    </div>

    <!-- 新增、编辑目录 -->
    <DatadirAdd v-show="search_page == 'add'" class="page_contain" :departList="departList" :systemList="systemList"
      ref="datadir_add" @clickBack="clickBack" @addDatadir="addDatadir" @editDatadir="editDatadir" :userList="userList"
      :treeOptions="treeOptions" @loadNode="loadNode" :auditorUser="auditorUser" />

    <!-- 绑定数据 -->
    <DatadirBindDB class="page_contain" v-show="search_page == 'bind_DB'" @clickBack="clickBack" ref="bindDB" />
    <DatadirBindAPI class="page_contain" v-show="search_page == 'bind_API'" @clickBack="clickBack" ref="bindAPI" />
    <DatadirBindFILE class="page_contain" v-show="search_page == 'bind_FILE'" @clickBack="clickBack" ref="bindFILE" />
  </div>
</template>

<script>
import { rootCatalog, childCatalog, listUser, listAuditor, userDepts, Listsystem } from "@/api/system/dept";
import {
  listDatadir, addDatadirRequest, deleteDatadirRequest, restoreDatadirRequest,
  uplineDatadirRequest, offlineDatadirRequest, editDatadirRequest, detailDatadirRequest
} from "@/api/system/datadir";
import Navbar from '@/components/common/Navbar.vue'
import CatalogTree from "@/components/CatalogTree"
import DatadirAdd from "./datadirAdd.vue"
import DatadirDetail from "./datadirDetail.vue"
import DatadirBindDB from "./datadirBindDB.vue"
import DatadirBindAPI from "./datadirBindAPI.vue"
import DatadirBindFILE from "./datadirBindFILE.vue"
import dict from '../mixins/dict.js'

export default {
  name: "DataDir",
  components: {
    Navbar, CatalogTree, DatadirAdd, DatadirDetail,
    DatadirBindDB, DatadirBindAPI, DatadirBindFILE
  },
  mixins: [dict],
  data() {
    return {
      // 是否展开，默认全部展开
      // isExpandAll: true,
      // 遮罩层
      loading: true,
      // 总条数
      total: 0,
      // 目录列表数据
      datadirList: [],
      // 编目列表数据
      // catalogList: [],
      // 用户下拉列表
      userList: [],
      auditorUser: {}, // 审核人-新增
      // 部门下拉列表
      departList: [],
      // 信息系统下拉列表
      systemList: [],
      // 编目列表
      rootList: [],
      // 编目根节点对应关系
      rootMap: {},
      // treeList: [],
      // 弹出层标题
      title: "",
      // 部门树选项
      treeOptions: undefined,
      defaultProps: {
        children: "children",
        isLeaf: "leaf",
        label: "name"
      },
      // 查询参数
      queryParams: {
        openType: '',
        shareType: '',
        resourceType: '',
        pageNo: 1,
        pageSize: 10,
        name: '',
        catalogId: '',
        fetchCatalogs: true // 获取catalogs
        // viewState: undefined,
      },
      userDialogId: null, // 关联用户弹窗，当前catalogId
      search_page: 'index',  // index展示查询页，add展示新增/编辑页, bind_xxx展示绑定页
      search_table: true, // true展示查询表结果，false展示详情
      datadirDetail: {},
      tableKey: 1, // 用于刷新
    };
  },
  watch: {
    // 根据名称筛选部门树
    treeName(val) {
      this.$refs.tree.filter(val);
    }
  },
  created() {
    this.refreshTree();
    this.getDataDirList();
    this.getAuditorUser();
    // this.getDepartList();
    this.getSystemList();
    this.getUserList();

  },
  methods: {
    // 获取用户下拉列表
    getUserList() {
      listUser().then(response => {
        if (response.data) {
          this.userList = response.data.list || [];
        }
      }, err => {
        this.$message.error(err.message)
      });
    },
    // 获取部门管理员下拉列表
    getAuditorUser() {
      listAuditor().then(response => {
        if (response.data) {
          this.auditorUser = response.data || {};
        }
      }, err => {
        this.$message.error(err.message)
      });
    },
    // 获取部门下拉列表
    // getDepartList() {
    //   userDepts().then(response => {
    //     if (response.data) {
    //       this.departList = response.data || [];
    //     }
    //   }, err => {
    //     this.$message.error(err.message)
    //   });
    // },
    // 获取信息系统下拉列表
    getSystemList() {
      Listsystem().then(response => {
        if (response.list) {
          this.systemList = response.list || [];
        }
      }, err => {
        this.$message.error(err.message)
      });
    },
    //--------------------------------------------------------------
    refreshTree() {
      // this.treeList = []
      this.getTreeRoot(this.getTreeFirstLever) // 懒加载
    },
    // 查询编目维度根节点
    getTreeRoot(callback) {
      rootCatalog().then(response => {
        this.rootList = this.formatTreeNode(response.data)
        this.rootList.forEach(item => {
          if (/部门/.test(item.catalogName)) {
            return this.rootMap[item.catalogId] = 'dept'
          }
          if (/基础/.test(item.catalogName)) {
            return this.rootMap[item.catalogId] = 'basic'
          }
          if (/主题/.test(item.catalogName)) {
            return this.rootMap[item.catalogId] = 'topic'
          }
        })
        // this.rootList.sort((a, b) => a.catalogId - b.catalogId)  // 按照根节点的 catalogId 排序
        callback && callback()
      });
    },
    getTreeFirstLever() {
      this.treeOptions = [];
      this.treeOptions.push(...this.handleTree(this.rootList, "id"));
      this.loading = false
      this.treeOptions.forEach(item => {
        this.getTreeChild(item)
        // console.log('tree each ', item)
      })
    },
    // 懒加载树
    loadNode(node, resolve) {
      // console.log("lazy load", node, resolve)
      if (!node.id || !node.data || node.data instanceof Array) return resolve([])
      this.getTreeChild(node.data, resolve)
    },
    // 查询根节点下的编目
    getTreeChild(root, callback) {
      childCatalog({ id: root.catalogId }).then(response => {
        let res_arr = this.formatTreeNode(response.data)
        this.loading = false;
        // 处理treeOptions
        // this.treeList = this.treeList.concat(res_arr)
        callback && callback(res_arr)
      }, err => {
        this.$message.error(err.message)
      })
    },
    // 预处理树状结构
    formatTreeNode(arr) {
      if (!arr || !arr instanceof Array) return []
      arr.forEach(i => {
        i.id = i.id || i.catalogId
        i.name = i.name || i.catalogName
        i.parentId = i.parentId || i.catalogPid || 0
      })
      return arr
    },
    // 节点单击事件
    handleNodeClick(data) {
      this.queryParams.catalogId = data.id;
      this.queryParams.viewState = undefined;
      this.handleQuery()
    },
    //-------------------------------------------------------------
    // 查询目录列表
    getDataDirList() {
      this.loading = true;
      this.search_table = true;
      this.search_page = 'index';
      listDatadir(this.queryParams).then(response => {
        if (response.data) {
          let arr = response.data.list || [];
          this.datadirFilter(arr)
          this.datadirList = arr
          this.total = response.data.total;
        }
        this.loading = false;
      }, err => {
        this.$message.error(err.message)
      });
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNo = 1;
      this.getDataDirList()
    },
    // 处理来源组织（所属部门）label_dept
    datadirFilter(arr) {
      arr.forEach(item => {
        if (item.catalogs instanceof Array) {
          item.catalogs.forEach(catalog => {
            let key = 'label_' + this.rootMap[catalog.catalogRootId]
            item[key] = catalog.catalogName
          })
        }
      })
      this.tableKey = Math.random()
    },
    // 可执行操作---------------------------------------------------
    canEdit(state) {
      let operationArr = [1, 2, 4, 8]
      return operationArr.indexOf(state) !== -1
    },
    canRestore(state) {
      let operationArr = [9]
      return operationArr.indexOf(state) !== -1
    },
    canDelete(state) {
      let operationArr = [1, 2, 4, 8]
      return operationArr.indexOf(state) !== -1
    },
    canUpline(state) {
      let operationArr = [2, 4, 8]
      return operationArr.indexOf(state) !== -1
    },
    canOffline(state) {
      let operationArr = [5, 7]
      return operationArr.indexOf(state) !== -1
    },
    canBind(state) {
      let operationArr = [2, 3, 4, 6, 7, 8, 9]
      return operationArr.indexOf(state) !== -1
    },
    //-------------------------------------------------------------
    handleClickAdd() {
      this.search_page = 'add'
      this.$refs.datadir_add.reset()
    },
    clickBack() {
      return this.getDataDirList()
    },
    handleRowEdit(row) {
      this.queryDetail(row.id, (detail) => {
        this.$refs.datadir_add.edit(detail)
        this.search_page = 'add'
      })
    },
    addDatadir(obj, toBind) {
      addDatadirRequest(obj).then(res => {
        if (res.data) {
          this.$message.success('新增成功')
          if (toBind) return this.handleRowBind(Object.assign({ id: res.data }, obj))

          this.search_page = 'index'
          this.getDataDirList() // 请求刷新
        }
      }, err => {
        this.$message.error(err)
      })
    },
    editDatadir(obj, toBind) {
      editDatadirRequest(obj).then(res => {
        if (res.data) {
          this.$message.success('编辑成功')
          if (toBind) return this.handleRowBind(obj)

          this.search_page = 'index'
          this.getDataDirList() // 请求刷新
        }
      }, err => {
        this.$message.error(err)
      })
    },
    //-------------------------------------------------------------
    // 上线、下线、删除
    handleBatchUpline() {
      if (this.$refs.form.selection.length == 0) {
        return this.$message.error('请选择批量上线的目录')
      }
      this.doUpline(this.getSelectArray())
    },
    handleBatchOffline() {
      if (this.$refs.form.selection.length == 0) {
        return this.$message.error('请选择批量下线的目录')
      }
      this.doOffline(this.getSelectArray())
    },
    handleBatchDelete() {
      if (this.$refs.form.selection.length == 0) {
        return this.$message.error('请选择批量删除的目录')
      }
      this.doDelete(this.getSelectArray())
    },
    getSelectArray() {
      let arr = []
      this.$refs.form.selection.forEach(item => { arr.push(item.id) })
      return arr
    },
    handleRowUpline(row) {
      this.doUpline([row.id])
    },
    handleRowOffline(row) {
      this.doOffline([row.id])
    },
    handleRowDelete(row) {
      this.doDelete([row.id])
    },
    handleRowRestore(row) {
      this.doRestore([row.id])
    },
    doUpline(arr) {
      this.$confirm('此操作将上线该目录并进行审核，确定继续？').then(() => {
        uplineDatadirRequest(arr).then(res => {
          this.$message.success('上线成功')
          this.getDataDirList() // 请求刷新
        }, err => {
          this.$message.error(err)
        })
      }).catch(() => { })
    },
    doOffline(arr) {
      this.$confirm('此操作将下线该目录并进行审核，确定继续？').then(() => {
        offlineDatadirRequest(arr).then(res => {
          this.$message.success('下线成功')
          this.getDataDirList() // 请求刷新
        }, err => {
          this.$message.error(err)
        })
      }).catch(() => { })
    },
    doDelete(arr) {
      this.$confirm('此操作将永久删除此信息，确定继续？').then(() => {
        deleteDatadirRequest(arr).then(res => {
          this.$message.success('删除成功')
          this.getDataDirList() // 请求刷新
        }, err => {
          this.$message.error(err)
        })
      }).catch(() => { })
    },
    doRestore(arr) {
      this.$confirm('此操作将恢复该信息，确定继续？').then(() => {
        restoreDatadirRequest(arr).then(res => {
          this.$message.success('恢复成功')
          this.getDataDirList() // 请求刷新
        }, err => {
          this.$message.error(err)
        })
      }).catch(() => { })
    },
    // 详情-----------------------------------------------
    handleRowDetail(row) {
      this.queryDetail(row.id, () => {
        this.search_table = false
      })
    },
    // 根据目录id查询详情
    queryDetail(id, callback) {
      detailDatadirRequest(id).then(res => {
        if (res && res.data) {
          this.datadirDetail = this.detailFormatRoot(res.data)
          callback && callback(res.data)
        }
      }, err => {
        this.$message.error(err)
      })
    },
    // 格式化详情：部门、基础、主题
    detailFormatRoot(detail) {
      if (Object.keys(this.rootMap).length < 1) return detail// 说明根节点未加载或未配置好

      if (detail.catalogs instanceof Array) {
        detail.catalogs.forEach(catalog => {
          let label = 'label_' + this.rootMap[catalog.catalogRootId]
          detail[label] = catalog.catalogName
          let value = 'value_' + this.rootMap[catalog.catalogRootId]
          detail[value] = catalog.catalogId
        })
      }
      return detail;
    },
    // 绑定数据-----------------------------------------------
    handleRowBind(row) {
      this.queryDetail(row.id, (detail) => {
        // 没有绑定数据项，提示先绑定数据项
        if (!detail.dataItems) {
          this.$refs.datadir_add.edit(detail)
          this.search_page = 'add'
          this.$message.warning('请先绑定数据项，再挂载数据')
        } else {
          this.search_page = row.resourceType ? 'bind_' + row.resourceType : 'index'
          this.$refs['bind' + row.resourceType].reset(detail) // 新增挂载
        }
      })
    },
  },

};
</script>
<style lang="less" scoped>
.page_contain {
  position: relative;
  height: 930px;
  margin: 0 20px;
}

/deep/.el-button--default:hover {
  border: solid 1px #007cd5;
  background-color: #1f499b;
  color: #7ac4fd;
}

/* 编目维度 */
.catalog_dimension {
  text-align: center;
  position: absolute;
  left: 0;
  padding: 0 10px !important;
  background-color: #06346e;
  border-radius: 5px;
  height: 100%;
  width: 16%;
  background: url('../../assets/svg/bg_tree.svg');
  background-size: 100% 100%;
}

.querybox {
  margin: 0 0 10px 0;
}

/* 搜索栏 */
.portal_container {
  position: absolute;
  right: 0;
  width: 83%;

  .el-form-item {
    margin-bottom: 0px;
  }
}

.btn_setting {
  font-size: 21px;
  color: #82ceff;
}

.btn_setting:hover {
  cursor: pointer;
  color: #9ee6ff;
}
</style>
