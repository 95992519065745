<template>
  <div class="des_container">
    <div v-for="item in keys" :key="item.name" class="item">
      <div class="item_left">{{ item.name }}</div>
      <div class="item_right">
        {{ item.value }}
      </div>
      <!-- <el-input
        v-else
        key="passwordshow"
        class="item_right"
        :value="item.value"
        disable
        :show-password="true"
      >
         -->
      <!-- </el-input> -->
    </div>
  </div>
</template>

<script>
import {linkparam} from '@/api/system/datasource'

const showList = [
  {name: '数据源类型', value: '', meta: {}},
  {name: '数据源名称', value: '', meta: {}},
  {name: '所属部门', value: '', meta: {}},
  {name: '服务器名称', value: '', meta: {}},
  // {name: '数据库IP', value: '', meta: {}},
  // {name: '端口号', value: '', meta: {}},
  // {name: '数据库名称', value: '', meta: {}},
  // {name: '字符集编码', value: '', meta: {}},
  // {name: '用户名', value: '', meta: {}},
  // {name: '密码', value: '', meta: {isCiphertext: true}},
]

export default {
  props: ['detailData'],
  data() {
    return {
      keys: [],
    }
  },
  // created() {
  //   this.setData()
  // },
  methods: {
    setData() {
      this.keys = showList.map((v) => ({
        ...v,
        value: this.mapKey(v.name),
      }))
      if (this.$props.detailData) {
        const linkParams = JSON.parse(this.$props.detailData.linkParams)
        this.queryShowKeys(this.$props.detailData.dsTypeName, linkParams)
      }
    },
    mapKey(key) {
      //   console.log(this)
      const {detailData} = this.$props
      switch (key) {
        case '数据源类型':
          return detailData['dsTypeName']
        case '数据源名称':
          return detailData['dsName']
        case '所属部门':
          return detailData['deptName']
        case '服务器名称':
          return detailData['serverName']
        case '数据库IP':
          return detailData['host']
        case '端口号':
          return detailData['port']
        case '数据库名称':
          return detailData['database']
        case '字符集编码':
          return detailData['encoding']
        case '用户名':
          return detailData['username']
        case '密码':
          return detailData['password']
        default:
          return ''
      }
    },
    // 查询动态字段
    queryShowKeys(dataSourceType, linkParams) {
      linkparam({dataSourceType})
        .then((res) => {
          const extraKeys = []
          res.data.forEach((v) => {
            extraKeys.push({
              name: v.colDescription,
              value: linkParams[v.colName],
            })
          })
          this.keys = [...this.keys, ...extraKeys]
        })
        .catch((e) => {
          console.log('eee', e)
        })
    },
  },
  computed: {},
  watch: {
    detailData: {
      immediate: true,
      handler() {
        this.setData()
      },
    },
  },
}
</script>

<style lang="less" scoped>
.des_container {
  width: 100%;
  height: 100%;
  padding: 50px;
  .item {
    // background: transparent;
    display: flex;
    flex-direction: row;
    justify-content: center;
    line-height: 50px;
    height: 50px;

    .item_left {
      width: 100px;
      text-align: right;
      margin-right: 10px;
      margin-left: 50px;
    }

    .item_right {
      flex: 1;
      margin-left: 10px;
      text-align: left;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
</style>
