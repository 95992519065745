<template>
  <!-- 用户管理 -->
  <el-dialog title="用户管理" :visible="user_dialog_open" width="660px" append-to-body center @close="close">
    <div slot="footer" class="dialog-footer">
      <el-form ref="form" :model="form" label-width="200px">
        <el-row>
          <el-col :span="24">
            <el-form-item label="已关联用户" prop="users" style="text-align: left;">
              <!-- <el-input v-model="form.users" placeholder="请输入内容"></el-input> -->
              <el-tag v-for="tag in tagList" :key="tag.nickname" closable @close="handleDelete(tag)">
                {{ tag.nickname }}
              </el-tag>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="选择关联用户" prop="userIds">
              <el-select v-model="form.userIds" placeholder="请选择" multiple>
                <el-option v-for="item in selectList" :label="item.nickname" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-button type="primary" @click="save">保存</el-button>
      <el-button type="info" @click="close">取消</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "dialogUser",
  props: ['user_dialog_open', 'userList', 'catalogUserList', 'addUser', 'deleteUser'],
  data() {
    return {
      form: {
        userIds: []
      }
    }
  },
  methods: {
    save() {
      this.$emit('addUser', this.form.userIds)
    },
    handleDelete(tag) {
      this.$emit('deleteUser', tag.id)
    },
    close() {
      this.$emit('closeDialog')
    }
  },
  computed: {
    tagList() {
      let list = JSON.parse(JSON.stringify(this.catalogUserList))
      list.map(el => {
        el.nickname = this.userList.find(item => item.id == el.userId).nickname
      })
      return list
    },
    selectList() {
      this.form.userIds = []
      return this.userList.filter(item => {
        let hasConnect = false
        this.catalogUserList.forEach(el => {
          hasConnect = hasConnect || (el.userId == item.id)
        });
        return !hasConnect
      })
    }
  }
}
</script>

<style lang="less" scoped>
.btn_add {
  text-align: right;
  padding: 0 0 10px 0;
  color: #82ceff;
  cursor: pointer;
}

/deep/ .el-button--info,
.el-button--info:focus,
.el-button--info:hover {
  background: #1f499b;
  border: 1px solid #045b9f;
}

/deep/ .el-tag{
  margin: 0 5px;
  background-color: #0d4890;
  border: #0683e5 1px solid;
  color: #fff;
  font-size: 16px;
}
</style>