<template>
  <div class="app-container">

    <!-- 搜索工作栏 -->
    <el-row class="page_contain" :gutter="20">
      <!--编目维度-->
      <el-col class="catalog_dimension" :span="4" :xs="24">
        <div>
          <div class="btn_dimension">部门</div>
          <div class="btn_dimension">基础</div>
          <div class="btn_dimension">主题</div>
        </div>
        <div class="line-decoration"></div>
        <div class="head-container">
          <el-input v-model="deptName" placeholder="请输入内容" clearable size="small" prefix-icon="el-icon-search"
            style="margin: 20px 0" />
        </div>
        <div class="head-container">
          <el-tree :data="deptOptions" :props="defaultProps" :expand-on-click-node="false"
            :filter-node-method="filterNode" ref="tree" default-expand-all highlight-current
            @node-click="handleNodeClick" />
        </div>
      </el-col>
      <!--搜索框-->
      <el-col class="catalog" :span="20" :xs="24">
        <el-form :model="queryParams" ref="queryForm" size="small" :inline="true" v-show="showSearch" label-width="68px">
          <el-form-item label="排序方式" prop="sort">
            <el-radio :label="1" v-model='queryParams.sort'>更新时间</el-radio>
            <el-radio :label="2" v-model='queryParams.sort'>更新周期</el-radio>
            <el-radio :label="3" v-model='queryParams.sort'>访问排序</el-radio>
          </el-form-item>
          <el-form-item>
            <div class="head-container">
              <el-input v-model="search_info" placeholder="请输入内容" clearable size="small" prefix-icon="el-icon-search"
                style="margin-bottom: 20px" />
            </div>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" @click="handleQuery">查询</el-button>
            <!-- <el-button icon="el-icon-refresh" @click="resetQuery">重置</el-button> -->
          </el-form-item>
        </el-form>

        <!-- 查询结果 -->
        <el-table v-loading="loading" :data="userList">
          <el-table-column label="序号" align="center" key="id" prop="id" v-if="columns[0].visible" />
          <el-table-column label="目录名称" align="center" key="username" prop="username" v-if="columns[1].visible"
            :show-overflow-tooltip="true" />
          <el-table-column label="来源组织" align="center" key="nickname" prop="nickname" v-if="columns[2].visible"
            :show-overflow-tooltip="true" />
          <el-table-column label="所属部门" align="center" key="deptName" prop="dept.name" v-if="columns[3].visible"
            :show-overflow-tooltip="true" />
          <!-- <el-table-column label="手机号码" align="center" key="mobile" prop="mobile" v-if="columns[4].visible" width="120" /> -->
          <!-- <el-table-column label="状态" key="status" v-if="columns[5].visible" align="center">
            <template v-slot="scope">
              <el-switch v-model="scope.row.status" :active-value="0" :inactive-value="1"
                @change="handleStatusChange(scope.row)" />
            </template>
          </el-table-column> -->
          <el-table-column label="更新时间" align="center" prop="createTime" v-if="columns[6].visible" width="160">
            <template v-slot="scope">
              <span>{{ parseTime(scope.row.createTime) }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" width="160" class-name="small-padding fixed-width">
            <template v-slot="scope">
              <el-button size="mini" type="text" icon="el-icon-edit" @click="handleUpdate(scope.row)">修改</el-button>
              <el-dropdown @command="(command) => handleCommand(command, scope.$index, scope.row)">
                <el-button size="mini" type="text" icon="el-icon-d-arrow-right">更多</el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="handleDelete" v-if="scope.row.id !== 1" size="mini" type="text"
                    icon="el-icon-delete">删除</el-dropdown-item>
                  <el-dropdown-item command="handleResetPwd" size="mini" type="text"
                    icon="el-icon-key">重置密码</el-dropdown-item>
                  <el-dropdown-item command="handleRole" size="mini" type="text"
                    icon="el-icon-circle-check">分配角色</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>

        <pagination v-show="total > 0" :total="total" :page.sync="queryParams.pageNo" :limit.sync="queryParams.pageSize"
          @pagination="getList" />
      </el-col>
    </el-row>

    <!-- 添加或修改参数配置对话框 -->
    <el-dialog :title="title" :visible.sync="open" width="600px" append-to-body>
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-row>
          <el-col :span="12">
            <el-form-item label="用户昵称" prop="nickname">
              <el-input v-model="form.nickname" placeholder="请输入用户昵称" />
            </el-form-item>
          </el-col>
          <!-- <el-col :span="12">
            <el-form-item label="归属部门" prop="deptId">
              <treeselect v-model="form.deptId" :options="deptOptions" :show-count="true" :clearable="false"
                placeholder="请选择归属部门" :normalizer="normalizer" />
            </el-form-item>
          </el-col> -->
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="手机号码" prop="mobile">
              <el-input v-model="form.mobile" placeholder="请输入手机号码" maxlength="11" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="邮箱" prop="email">
              <el-input v-model="form.email" placeholder="请输入邮箱" maxlength="50" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item v-if="form.id === undefined" label="用户名称" prop="username">
              <el-input v-model="form.username" placeholder="请输入用户名称" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item v-if="form.id === undefined" label="用户密码" prop="password">
              <el-input v-model="form.password" placeholder="请输入用户密码" type="password" show-password />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="备注">
              <el-input v-model="form.remark" type="textarea" placeholder="请输入内容"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary">确 定</el-button>
        <el-button>取 消</el-button>
      </div>
    </el-dialog>

    <!-- 分配角色 -->
    <el-dialog title="分配角色" :visible.sync="openRole" width="500px" append-to-body>
      <el-form :model="form" label-width="80px">
        <el-form-item label="用户名称">
          <el-input v-model="form.username" :disabled="true" />
        </el-form-item>
        <el-form-item label="用户昵称">
          <el-input v-model="form.nickname" :disabled="true" />
        </el-form-item>
        <el-form-item label="角色">
          <el-select v-model="form.roleIds" multiple placeholder="请选择">
            <el-option v-for="item in roleOptions" :key="parseInt(item.id)" :label="item.name"
              :value="parseInt(item.id)"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary">确 定</el-button>
        <el-button>取 消</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import {
  listCatalog,
  // addUser,
  // changeUserStatus,
  // delUser,
  // getUser,
  // resetUserPwd,
  // updateUser
} from "@/api/system/catalog";

import { listSimpleDepts } from "@/api/system/dept";
// import { listSimplePosts } from "@/api/system/post";

import { CommonStatusEnum } from "@/utils/constants";
import { assignUserRole, listUserRoles } from "@/api/system/permission";
import { listSimpleRoles } from "@/api/system/role";
import { getBaseHeader } from "@/utils/request";

export default {
  name: "DatadirManager",
  // components: { Treeselect },
  data() {
    return {
      // 遮罩层
      loading: true,
      // 导出遮罩层
      exportLoading: false,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 用户表格数据
      userList: null,
      // 弹出层标题
      title: "",
      // 部门树选项
      deptOptions: undefined,
      // 是否显示弹出层
      open: false,
      // 部门名称
      deptName: undefined,
      // 搜索内容
      search_info: '',
      // 默认密码
      initPassword: undefined,
      // 性别状态字典
      sexOptions: [],
      // 岗位选项
      postOptions: [],
      // 角色选项
      roleOptions: [],
      // 表单参数
      form: {},
      defaultProps: {
        children: "children",
        label: "name"
      },
      // 用户导入参数
      upload: {
        // 是否显示弹出层（用户导入）
        open: false,
        // 弹出层标题（用户导入）
        title: "",
        // 是否禁用上传
        isUploading: false,
        // 是否更新已经存在的用户数据
        updateSupport: 0,
        // 设置上传的请求头部
        headers: getBaseHeader(),
        // 上传的地址
        url: process.env.VUE_APP_BASE_API + '/admin-api/system/user/import'
      },
      // 查询参数
      queryParams: {
        pageNo: 1,
        pageSize: 10,
        sort: 1  // 1更新时间 2更新周期 3访问排序
      },
      // 列信息（权限）
      columns: [
        { key: 0, label: `序号`, visible: true },
        { key: 1, label: `目录名称`, visible: true },
        { key: 2, label: `用户昵称`, visible: true },
        { key: 3, label: `部门`, visible: true },
        { key: 4, label: `手机号码`, visible: true },
        { key: 5, label: `状态`, visible: true },
        { key: 6, label: `更新时间`, visible: true }
      ],
      // 表单校验
      rules: {
        username: [
          { required: true, message: "用户名称不能为空", trigger: "blur" }
        ],
        nickname: [
          { required: true, message: "用户昵称不能为空", trigger: "blur" }
        ],
        password: [
          { required: true, message: "用户密码不能为空", trigger: "blur" }
        ],
        email: [
          {
            type: "email",
            message: "'请输入正确的邮箱地址",
            trigger: ["blur", "change"]
          }
        ],
        mobile: [
          {
            pattern: /^(?:(?:\+|00)86)?1(?:3[\d]|4[5-79]|5[0-35-9]|6[5-7]|7[0-8]|8[\d]|9[189])\d{8}$/,
            message: "请输入正确的手机号码",
            trigger: "blur"
          }
        ]
      },
      // 是否显示弹出层（角色权限）
      openRole: false,

    };
  },
  watch: {
    // 根据名称筛选部门树
    deptName(val) {
      this.$refs.tree.filter(val);
    }
  },
  created() {
    this.getList();
    this.getTreeselect();

  },
  methods: {
    // 更多操作
    handleCommand(command, index, row) {
      switch (command) {
        case 'handleUpdate':
          this.handleUpdate(row);//修改客户信息
          break;
        case 'handleDelete':
          this.handleDelete(row);//红号变更
          break;
        case 'handleResetPwd':
          this.handleResetPwd(row);
          break;
        case 'handleRole':
          this.handleRole(row);
          break;
        default:
          break;
      }
    },
    /** 查询用户列表 */
    getList() {
      this.loading = true;
      listCatalog(this.queryParams).then(response => {
        this.userList = response.data.list;
        this.total = response.data.total;
        this.loading = false;
      }
      );
    },
    /** 查询部门下拉树结构 TODO */
    getTreeselect() {
      listSimpleDepts().then(response => {
        // 处理 deptOptions 参数
        this.deptOptions = [];
        this.deptOptions.push(...this.handleTree(response.data, "id"));
      });
      // listSimplePosts().then(response => {
      //   // 处理 postOptions 参数
      //   this.postOptions = [];
      //   this.postOptions.push(...response.data);
      // });
      // this.deptOptions = [];
    },
    // 筛选节点
    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    // 节点单击事件
    handleNodeClick(data) {
      this.queryParams.deptId = data.id;
      this.getList();
    },
    // 用户状态修改
    // handleStatusChange(row) {
    //   let text = row.status === CommonStatusEnum.ENABLE ? "启用" : "停用";
    //   this.$modal.confirm('确认要"' + text + '""' + row.username + '"用户吗?').then(function () {
    //     return changeUserStatus(row.id, row.status);
    //   }).then(() => {
    //     this.$modal.msgSuccess(text + "成功");
    //   }).catch(function () {
    //     row.status = row.status === CommonStatusEnum.ENABLE ? CommonStatusEnum.DISABLE
    //       : CommonStatusEnum.ENABLE;
    //   });
    // },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    // 取消按钮（角色权限）
    cancelRole() {
      this.openRole = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {
        id: undefined,
        deptId: undefined,
        username: undefined,
        nickname: undefined,
        password: undefined,
        mobile: undefined,
        email: undefined,
        sex: undefined,
        status: "0",
        remark: undefined,
        postIds: [],
        roleIds: []
      };
      this.resetForm("form");
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNo = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    // resetQuery() {
    //   this.resetForm("queryForm");
    //   this.handleQuery();
    // },
    /** 新增按钮操作 */
    // handleAdd() {
    //   this.reset();
    //   // 获得下拉数据
    //   this.getTreeselect();
    //   // 打开表单，并设置初始化
    //   this.open = true;
    //   this.title = "添加用户";
    //   this.form.password = this.initPassword;
    // },
    // /** 修改按钮操作 */
    // handleUpdate(row) {
    //   this.reset();
    //   this.getTreeselect();
    //   const id = row.id;
    //   getUser(id).then(response => {
    //     this.form = response.data;
    //     this.open = true;
    //     this.title = "修改用户";
    //     this.form.password = "";
    //   });
    // },
    /** 重置密码按钮操作 */
    // handleResetPwd(row) {
    //   this.$prompt('请输入"' + row.username + '"的新密码', "提示", {
    //     confirmButtonText: "确定",
    //     cancelButtonText: "取消"
    //   }).then(({ value }) => {
    //     resetUserPwd(row.id, value).then(response => {
    //       this.$modal.msgSuccess("修改成功，新密码是：" + value);
    //     });
    //   }).catch(() => { });
    // },
    // /** 分配用户角色操作 */
    // handleRole(row) {
    //   this.reset();
    //   const id = row.id
    //   // 处理了 form 的用户 username 和 nickname 的展示
    //   this.form.id = id;
    //   this.form.username = row.username;
    //   this.form.nickname = row.nickname;
    //   // 打开弹窗
    //   this.openRole = true;
    //   // 获得角色列表
    //   listSimpleRoles().then(response => {
    //     // 处理 roleOptions 参数
    //     this.roleOptions = [];
    //     this.roleOptions.push(...response.data);
    //   });
    //   // 获得角色拥有的菜单集合
    //   listUserRoles(id).then(response => {
    //     // 设置选中
    //     this.form.roleIds = response.data;
    //   })
    // },
    // /** 提交按钮 */
    // submitForm: function () {
    //   this.$refs["form"].validate(valid => {
    //     if (valid) {
    //       if (this.form.id !== undefined) {
    //         updateUser(this.form).then(response => {
    //           this.$modal.msgSuccess("修改成功");
    //           this.open = false;
    //           this.getList();
    //         });
    //       } else {
    //         addUser(this.form).then(response => {
    //           this.$modal.msgSuccess("新增成功");
    //           this.open = false;
    //           this.getList();
    //         });
    //       }
    //     }
    //   });
    // },
    // /** 提交按钮（角色权限） */
    // submitRole: function () {
    //   if (this.form.id !== undefined) {
    //     assignUserRole({
    //       userId: this.form.id,
    //       roleIds: this.form.roleIds,
    //     }).then(response => {
    //       this.$modal.msgSuccess("分配角色成功");
    //       this.openRole = false;
    //       this.getList();
    //     });
    //   }
    // },
    // /** 删除按钮操作 */
    // handleDelete(row) {
    //   const ids = row.id || this.ids;
    //   this.$modal.confirm('是否确认删除用户编号为"' + ids + '"的数据项?').then(function () {
    //     return delUser(ids);
    //   }).then(() => {
    //     this.getList();
    //     this.$modal.msgSuccess("删除成功");
    //   }).catch(() => { });
    // },
    // // 格式化部门的下拉框
    // normalizer(node) {
    //   return {
    //     id: node.id,
    //     label: node.name,
    //     children: node.children
    //   }
    // }
  }
};
</script>
<style scoped>
.page_contain {
  height: 930px;
}

/* 编目维度 */
.catalog_dimension {
  text-align: center;
  padding: 0 10px !important;
  background-color: #06346e;
  border: 3px solid #05559b;
  border-radius: 5px;
  height: 100%;
  width: 18%;
  margin: 5px;
}

.btn_dimension {
  display: inline-block;
  width: 30%;
  height: 40px;
  line-height: 50px;
  color: #7ac4fd;
  vertical-align: bottom;
}

.btn_dimension:hover {
  color: #9ee6ff;
  cursor: pointer;
}

.selected {
  color: #fff;
  font-weight: 700;
}

.line-decoration {
  width: 100%;
  height: 1px;
  background-color: #0b4d90;
}

/* 搜索结果 */
.catalog {
  background-color: #06346e;
  width: 75%;
}

</style>
