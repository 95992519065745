// 请求头常量
export const AUTHORIZATION = 'Authorization'

// 测试 token 字符串
export const TEST_TOKEN = 'testToken'

// 默认请求地址
// export const BASE_URL = 'http://localhost'
//底座项目修改
// export const BASE_URL = '/olap-api'
export const BASE_URL =
  process.env.NODE_ENV === 'development'
    ? process.env.VUE_APP_BASE_API
    : '/olap-api'

// tokenKey 常量
export const Token_Key = 'TOKEN'

// error msg
export const NORMAL_MSG = '请联系管理员'

export const axiosErrorCode = {
  ECONNABORTED: '请求超时',
  ERR_NETWORK: '网络错误',
  // ENOTFOUND: '请求的域名无法解析',
  // ECONNREFUSED: '请求被拒绝，可能是因为服务未启动或端口被占用',
  // ECONNRESET: '与服务器的连接被重置',
}

const test_token = sessionStorage.getItem('ACCESS_TOKEN')

// 永久token
//底座项目修改——获取主应用登录后存在 sessionStorage 里的token
export const TOKEN_CODE = 'Bearer ' + test_token
//'Bearer eyJhbGciOiJIUzI1NiJ9.eyJqdGkiOiJhZG1pbiIsImF1dGhvcml0eSI6WyJpbmRpY2F0b3JEYXRhOnJlYWQiLCJjb25uZWN0aW9uOnJlYWQiLCJob21lOnJlYWQiLCJyb2xlOnJlYWQiLCJkZXB0OndyaXRlIiwicm9sZTp3cml0ZSIsImNhdGVnb3J5OndyaXRlIiwiZGltZW5zaW9uczp3cml0ZSIsImluZGljYXRvckRhdGE6d3JpdGUiLCJkZXB0OnJlYWQiLCJkaW1lbnNpb25zOnJlYWQiLCJpbmRpY2F0b3I6d3JpdGUiLCJ1c2VyOnJlYWQiLCJ1c2VyOndyaXRlIiwiaW5kaWNhdG9yOnJlYWQiLCJjYXRlZ29yeTpyZWFkIl0sImV4cCI6NDgzODQ5NzI3NCwiaWF0IjoxNjg0ODk3Mjc0LCJzdWIiOiJhZG1pbiJ9.QWSO1PxdLFVYJrO38bK1u767zbgJHLt3kw06N-f4MEw'
