<template>
  <div style="padding: 0 15px; color:#fff;" @click="toggleClick">
    <svg :class="{ 'is-active': isActive }" class="hamburger" xmlns="http://www.w3.org/2000/svg"
      width="64" height="70">
      <path
          d="M 0.999 2.521  C 1 1.651  1.702 0.946  2.569 1  L 18.276 1  C 19.143 0.946  19.846 1.652  19.846 2.523  C 19.845 3.392  19.143 4.097  18.276 4  L 2.569 4  C 1.702 4.097  0.999 3.391  0.999 2.521  Z M 0.999 11.188  C 1 10.318  1.702 9.613  2.569 9.613  L 11.993 9.613  C 12.861 9.613  13.564 10.318  13.564 11.189  C 13.564 12.059  12.861 12.764  11.993 12.764  L 2.569 12.764  C 1.702 12.764  0.999 12.058  0.999 11.188  Z M 0.999 19.854  C 1 18.985  1.702 18.28  2.569 18  L 18.276 18  C 19.143 18.28  19.846 18.986  19.846 19.856  C 19.845 20.726  19.143 21.431  18.276 21  L 2.569 21  C 1.702 21.431  0.999 20.725  0.999 19.854  Z M 15.141 7.249  L 19.846 11.189  L 15.141 15.128  L 15.141 7.249  Z "
          fill-rule="evenodd" fill="#7ac4fd" stroke="none" />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'Hamburger',
  props: {
    isActive: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    toggleClick() {
      this.$emit('toggleClick')
    }
  }
}
</script>

<style scoped>
.hamburger {
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 22px;
}

.hamburger.is-active {
  transform: scaleX(-1);
}
</style>
