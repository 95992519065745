<template>
    <!-- 新增目录 -->
    <div>
        <el-button type="text" @click="handleClickBack">返回</el-button>
        <el-button v-if="isAdd" type="primary" @click="handleClickAdd(true)">保存并挂载数据</el-button>
        <el-button v-if="isAdd" type="primary" @click="handleClickAdd(false)">保存</el-button>
        <el-button v-if="!isAdd" type="primary" @click="handleClickEdit(true)">保存并挂载数据</el-button>
        <el-button v-if="!isAdd" type="primary" @click="handleClickEdit(false)">保存</el-button>
        <el-button @click="handleClickBack">取消</el-button>
        <div class="add_catalog">
            <el-form ref="form" :model="form" label-width="180px" :rules="rules">
                <div class="add_title">基础属性</div>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="部门分类" prop="selectDept">
                            <!-- <el-select v-model="form.deptId" placeholder="请选择">
                                <el-option v-for="item in departList" :key="parseInt(item.id)" :label="item.name"
                                    :value="parseInt(item.id)"></el-option>
                            </el-select> -->
                            <el-select ref="selectDept" v-model="selectDept" placeholder="请选择">
                                <el-option class="tree_options" value="" style="height: 100%; padding: 0">
                                    <el-tree :data="treeDept" :props="defaultProps" :expand-on-click-node="false"
                                        :highlight-current="true" @node-click="clickTreeDept" lazy :load="addLoadNode" />
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="目录名称" prop="name">
                            <el-input v-model="form.name" placeholder="请输入内容"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="关联基础" prop="selectBasic">
                            <el-select ref="selectBasic" v-model="selectBasic" placeholder="请选择" clearable
                                @clear="clearBasic">
                                <el-option class="tree_options" value="" style="height: 100%; padding: 0">
                                    <el-tree :data="treeBasic" :props="defaultProps" :expand-on-click-node="false"
                                        :highlight-current="true" @node-click="clickTreeBasic" lazy :load="addLoadNode" />
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="关联主题" prop="selectTopic">
                            <el-select ref="selectTopic" v-model="selectTopic" placeholder="请选择" clearable
                                @clear="clearTopic">
                                <el-option class="tree_options" value="" style="height: 100%; padding: 0">
                                    <el-tree :data="treeTopic" :props="defaultProps" :expand-on-click-node="false"
                                        :highlight-current="true" @node-click="clickTreeTopic" lazy :load="addLoadNode" />
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="统一社会信用代码" prop="socialCreditCode">
                            <el-input v-model="form.socialCreditCode" placeholder="请输入内容"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="目录简称" prop="simpleName">
                            <el-input v-model="form.simpleName" placeholder="请输入内容"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="资源描述" prop="resourceDescribe">
                            <el-input v-model="form.resourceDescribe" placeholder="请输入内容"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="资源格式" prop="resourceType">
                            <el-select v-model="form.resourceType" placeholder="请选择">
                                <el-option v-for="item in dict_resource_type" :label="item.label"
                                    :value="item.value"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="资源格式属性" prop="resourceTypeAttr">
                            <el-select v-model="form.resourceTypeAttr" placeholder="请选择" clearable>
                                <el-option v-for="item in dict_resource_type_attr" :label="item.label"
                                    :value="item.value"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="更新周期" prop="updateCycle">
                            <el-select v-model="form.updateCycle" placeholder="请选择">
                                <el-option v-for="item in dict_update_cycle" :key="item.value" :label="item.label"
                                    :value="item.value"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="关联资源目录名称" prop="relevanceResource">
                            <el-input v-model="form.relevanceResource" placeholder="请输入内容"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="职责事项" prop="dutyName">
                            <el-input v-model="form.dutyName" placeholder="请输入内容"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="所用信息系统" prop="systemId">
                            <el-select v-model="form.systemId" placeholder="请选择" clearable>
                                <el-option v-for="item in systemList" :key="parseInt(item.sysId)" :label="item.sysName"
                                    :value="parseInt(item.sysId)"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="是否有数据" prop="hasData">
                            <el-radio :label="1" v-model="form.hasData">是</el-radio>
                            <el-radio :label="0" v-model="form.hasData">否</el-radio>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="是否有资源" prop="hasResource">
                            <el-radio :label="1" v-model="form.hasResource">是</el-radio>
                            <el-radio :label="0" v-model="form.hasResource">否</el-radio>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="审核人" prop="auditorId">
                            <el-select v-model="form.auditorId" placeholder="请选择" disabled>
                                <el-option v-for="item in userList" :key="parseInt(item.id)" :label="item.nickname"
                                    :value="parseInt(item.id)"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-show="form.resourceType == 'FILE'">
                    <el-col :span="8">
                        <el-form-item label="文件格式" prop="fileFormat"
                            :rules="form.resourceType == 'FILE' ? rules.fileFormat : []">
                            <el-select v-model="form.fileFormat" placeholder="请选择">
                                <el-option v-for="item in dict_file_type_format" :label="item.label"
                                    :value="item.value"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>

                <div class="add_title">共享属性</div>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="共享方式" prop="shareWay">
                            <el-select v-model="form.shareWay" placeholder="请选择">
                                <el-option v-for="item in dict_share_way" :key="item.value" :label="item.label"
                                    :value="item.value"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="共享类型" prop="shareType">
                            <el-select v-model="form.shareType" placeholder="请选择">
                                <el-option v-for="item in dict_share_type" :key="item.value" :label="item.label"
                                    :value="item.value"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>

                <div class="add_title">开放属性</div>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="开放类型" prop="openType">
                            <el-select v-model="form.openType" placeholder="请选择">
                                <el-option v-for="item in dict_open_type" :key="item.value" :label="item.label"
                                    :value="item.value"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="开放条件" prop="openCondition">
                            <el-input v-model="form.openCondition" placeholder="请输入内容"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>

            <div class="add_title">数据项
                <el-button size="mini" icon="el-icon-circle-plus" type="text" @click="handleClickAddItem">新增</el-button>
            </div>
            <el-table :data="dataItemList" stripe border>
                <el-table-column align="center" width="58" label="序号" key="data_id" prop="data_id">
                    <template slot-scope="scope">{{ scope.$index + 1 }}</template>
                </el-table-column>
                <el-table-column align="center" width="120" label="数据项名称" key="itemName" prop="itemName" />
                <el-table-column align="center" width="120" label="数据项描述" key="itemDesc" prop="itemDesc" />
                <el-table-column align="center" width="auto" label="数据类型" key="dataType" prop="dataType" />
                <el-table-column align="center" width="auto" label="数据长度" key="dataLength" prop="dataLength" />
                <el-table-column align="center" width="auto" label="数据分级" key="dataGrade" prop="dataGrade"
                    :formatter="dataGradeFormat" />
                <el-table-column align="center" width="auto" label="共享类型" key="shareType" prop="shareType"
                    :formatter="shareTypeFormat" />
                <el-table-column align="center" width="auto" label="共享条件" key="shareCondition" prop="shareCondition"
                    :formatter="shareConditionFormat" />
                <el-table-column align="center" width="140" label="不予共享依据" key="notShareReason" prop="notShareReason" />
                <el-table-column align="center" width="auto" label="开放类型" key="openType" prop="openType"
                    :formatter="openTypeFormat" />
                <el-table-column align="center" width="auto" label="开放条件" key="openCondition" prop="openCondition" />
                <el-table-column align="center" width="150" label="是否为核数据项" key="isCore" prop="isCore"
                    :formatter="booleanFormat" />
                <el-table-column align="center" width="auto" label="是否非空" key="isEmpty" prop="isEmpty"
                    :formatter="booleanFormat" />
                <el-table-column align="center" width="auto" label="取值范围" key="valueRange" prop="valueRange" />
                <el-table-column align="center" min-width="100px" label="操作" class-name="small-padding fixed-width">
                    <template v-slot="scope">
                        <el-button size="mini" type="text" @click="handleItemEdit(scope.row)">编辑</el-button>
                        <el-button size="mini" type="text" @click="handleItemDelete(scope.row, scope.$index)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <DialogDataItem :item_dialog_show="item_dialog_show" @closeDialog="closeDialog" @addDataItem="addDataItem"
            ref="formDatadir" @editDataItem="editDataItem" />
    </div>
</template>

<script>
import dict from '../mixins/dict.js'
import DialogDataItem from './dialogDataItem.vue';
export default {
    name: "DatadirAdd",
    components: { DialogDataItem },
    mixins: [dict],
    props: [
        'userList',
        'auditorUser',
        'departList',
        'systemList',
        'treeOptions',
        'loadNode',
        'addDatadir',
        'editDatadir',
        'clickBack',
    ],
    watch: {
        selectDept: {
            immediate: true,
            handler(newValue, oldValue) {
                this.form.selectDept = newValue;  // 用于表单校验是否为空
            }
        }
    },
    data() {
        return {
            form: {},
            // 表单校验
            rules: {
                name: [{ required: true, message: "目录名称不能为空", trigger: "blur" }],
                selectDept: [{ required: true, message: "部门分类不能为空", trigger: "blur" }],
                shareWay: [{ required: true, message: "共享方式不能为空", trigger: "blur" }],
                openType: [{ required: true, message: "开放类型不能为空", trigger: "blur" }],
                shareType: [{ required: true, message: "共享类型不能为空", trigger: "blur" }],
                auditorId: [{ required: true, message: "审核人不能为空", trigger: "blur" }],
                fileFormat: [{ required: true, message: "文件格式不能为空", trigger: "blur" }],
                simpleName: [{ required: true, message: "目录简称不能为空", trigger: "blur" }],
                updateCycle: [{ required: true, message: "更新周期不能为空", trigger: "blur" }],
                resourceType: [{ required: true, message: "资源格式不能为空", trigger: "blur" }],
                resourceDescribe: [{ required: true, message: "资源简介不能为空", trigger: "blur" }],
            },
            item_dialog_show: false, // 数据项弹窗
            datadirTitle: "",
            dataItemList: [],
            isAdd: true, // true新增  false编辑
            selectDept: "",
            selectBasic: "",
            selectTopic: "",
            selectDeptId: [],
            selectBasicId: [],
            selectTopicId: [],
            // el-tree
            defaultProps: {
                children: "children",
                isLeaf: "leaf",
                label: "name"
            },
            treeAdd: [],
            treeDept: [],
            treeBasic: [],
            treeTopic: [],
        }
    },
    methods: {
        handleClickBack() { this.$emit('clickBack') },
        // 表单重置
        reset() {
            this.init_tree()
            this.isAdd = true
            this.resetForm("form");
            // empty
            this.form = {};
            this.dataItemList = []
            this.selectDept = "";
            this.selectBasic = "";
            this.selectTopic = "";
            this.selectDeptId = [];
            this.selectBasicId = [];
            this.selectTopicId = [];
            this.form.auditorId = this.auditorUser.id  // 新增时，审核人为部门管理员
        },
        edit(row) {
            this.init_tree()
            this.isAdd = false
            // value
            this.form = JSON.parse(JSON.stringify(row))
            this.dataItemList = this.form.dataItems
            this.dataItemList.forEach(i => i.data_id = i.id) // 用于编辑、删除
            this.selectDept = row.label_dept;
            this.selectBasic = row.label_basic;
            this.selectTopic = row.label_topic;
            this.selectDeptId = row.value_dept ? [row.value_dept] : [];
            this.selectBasicId = row.value_basic ? [row.value_basic] : [];
            this.selectTopicId = row.value_topic ? [row.value_topic] : [];
            this.form.selectDept = row.label_dept // 用于表单校验是否为空
        },
        // 数据项---------------------------------------------
        closeDialog() {
            this.item_dialog_show = false
        },
        handleClickAddItem() {
            this.item_dialog_show = true
            this.datadirTitle = "新增数据项信息"
            this.$refs.formDatadir.reset()
        },
        addDataItem(obj) {
            this.dataItemList.push(obj)
            this.item_dialog_show = false
        },
        handleItemEdit(row) {
            this.item_dialog_show = true
            this.datadirTitle = "编辑数据项信息"
            this.$refs.formDatadir.edit(row)
        },
        editDataItem(obj) {
            this.dataItemList = this.dataItemList.map((item) => item.data_id === obj.data_id ? obj : item)
            this.item_dialog_show = false
        },
        handleItemDelete(row, index) {
            // let index = this.dataItemList.indexOf(row)
            if (index !== -1) {
                this.dataItemList.splice(index, 1)
            }
        },
        handleClickAdd(toBind) {
            this.$refs['form'].validate(valid => {
                if (!valid) return
                let dataItems = this.dataItemList.length !== 0 ? this.dataItemList : []
                let catalogs = []
                catalogs = catalogs.concat(this.selectDeptId, this.selectBasicId, this.selectTopicId)
                let obj = Object.assign({}, this.form, { catalogs, dataItems })

                if (toBind && dataItems.length == 0) return this.$message.error('请先绑定数据项，再挂载数据')
                this.$emit('addDatadir', obj, toBind)
            })
        },
        handleClickEdit(toBind) {
            this.$refs['form'].validate(valid => {
                if (!valid) return
                let dataItems = this.dataItemList.length !== 0 ? this.dataItemList : []
                let catalogs = []
                catalogs = catalogs.concat(this.selectDeptId, this.selectBasicId, this.selectTopicId)
                let obj = Object.assign({}, this.form, { catalogs, dataItems })

                if (toBind && dataItems.length == 0) return this.$message.error('请先绑定数据项，再挂载数据')
                this.$emit('editDatadir', obj, toBind)
            })
        },
        // 树状下拉框----------------------------------------
        init_tree() {
            if (this.treeAdd.length == 0) {
                this.$nextTick(() => {
                    this.treeAdd = JSON.parse(JSON.stringify(this.treeOptions))
                    this.treeAdd.forEach(item => {
                        if (/部门/.test(item.catalogName)) return this.treeDept.push(item)
                        if (/基础/.test(item.catalogName)) return this.treeBasic.push(item)
                        if (/主题/.test(item.catalogName)) return this.treeTopic.push(item)
                    })
                })
            }
        },
        addLoadNode(node, resolve) {
            this.$emit('loadNode', node, resolve)
        },
        clickTreeDept(node) {
            this.selectDept = node.name;
            this.selectDeptId = [node.id];
            this.$refs.selectDept.blur();
        },
        clickTreeBasic(node) {
            this.selectBasic = node.name;
            this.selectBasicId = [node.id];
            this.$refs.selectBasic.blur();
        },
        clickTreeTopic(node) {
            this.selectTopic = node.name;
            this.selectTopicId = [node.id];
            this.$refs.selectTopic.blur();
        },
        // 基础、主题可以为空
        clearBasic() {
            this.selectBasicId = []
        },
        clearTopic() {
            this.selectTopicId = []
        },
    }
}
</script>

<style lang="less" scoped>
// 添加目录
.add_catalog {
    background-color: #0f347e;
    width: 1880px;
    height: 919px;
    border: 1px solid #067cdb;
    border-radius: 5px;
    padding: 20px;
}

.add_title {
    height: 40px;
}

/deep/.el-button--default:hover {
    border: solid 1px #007cd5;
    background-color: #1f499b;
    color: #7ac4fd;
}

// /deep/.el-textarea__inner,
// /deep/.el-input--prefix .el-input__inner {
//   border: #1B6CAC 1px solid;
//   border-radius: 3px;
//   background-color: #092864;
//   color: #fff;
// }

// /deep/.el-textarea .el-input__count {
//   background-color: #092864;
// }
</style>