import request from '@/utils/request'
import { praseStrEmpty } from "@/utils/ruoyi";
const adminurl = '/admin-api'

// 新增关联用户
export function listDatadir(query) {
  return request({
    url: adminurl + '/rdm/resDir/page',
    method: 'get',
    params: query
  })
}

// 新增目录
export function addDatadirRequest(data) {
  return request({
    url: adminurl + '/rdm/resDir',
    method: 'post',
    data: data
  })
}

// 编辑目录
export function editDatadirRequest(data) {
  return request({
    url: adminurl + '/rdm/resDir', // + praseStrEmpty(data.id)
    method: 'put',
    data: data
  })
}

// 清除目录-真删除-未使用
// export function hardDeleteDatadirRequest(data) {
//   return request({
//     url: adminurl + '/rdm/resDir/' + data.toString(),
//     method: 'delete',
//   })
// }

// 删除目录-假删除
export function deleteDatadirRequest(data) {
  return request({
    url: adminurl + '/rdm/resDir/' + data.toString() + '/delete',
    method: 'put',
  })
}

// 恢复假删除的目录
export function restoreDatadirRequest(data) {
  return request({
    url: adminurl + '/rdm/resDir/' + data.toString() + '/restore',
    method: 'put',
  })
}

// 上线目录
export function uplineDatadirRequest(data) {
  return request({
    url: adminurl + '/rdm/resDir/upline/' + data.toString(),
    method: 'put',
  })
}

// 下线目录
export function offlineDatadirRequest(data) {
  return request({
    url: adminurl + '/rdm/resDir/offline/' + data.toString(),
    method: 'put',
  })
}

// 目录详情
export function detailDatadirRequest(id) {
  return request({
    url: adminurl + '/rdm/resDir/' + praseStrEmpty(id),
    method: 'get',
  })
}