<template>
    <!-- 编目管理 -->
    <el-dialog :title="dialogCatalogTitle" :visible="catalog_dialog_open" width="800px" append-to-body @close="close">
        <el-form ref="form" :model="form" label-width="200px" :rules="rules">
            <el-row>
                <el-col :span="24">
                    <el-form-item label="编目名称" prop="catalogName">
                        <el-input v-model="form.catalogName" placeholder="请输入内容"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="24">
                    <el-form-item label="编目编码" prop="catalogCode">
                        <el-input v-model="form.catalogCode" placeholder="请输入内容"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="24">
                    <el-form-item label="统一社会信用代码" prop="creditCode">
                        <el-input v-model="form.creditCode" placeholder="请输入内容"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <!-- <el-row>
                <el-col :span="24">
                    <el-form-item label="编目图标" prop="applyReason">
                        <el-input v-model="form.applyReason" placeholder="请输入内容"></el-input>
                    </el-form-item>
                </el-col>
            </el-row> -->
            <el-row>
                <el-col :span="24">
                    <el-form-item label="所属父类" prop="catalogPid">
                        <!-- 方法2 懒加载结点 -->
                        <el-select ref="selectTree" v-model="selectValue" placeholder="请选择" :disabled="tree_select_disable">
                            <el-option class="tree_options" value="" style="height: 100%; padding: 0">
                                <el-tree :data="dialogTree" :props="defaultProps" :expand-on-click-node="false"
                                    :highlight-current="true" @node-click="clickTreeSelect" lazy :load="dialogloadNode" />
                            </el-option>
                        </el-select>

                        <!-- 方法1 -->
                        <!-- <el-select ref="selectTree" v-model="selectValue" placeholder="请选择" :disabled="tree_select_disable">
                            <el-option v-for="item in optionData(catalogList)" :label="item.label" :value="item.value"
                                style="display: none;">
                            </el-option>
                            <el-tree :data="catalogList" :props="defaultProps" :expand-on-click-node="false"
                                ref="selectlTree" default-expand-all :highlight-current="true"
                                @node-click="clickTreeSelect" />
                        </el-select> -->
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="24">
                    <el-form-item label="负责人" prop="ownerId">
                        <el-select v-model="form.ownerId" placeholder="请选择" clearable>
                            <el-option v-for="item in userList" :key="parseInt(item.id)" :label="item.nickname"
                                :value="parseInt(item.id)"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="24">
                    <el-form-item label="编目描述" prop="catalogDescription">
                        <el-input v-model="form.catalogDescription" type="textarea" placeholder="请输入内容" maxlength="100"
                            show-word-limit></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button v-if='isAdd' type="primary" @click="saveAdd">确 定</el-button>
            <el-button v-if='!isAdd' type="primary" @click="saveEdit">确 定</el-button>
            <el-button type="info" @click="close">取消</el-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
    name: "dialogCatalog",
    props: ['catalog_dialog_open', 'userList', 'catalogList', 'dialogCatalogTitle', 'treeOptions', 'loadNode'],
    data() {
        return {
            isAdd: true, // true新增  false编辑
            // 树下拉框
            tree_select_disable: false,
            selectValue: "",
            // 表单参数
            form: {},
            // 表单校验
            rules: {
                catalogName: [{ required: true, message: "编目名称不能为空", trigger: "blur" }],
                catalogCode: [{ required: true, message: "编目编码不能为空", trigger: "blur" }],
                catalogPid: [{ required: true, message: "所属编目不能为空", trigger: "blur" }],
            },
            // el-tree
            defaultProps: {
                children: "children",
                isLeaf: "leaf",
                label: "name"
            },
            dialogTree: [],
        }
    },
    methods: {
        // 表单重置
        reset(parent) {
            this.init_tree()
            this.isAdd = true
            this.resetForm("form");
            this.form = {
                catalogName: undefined,
                catalogCode: undefined,
                creditCode: undefined,
                catalogPid: undefined,
                ownerId: undefined,
                catalogDescription: undefined,
            };
            this.selectValue = ""
            if (parent) {
                this.selectValue = parent.catalogName
                this.form.catalogPid = parent.id
                this.tree_select_disable = true
            } else {
                this.tree_select_disable = false
            }
        },
        edit(catalog) {
            this.init_tree()
            this.isAdd = false
            this.tree_select_disable = false
            this.form = {
                id: catalog.catalogId,
                catalogName: catalog.catalogName,
                catalogCode: catalog.catalogCode,
                creditCode: catalog.creditCode,
                catalogPid: catalog.catalogPid,
                ownerId: catalog.ownerId,
                catalogDescription: catalog.catalogDescription,
            };
            // TODO 懒加载回显问题，待优化
            // console.log('edit catalog list ', this.catalogList);
            let parentNode = this.catalogList.find(item => item.catalogId == catalog.catalogPid)
            this.selectValue = parentNode ? parentNode.catalogName : ""
        },
        // 初始化el-tree
        init_tree(id) {
            if (this.dialogTree.length == 0) {
                this.$nextTick(() => {
                    this.dialogTree = JSON.parse(JSON.stringify(this.treeOptions))
                })
            }

        },
        saveAdd() {
            this.$refs['form'].validate(valid => {
                if (!valid) return
                this.$emit('addCatalog', this.form)
            })
        },
        saveEdit() {
            this.$refs['form'].validate(valid => {
                if (!valid) return
                this.$emit('editCatalog', this.form)
            })
        },
        close() {
            this.$emit('closeDialog')
        },
        dialogloadNode(node, resolve) {
            this.$emit('loadNode', node, resolve)
        },
        optionData(array, result = []) {
            if (!array) return null
            array.forEach(item => {
                result.push({ label: item.name, value: item.id })
                if (item.children && item.children.length !== 0) {
                    this.optionData(item.children, result)
                }
            })
            return JSON.parse(JSON.stringify(result))
        },
        clickTreeSelect(node) {
            this.form.catalogPid = node.id;
            this.selectValue = node.name;
            this.$refs.selectTree.blur();
        }
    }
}
</script>

<style lang="less" scoped>
.btn_add {
    text-align: right;
    padding: 0 0 10px 0;
    color: #82ceff;
    cursor: pointer;
}

/deep/ .el-button--info,
.el-button--info:focus,
.el-button--info:hover {
    background: #1f499b;
    border: 1px solid #045b9f;
}

/deep/.el-textarea__inner,
/deep/.el-input--prefix .el-input__inner {
    border: #1B6CAC 1px solid;
    border-radius: 3px;
    background-color: #092864;
    color: #fff;
}

/deep/.el-textarea .el-input__count {
    background-color: #092864;
}

.tree_options:hover {
    background: none;
}

/deep/ .el-select-dropdown__item.selected {
    color: transparent;
}
</style>