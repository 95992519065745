import request from '@/utils/request'
const adminurl = '/admin-api'

// 获取编目维度-树状结构
export function childCatalog(query) {
  return request({
    url: adminurl + '/rdm/catalog/' + query.id + '/child',
    method: 'get',
    params: { allDescendants: query.is_all }
  })
}

// 获取编目维度-列表 1110废弃？
// export function listSimpleDepts() {
//   return request({
//     url: adminurl + '/rdm/catalog/list',
//     method: 'get'
//   })
// }

// 获取编目维度-根节点
export function rootCatalog() {
  return request({
    url: adminurl + '/rdm/catalog/root',
    method: 'get'
  })
}

// 获取用户相关部门信息
export function userDepts() {
  return request({
    url: adminurl + '/system/dept/list',
    method: 'get'
  })
}

// 查询用户列表
export function listUser(query) {
  return request({
    url: adminurl + '/system/user/page',
    method: 'get',
    params: query
  })
}

// 查询当前用户的部门管理员（审核）
export function listAuditor(query) {
  return request({
    url: adminurl + '/rdm/resDir/auditor',
    method: 'get',
    params: query
  })
}

// 获取信息系统
export function Listsystem() {
  return request({
    url: adminurl + '/rdm/resSystem/list',
    method: 'get'
  })
}