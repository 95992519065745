import axios from 'axios'
// import store from '@/store'
import {
  AUTHORIZATION,
  NORMAL_MSG,
  TOKEN_CODE,
  axiosErrorCode,
} from '@/const/request.js'
import {Message, MessageBox} from 'element-ui'
import {showScreenLoading, hideScreenLoading} from '@/utils/loading'
// import {loginUrl} from './constants'
const token = TOKEN_CODE //store?.getters?.getToken || TOKEN_CODE
const domain = process.env.VUE_APP_BASE_API
const {host} = window.location
// 是否华为云环境
const isHuawei = process.env.VUE_APP_HUAWEI === 'true'
const loginUrl = process.env.VUE_APP_LOGIN_URL
// 移动环境后端服务 api
// const customURL = domain || `http://${host}`
//底座项目修改
const customURL =
  process.env.NODE_ENV === 'development'
    ? process.env.VUE_APP_BASE_API
    : '/olap-api'
//打包
// const customURL =  `/olap-api`

// 华为云后端服务 api
const huaweiURL = domain || `http://${host}/platform/indicator-api/`

const baseURL = isHuawei ? huaweiURL : customURL

const instance = axios.create({
  baseURL,
  timeout: 60 * 1000, // 默认60s
})

const uerList = [
  '/connection/table',
  '/home',
  'indicator/complex',
  '/indicator/atom',
  '/indicator/derive',
  // '/indicator-data',
  '/dimensions/item',
  '/user/listUser',
  '/dept',
  '/role/page',
  '/login',
  '/indicator/atom/data',
]
// 请求头拦截
instance.interceptors.request.use(
  (cfg) => {
    if (uerList.includes(cfg.url)) {
      showScreenLoading(cfg.headers)
    }

    // cfg.headers[AUTHORIZATION] = token
    cfg.headers[AUTHORIZATION] =
      'Bearer ' + sessionStorage.getItem('ACCESS_TOKEN')
    return cfg
  },
  (err) => {
    Message.error(err)
  }
)

// // 响应拦截
instance.interceptors.response.use(
  (res) => {
    if (res.config.headers.showLoading !== false) {
      hideScreenLoading()
    }
    const {data} = res || {}
    const {status, message, msg, code} = data
    const isBlob = data instanceof Blob
    // 是否数据流
    if (typeof data === 'string' || isBlob) {
      return data
    }
    if (code === 401) {
      logMessage()
      return false
    }
    // 请求失败
    if (+status !== 200 && code !== '200') {
      return Promise.reject({message: message || msg})
    }

    return res
  },
  (err) => {
    if (err.config.headers.showLoading !== false) {
      hideScreenLoading()
    }

    err.message = axiosErrorCode[err.code] || NORMAL_MSG
    return Promise.reject(err)
  }
)
function logMessage() {
  MessageBox.confirm('登录状态已过期，请重新登录', '系统提示', {
    confirmButtonText: '重新登录',
    showCancelButton: false,
    showClose: false,
    closeOnClickModal: false,
    closeOnPressEscape: false,
    type: 'warning',
  }).then(() => {
    // window.location.href = window.location.origin + '/login'
    // const currentHref = window.location.href
    // window.location.href = `${loginUrl}?redirect=${currentHref}`
    sessionStorage.clear()
  })
}
export default instance
