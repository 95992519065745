import request from '@/utils/request'
import {praseStrEmpty} from '@/utils/ruoyi'
const adminurl = '/admin-api/rdm/'

/**
 * 查看详情
 *
 */
export function getDetail(data) {
  return request({
    url: adminurl + 'datasource',
    method: 'get',
    params: data,
  })
}

/**
 * 修改数据源
 *
 */
export function update(data) {
  return request({
    url: adminurl + 'datasource',
    method: 'put',
    data,
  })
}

/**
 * 新增数据源
 *
 */
export function addData(data) {
  return request({
    url: adminurl + 'datasource/add',
    method: 'post',
    data,
  })
}

/**
 * 查询当前用户结构的数据源列表
 *
 */
export function getList(data) {
  return request({
    url: adminurl + 'datasource/list',
    method: 'get',
    params: data,
  })
}

/**
 * 删除数据源
 *
 */
export function deleteData(data) {
  return request({
    url: adminurl + 'datasource/delete',
    method: 'delete',
    params: data,
  })
}

/**
 * 查询当前用户结构的数据源列表
 *
 */
export function test(data) {
  return request({
    url: adminurl + 'datasource/test',
    method: 'get',
    params: data,
  })
}

/**
 * 查询数据源字典
 *
 */
export function queryDatasource() {
  return request({
    url: adminurl + 'datasource/type',
    method: 'get',
    // params: data,
  })
}

/**
 * 根据数据源查询要展示的字段
 *
 */
export function linkparam(data) {
  return request({
    url: adminurl + 'datasource/linkparam',
    method: 'get',
    params: data,
  })
}

/**
 * 根据数据源查询要展示的字段
 *
 */
export function serverList() {
  return request({
    url: adminurl + 'exch-server/list',
    method: 'get',
    // params: data,
  })
}
