<template>
    <!-- 前置服务器拓扑结构 -->
    <div class="container">
        <div class="center">
            <div class="tag">交换中心</div>
        </div>

        <NodeServer v-for="(item, index) in serverAll" :server="item" :index="index" />
        <div class="all_servers">
            <div class="dept">
                <p class="total">部门： {{ countDept }}</p>
            </div>
            <div class="file">
                <p class="total">文件传输： {{ countFile }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import dict from '../mixins/dict.js'
import NodeServer from './nodeServer.vue';
export default {
    name: "PrintServer",
    components: { NodeServer },
    mixins: [dict],
    props: [
        'serverAll',
    ],
    created() {
        console.log('all', this.serverAll);
    },
    data() {
        return {
            countDept: 0,
            countFile: 0,
        }
    },
    watch: {
        serverAll: {
            deep: false,
            immediate: true,
            handler(val) {
                // 归零
                this.countDept = 0
                this.countFile = 0
                // 重新计算
                this.serverAll.forEach(item => {
                    if (item.serverType != '3') this.countDept++
                })
                this.countFile = this.serverAll.length - this.countDept
            }
        }
    },
    methods: {
    }
}
</script>

<style lang="less" scoped>
.container {
    position: relative;
    width: 1850px;
    height: 600px;
}

.tag {
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 0);
    width: 130px;
    height: 30px;
    background-color: rgba(242, 242, 242, 1);
    border: none;
    border-radius: 8px;
    color: #0C72BD;
    text-align: center;
    line-height: 30px;
}

.center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 204px;
    height: 221px;
    background: url('../../assets/images/server/center.png');
    cursor: pointer;
}

.all_servers {
    // background-color: aquamarine;
    width: 400px;
    height: 150px;
    position: absolute;
    bottom: -20px;
    left: 0;

    .dept {
        position: absolute;
        top: 20px;
        left: 20px;
        width: 35px;
        height: 45px;
        background-image: url('../../assets/images/server/department.png');
        background-size: contain;
    }

    .file {
        position: absolute;
        top: 100px;
        left: 20px;
        width: 35px;
        height: 33px;
        background-image: url('../../assets/images/server/file.png');
        background-size: contain;
    }

    .total {
        position: absolute;
        bottom: 5px;
        left: 50px;
        width: 160px;
    }
}
</style>