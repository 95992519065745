import request from '@/utils/request'
import { praseStrEmpty } from "@/utils/ruoyi";
const adminurl = '/admin-api'


// 订阅管理-订阅列表
export function pageSubscribe(query) {
  return request({
    url: adminurl + '/exch/subscribe/page',
    method: 'get',
    params: query
  })
}

// 订阅管理-详情-数据项
export function dataItemSubscribe(query) {
  return request({
    url: adminurl + '/exch/subscribe/dataItemPage',
    method: 'get',
    params: query
  })
}