import { render, staticRenderFns } from "./datadirBindAPI.vue?vue&type=template&id=0c0e7314&scoped=true&"
import script from "./datadirBindAPI.vue?vue&type=script&lang=js&"
export * from "./datadirBindAPI.vue?vue&type=script&lang=js&"
import style0 from "./datadirBindAPI.vue?vue&type=style&index=0&id=0c0e7314&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c0e7314",
  null
  
)

export default component.exports