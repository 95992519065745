<template>
    <!-- 挂载数据-api -->
    <div>
        <el-button type="text" @click="handleClickBack">返回</el-button>
        <el-button type="primary" @click="handleClickBind">保存</el-button>
        <div class="bind_catalog">
            <el-form ref="form" :model="form" label-width="100px" :rules="rules">
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="目录名称" prop="name">{{ datadir.name }}</el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="接口URL" prop="protocol">
                            <el-select v-model="form.protocol" placeholder="请选择" style="width: 12%">
                                <el-option v-for="item in dict_bind_api_protocol" :key="item.value" :label="item.label"
                                    :value="item.value"></el-option>
                            </el-select>
                            <el-input v-model="form.path" placeholder="请输入内容" style="width: 78%; margin: 0 8px;"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="3">
                        <el-form-item label="请求方式" prop="method">
                            <el-select v-model="form.method" placeholder="请选择">
                                <el-option v-for="item in dict_bind_api_method" :key="item.value" :label="item.label"
                                    :value="item.value"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>

                <div class="bind_title">入参配置</div>
                <el-row>
                    <el-col :span="4">
                        <el-radio-group v-model="queryTab" class="querybox">
                            <el-radio-button v-for="item in queryTabs" v-if="item.label != 'body' || form.method != 'GET'"
                                :label="parseInt(item.value)">
                                {{ item.label }}
                            </el-radio-button>
                        </el-radio-group>
                    </el-col>
                    <el-col :span="8" v-show="queryTab == 2">
                        <div class="radio_body">
                            <el-radio v-model='form.bodyFormat' :label="0">form-data</el-radio>
                            <el-radio v-model='form.bodyFormat' :label="1">x-www-form-urlencoded</el-radio>
                            <el-radio v-model='form.bodyFormat' :label="2">JSON</el-radio>
                        </div>
                    </el-col>
                </el-row>
            </el-form>
            <el-table v-show="queryTab == 1" :data="query" stripe border class="querybox" :key="queryTableKey">
                <el-table-column align="center" width="58" label="序号" key="data_id" prop="data_id">
                    <template slot-scope="scope">{{ scope.$index + 1 }}</template>
                </el-table-column>
                <el-table-column align="center" label="参数名称" key="name" prop="name">
                    <template slot-scope="scope">
                        <el-input v-if="scope.row.isEdit" v-model="scope.row.name" placeholder="请输入内容"></el-input>
                        <span v-else>{{ scope.row.name }}</span>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="数据类型" key="dataType" prop="dataType">
                    <template slot-scope="scope">
                        <el-input v-if="scope.row.isEdit" v-model="scope.row.dataType" placeholder="请输入内容"></el-input>
                        <span v-else>{{ scope.row.dataType }}</span>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="是否必传" key="required" prop="required">
                    <template slot-scope="scope">
                        <el-switch v-if="scope.row.isEdit" v-model="scope.row.required" active-color="#00a0ff"
                            inactive-color="#ccc">
                        </el-switch>
                        <span v-else>{{ booleanFormat(undefined, undefined, scope.row.required) }}</span>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="参数说明" key="desc" prop="string">
                    <template slot-scope="scope">
                        <el-input v-if="scope.row.isEdit" v-model="scope.row.desc" placeholder="请输入内容"></el-input>
                        <span v-else>{{ scope.row.desc }}</span>
                    </template>
                </el-table-column>
                <el-table-column align="center" min-width="100px" label="操作" class-name="small-padding fixed-width">
                    <template v-slot="scope">
                        <el-button size="mini" type="text" v-if="!scope.row.isEdit"
                            @click="editRowReq(scope.row)">编辑</el-button>
                        <el-button size="mini" type="text" v-if="!scope.row.isEdit"
                            @click="deleteRowReq(scope.row, scope.$index)">删除</el-button>
                        <el-button size="mini" type="text" v-if="scope.row.isEdit"
                            @click="saveRowReq(scope.row)">保存</el-button>
                        <el-button size="mini" type="text" v-if="scope.row.isEdit"
                            @click="cancelRowReq(scope.row, scope.$index)">取消</el-button>
                    </template>
                </el-table-column>
            </el-table>

            <el-input v-show="form.bodyFormat == 2 && queryTab == 2" v-model="bodyJSON" type="textarea"
                :autosize="{ minRows: 3 }" placeholder="请输入内容" @input="orderNoChange"></el-input>
            <el-table v-show="form.bodyFormat != 2 && queryTab == 2" :data="body" stripe border class="querybox"
                :key="bodyTableKey">
                <el-table-column align="center" width="58" label="序号" key="data_id" prop="data_id">
                    <template slot-scope="scope">{{ scope.$index + 1 }}</template>
                </el-table-column>
                <el-table-column align="center" label="Key" key="key" prop="key">
                    <template slot-scope="scope">
                        <el-input v-if="scope.row.isEdit" v-model="scope.row.key" placeholder="请输入内容"></el-input>
                        <span v-else>{{ scope.row.key }}</span>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="value" key="value" prop="value">
                    <template slot-scope="scope">
                        <el-input v-if="scope.row.isEdit" v-model="scope.row.value" placeholder="请输入内容"></el-input>
                        <span v-else>{{ scope.row.value }}</span>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="Description" key="desctiption" prop="desctiption">
                    <template slot-scope="scope">
                        <el-input v-if="scope.row.isEdit" v-model="scope.row.desctiption" placeholder="请输入内容"></el-input>
                        <span v-else>{{ scope.row.desctiption }}</span>
                    </template>
                </el-table-column>
                <el-table-column align="center" min-width="100px" label="操作" class-name="small-padding fixed-width">
                    <template v-slot="scope">
                        <el-button size="mini" type="text" v-if="!scope.row.isEdit"
                            @click="editRowReq(scope.row)">编辑</el-button>
                        <el-button size="mini" type="text" v-if="!scope.row.isEdit"
                            @click="deleteRowReq(scope.row, scope.$index)">删除</el-button>
                        <el-button size="mini" type="text" v-if="scope.row.isEdit"
                            @click="saveRowReq(scope.row)">保存</el-button>
                        <el-button size="mini" type="text" v-if="scope.row.isEdit"
                            @click="cancelRowReq(scope.row, scope.$index)">取消</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-table v-show="queryTab == 3" :data="headers" stripe border class="querybox" :key="headerTableKey">
                <el-table-column align="center" width="58" label="序号" key="data_id" prop="data_id">
                    <template slot-scope="scope">{{ scope.$index + 1 }}</template>
                </el-table-column>
                <el-table-column align="center" label="参数名称" key="name" prop="name">
                    <template slot-scope="scope">
                        <el-input v-if="scope.row.isEdit" v-model="scope.row.name" placeholder="请输入内容"></el-input>
                        <span v-else>{{ scope.row.name }}</span>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="数据类型" key="dataType" prop="dataType">
                    <template slot-scope="scope">
                        <el-input v-if="scope.row.isEdit" v-model="scope.row.dataType" placeholder="请输入内容"></el-input>
                        <span v-else>{{ scope.row.dataType }}</span>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="是否必传" key="required" prop="required">
                    <template slot-scope="scope">
                        <el-switch v-if="scope.row.isEdit" v-model="scope.row.required" active-color="#00a0ff"
                            inactive-color="#ccc">
                        </el-switch>
                        <span v-else>{{ booleanFormat(undefined, undefined, scope.row.required) }}</span>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="参数说明" key="desc" prop="string">
                    <template slot-scope="scope">
                        <el-input v-if="scope.row.isEdit" v-model="scope.row.desc" placeholder="请输入内容"></el-input>
                        <span v-else>{{ scope.row.desc }}</span>
                    </template>
                </el-table-column>
                <el-table-column align="center" min-width="100px" label="操作" class-name="small-padding fixed-width">
                    <template v-slot="scope">
                        <el-button size="mini" type="text" v-if="!scope.row.isEdit"
                            @click="editRowReq(scope.row)">编辑</el-button>
                        <el-button size="mini" type="text" v-if="!scope.row.isEdit"
                            @click="deleteRowReq(scope.row, scope.$index)">删除</el-button>
                        <el-button size="mini" type="text" v-if="scope.row.isEdit"
                            @click="saveRowReq(scope.row)">保存</el-button>
                        <el-button size="mini" type="text" v-if="scope.row.isEdit"
                            @click="cancelRowReq(scope.row, scope.$index)">取消</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-button v-show="queryTab != 2 || form.bodyFormat != 2" size="mini" icon="el-icon-circle-plus" type="text"
                @click="addRowReq">新增</el-button>

            <div class="bind_title">出参配置</div>
            <el-table :data="respFields" stripe border :key="responseTableKey">
                <el-table-column align="center" width="58" label="序号" key="data_id" prop="data_id">
                    <template slot-scope="scope">{{ scope.$index + 1 }}</template>
                </el-table-column>
                <el-table-column align="center" label="参数名称" key="name" prop="name">
                    <template slot-scope="scope">
                        <el-input v-if="scope.row.isEdit" v-model="scope.row.name" placeholder="请输入内容"></el-input>
                        <span v-else>{{ scope.row.name }}</span>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="数据类型" key="dataType" prop="dataType">
                    <template slot-scope="scope">
                        <el-input v-if="scope.row.isEdit" v-model="scope.row.dataType" placeholder="请输入内容"></el-input>
                        <span v-else>{{ scope.row.dataType }}</span>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="是否必传" key="required" prop="required">
                    <template slot-scope="scope">
                        <el-switch v-if="scope.row.isEdit" v-model="scope.row.required" active-color="#00a0ff"
                            inactive-color="#ccc">
                        </el-switch>
                        <span v-else>{{ booleanFormat(undefined, undefined, scope.row.required) }}</span>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="参数说明" key="desc" prop="string">
                    <template slot-scope="scope">
                        <el-input v-if="scope.row.isEdit" v-model="scope.row.desc" placeholder="请输入内容"></el-input>
                        <span v-else>{{ scope.row.desc }}</span>
                    </template>
                </el-table-column>
                <el-table-column align="center" min-width="100px" label="操作" class-name="small-padding fixed-width">
                    <template v-slot="scope">
                        <el-button size="mini" type="text" v-if="!scope.row.isEdit"
                            @click="editRowRes(scope.row)">编辑</el-button>
                        <el-button size="mini" type="text" v-if="!scope.row.isEdit"
                            @click="deleteRowRes(scope.row, scope.$index)">删除</el-button>
                        <el-button size="mini" type="text" v-if="scope.row.isEdit"
                            @click="saveRowRes(scope.row)">保存</el-button>
                        <el-button size="mini" type="text" v-if="scope.row.isEdit"
                            @click="cancelRowRes(scope.row, scope.$index)">取消</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-button size="mini" icon="el-icon-circle-plus" type="text" @click="addRowRes">新增</el-button>
        </div>
    </div>
</template>

<script>
import { bindApi } from "@/api/system/bindsource";
import dict from '../mixins/dict.js'
export default {
    name: "DatadirBindAPI",
    mixins: [dict],
    props: [
        'clickBack',
        // 'datadir',
    ],
    data() {
        return {
            query: [],
            body: [],
            headers: [],
            respFields: [],
            // 以下key可能不必须
            queryTableKey: 1,
            bodyTableKey: 2,
            headerTableKey: 3,
            responseTableKey: 4,
            form: {},
            bodyJSON: '',
            // 表单校验
            rules: {
                protocol: [{ required: true, message: "接口URL不能为空", trigger: "blur" }],
                method: [{ required: true, message: "请求方式不能为空", trigger: "blur" }],
            },
            queryTab: 1,
            queryTabs: [
                { value: 1, label: 'query' },
                { value: 2, label: 'body' },
                { value: 3, label: 'header' },
            ],
            datadir: {},
        }
    },
    methods: {
        handleClickBack() { this.$emit('clickBack') },
        // 表单重置
        reset(obj) {
            this.datadir = obj ? obj : {}
            let apiVoList = this.datadir.apiVoList || []

            this.form = apiVoList.length > 0 ? JSON.parse(JSON.stringify(apiVoList[0])) : {}
            this.form.bodyFormat = this.formatBodyFormat(this.form.bodyFormat)

            this.query = this.form.query ? this.form.query : []
            this.headers = this.form.headers ? this.form.headers : []
            this.respFields = this.form.respFields ? this.form.respFields : []

            if (this.form.bodyFormat == 2) {
                this.bodyJSON = this.form.body
                this.body = []
            } else {
                this.body = this.form.body ? JSON.parse(this.form.body) : []
                this.bodyJSON = ''
            }
            this.queryTab = 1
        },
        handleClickBind() {
            this.$refs['form'].validate(valid => {
                if (!valid) return
                let respFields = this.clearOldRow(this.respFields)
                let query = this.clearOldRow(this.query)
                let headers = this.clearOldRow(this.headers)
                let body = this.clearOldRow(this.body)
                body = this.form.bodyFormat == 2 ? this.bodyJSON : JSON.stringify(body)
                let obj = {
                    dirId: this.datadir.id,
                    method: this.form.method,
                    path: this.form.path,
                    protocol: this.form.protocol,
                    bodyFormat: this.form.bodyFormat,

                    query: query.length == 0 ? undefined : query,
                    headers: headers.length == 0 ? undefined : headers,
                    respFields: respFields.length == 0 ? undefined : respFields,
                    body
                }
                bindApi(Object.assign(obj)).then(res => {
                    this.$message.success('挂载成功')
                    this.$emit('clickBack')
                }, err => {
                    this.$message.error(err)
                });
            })
        },
        clearOldRow(list) {
            let new_list = JSON.parse(JSON.stringify(list))
            new_list.forEach(item => {
                item.oldRow = undefined
                item.isAdd = undefined
                item.isEdit = undefined
            });
            return new_list

        },
        // 解决el-input textarea编辑异常的问题
        orderNoChange() {
            this.$forceUpdate();
        },
        //------------------------------------------------
        activeReqTable() {
            switch (this.queryTab) {
                case 1: return this.query;
                case 2: return this.body;
                case 3: return this.headers;
                default: return 0;
            }
        },
        updateActiveTable() {
            switch (this.queryTab) {
                case 1: return this.queryTableKey = Math.random();
                case 2: return this.bodyTableKey = Math.random();
                case 3: return this.headerTableKey = Math.random();
                default: return;
            }
        },
        addRowReq() {
            this.activeReqTable().push({ isEdit: true, isAdd: true })
        },
        editRowReq(row) {
            // 备份原始数据
            row['oldRow'] = JSON.parse(JSON.stringify(row))
            row.isEdit = true
            this.updateActiveTable()
        },
        saveRowReq(row) {
            // 备份原始数据
            row['oldRow'] = JSON.parse(JSON.stringify(row))
            row.isEdit = false
            row.isAdd = false
            this.updateActiveTable()
        },
        deleteRowReq(row, index) {
            this.activeReqTable().splice(index, 1)
        },
        cancelRowReq(row, index) {
            // 如果是新增的数据
            if (row.isAdd) {
                this.activeReqTable().splice(index, 1)
            } else {
                // 不是新增的数据  还原数据
                for (const i in row.oldRow) {
                    row[i] = row.oldRow[i]
                    row.isEdit = false
                    this.updateActiveTable()
                }
            }
        },
        //------------------------------------------------
        addRowRes() {
            this.respFields.push({ isEdit: true, isAdd: true })
        },
        editRowRes(row) {
            // 备份原始数据
            row['oldRow'] = JSON.parse(JSON.stringify(row))
            row.isEdit = true
            row.isAdd = false
            this.responseTableKey = Math.random()
        },
        saveRowRes(row) {
            // 备份原始数据
            row['oldRow'] = JSON.parse(JSON.stringify(row))
            row.isEdit = false
            this.responseTableKey = Math.random()
        },
        deleteRowRes(row, index) {
            this.respFields.splice(index, 1)()
        },
        cancelRowRes(row, index) {
            // 如果是新增的数据
            if (row.isAdd) {
                this.respFields.splice(index, 1)
            } else {
                // 不是新增的数据  还原数据
                for (const i in row.oldRow) {
                    row[i] = row.oldRow[i]
                    row.isEdit = false
                    this.responseTableKey = Math.random()
                }
            }
        },
        //-------------------------------------------------
        formatBodyFormat(code) {
            switch (code) {
                case 'FORM_DATA': return 0;
                case 'FORM_URL_ENCODED': return 1;
                case 'JSON': return 2;
                default: return undefined;
            }
        }
    }
}
</script>

<style lang="less" scoped>
.bind_catalog {
    background-color: #0f347e;
    width: 1880px;
    min-height: 800px;
    border: 1px solid #067cdb;
    border-radius: 5px;
    padding: 20px;
}

.bind_title {
    height: 40px;
}

.querybox {
    margin: 0 0 10px 0;
}

.radio_body {
    display: inline-block;
    position: relative;
    bottom: -10px;
}

/deep/.el-button--default:hover {
    border: solid 1px #007cd5;
    background-color: #1f499b;
    color: #7ac4fd;
}

/deep/.el-form-item__content {
    font-size: 18px;
}

/deep/.el-textarea__inner {
    border: #1B6CAC 1px solid;
    border-radius: 3px;
    background-color: #092864;
    color: #fff;
}

/deep/.el-textarea .el-input__count {
    background-color: #092864;
}
</style>