<template>
  <div v-show="isStart" :class="'menu_page' + aniClass">
    <div class="menu" v-for="item in items">
      <div class="group" v-if="item.children" :index="item.path" :key="item.path">
        <div class="title">
          <div class="shortline"></div>{{ item.name }}
        </div>
        <div class="tags">
          <router-link class="tag_item" v-for="(citem, cindex) in item.children" :to="citem.path" :key="cindex">
            <img class="tag-icon" :src="citem.icon" />{{ citem.name }}
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'left-menu',
  data() {
    return {
      isStart: false,
      isOut: true,
      aniClass: '',
      items: [
        {
          name: '资源目录管理',
          path: 'catalog',
          children: [
            {
              path: 'catalog',
              name: '编目管理',
              icon: require('../../assets/svg/nav/datadir_manager.svg')
            },
            {
              path: 'datadir',
              name: '目录管理',
              icon: require('../../assets/svg/nav/catalog_manager.svg')
            },
            {
              path: 'datasource',
              name: '数据源管理',
              icon: require('../../assets/svg/nav/datasource_manager.svg')
            },
          ],
        },
        {
          name: '数据共享门户',
          path: 'exchange',
          children: [
            {
              path: 'exchange',
              name: '数据目录',
              icon: require('../../assets/svg/nav/exchange_portal.svg')
            },
          ],
        },
        {
          name: '共享交换中心',
          path: 'exch-server',
          children: [
            {
              path: 'exch-server',
              name: '平台监控',
              icon: require('../../assets/svg/nav/exchange_server.svg')
            },
            {
              path: 'subscribe',
              name: '订阅管理',
              icon: require('../../assets/svg/nav/subscribe_manager.svg')
            },
          ],
        },
      ],
    }
  },
  methods: {
    show() {
      this.isStart = true
      this.isOut = !this.isOut
      this.aniClass = this.isOut ? ' move_out' : ' move_in'
    }
  }
}
</script>

<style lang="scss" scoped>
/* 自定义 */
.menu {
  position: relative;
  left: 0;
  min-height: 100px;
  background-color: rgba(0, 20, 50, 0.8);
}

.group {
  position: relative;
  width: inherit;

  .title {
    display: inline-block;
    padding: 20px 0 10px 0;
    font-weight: 700;
    font-style: normal;
    font-size: 20px;
    color: #FFFFFF;
    line-height: 18px;

    .shortline {
      display: inline-block;
      width: 3px;
      height: 18px;
      background-color: rgba(122, 196, 253, 1);
      margin: -2px 8px;
    }
  }

  .tags {
    width: 380px; // 一行3个：380px； 一行4个：500px
    display: flex;
    justify-content: flex-start;
    // align-items: flex-start;
    flex-wrap: wrap;

    .tag_item {
      width: 100px;
      height: 68px;
      background-color: rgba(15, 56, 117, 0.8);
      border: none;
      border-radius: 6px;
      margin: 10px 12px;
      color: #7AC4FD;
      text-align: center;
      line-height: 18px;
      cursor: pointer;

      .tag-icon {
        display: block;
        margin: 10px auto;
      }
    }

    .tag_item:hover {
      color: #FFFFFF;
    }
  }
}

.move_in {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-name: slidein;
}

@keyframes slidein {
  from {
    transform: translate(-500px, 0);
  }

  to {
    transform: translate(0, 0);
  }
}

.move_out {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-name: slideout;
}

@keyframes slideout {
  from {
    transform: translate(0, 0);
  }

  to {
    transform: translate(-500px, 0);
  }
}
</style>
