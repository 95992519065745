export class EnumFactory {
  /*
    {arr} 二维数组
    例如 arr = [
      [label, value, alias],
      ['小花','123','FLOWER'],
    ]

    输出 options = [
      {
        label: '',
        value: '',
      }
    ]
  */
  mapList = new Map()
  options = []
  constructor(arr) {
    if (!Array.isArray(arr)) return

    arr.forEach(([label, value, alias]) => {
      this.mapList.set(label, value)
      if (alias) {
        this[alias] = label
      }
    })

    const mapListLen = this.mapList.size

    // 将数据转换为 options
    this.options = !mapListLen
      ? 0
      : [...this.mapList].map(([value, label]) => ({
          label,
          value,
        }))
  }

  // 获取
  getValue(key) {
    return this.mapList.get(key)
  }
}
