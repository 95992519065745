// 防抖函数
export const debounce = (fn, delay = 500) => {
  let timer = null
  let that = this
  return (...args) => {
    timer && clearTimeout(timer)
    timer = setTimeout(() => {
      fn.apply(that, args)
    }, delay)
  }
}

export function throttle(fn, delay = 500) {
  var previous = 0
  // 使用闭包返回一个函数并且用到闭包函数外面的变量previous
  return function () {
    var _this = this
    var args = arguments
    var now = new Date()
    if (now - previous > delay) {
      fn.apply(_this, args)
      previous = now
    }
  }
}
