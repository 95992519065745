<template>
  <div class="app-container">
    <!-- <sidebar v-if="!sidebar.hide" class="sidebar-container" /> -->
    <navbar />
    <!-- 搜索工作栏 -->
    <div class="page_contain">
      <!-- 左侧编目维度 -->
      <div class="catalog_dimension">
        <el-tabs class="tab_tree" v-model="activeTree" stretch @tab-click="updateTree">
          <el-tab-pane label="部门" name="first">
          </el-tab-pane>
          <el-tab-pane label="基础" name="second">
          </el-tab-pane>
          <el-tab-pane label="主题" name="third">
          </el-tab-pane>
        </el-tabs>
        <div class="line-decoration"></div>
        <div class="head-container">
          <el-input v-model="treeName" placeholder="请输入内容" clearable size="middle" prefix-icon="el-icon-search"
            style="margin: 20px 0" />
        </div>
        <div class="head-container">
          <el-tree :data="deptOptions" :props="defaultProps" :expand-on-click-node="false" lazy :load="loadNode"
            :filter-node-method="filterNode" ref="tree" :highlight-current="true" @node-click="handleNodeClick" />
          <!-- default-expand-all  -->
        </div>
      </div>
      <!-- 右侧门户主要内容 -->
      <div class="portal_container">
        <!-- 列表搜索 -->
        <div v-show="is_show_card">
          <el-form :model="queryParams" ref="queryForm" size="middle" :inline="true" @submit.native.prevent>
            <el-form-item label="排序方式" prop="orderColumn">
              <el-radio label="updateTime" v-model='queryParams.orderColumn' @change="handleQuery">更新时间</el-radio>
              <el-radio label="updateCycle" v-model='queryParams.orderColumn' @change="handleQuery">更新周期</el-radio>
              <el-radio label="accessCount" v-model='queryParams.orderColumn' @change="handleQuery">访问排序</el-radio>
            </el-form-item>
            <el-form-item>
              <div class="head-container">
                <el-input v-model="queryParams.name" placeholder="请输入内容" clearable size="middle"
                  prefix-icon="el-icon-search" />
              </div>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" icon="el-icon-search" @click="handleQuery">查询</el-button>
            </el-form-item>
          </el-form>

          <!-- 卡片列表 -->
          <el-row v-loading="loading">
            <el-col :span="12" v-for="o in datadirList" :key="o.id" style="height: 290px;">
              <el-card @click.native="handleCardView(o)" shadow="hover" :body-style="{ padding: '6px', height: '270px' }">
                <div class="card-head">
                  <span class="card-title">{{ o.name }}</span>
                  <div class="card-views">
                    <img class="card-icon" src="../../assets/svg/views.svg" alt="">
                    <span>{{ o.accessCount }}</span>
                    <img class="card-icon" src="../../assets/svg/stars.svg" alt="">
                    <span>{{ o.collectCount }}</span>
                    <img class="card-icon" src="../../assets/svg/right_arrow.svg" alt="">
                  </div>
                </div>
                <img src="../../assets/common/line.png" alt="" style="width: 100%; height: 3px;">
                <div class="card-tab" style="padding: 0 14px;">
                  <el-row>
                    <el-col :span="12" class="card-item"><span>共享类型</span>
                      <span>{{ formatFromDict(o.shareType, dict_share_type) }}</span>
                    </el-col>
                    <el-col :span="12" class="card-item"><span>所属主题</span><span>{{ o.front_topic || '无' }}</span></el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12" class="card-item"><span>更新周期</span>
                      <span>{{ formatFromDict(o.updateCycle, dict_update_cycle) }}</span>
                    </el-col>
                    <el-col :span="12" class="card-item"><span>所属基础</span><span>{{ o.front_basic || '无' }}</span></el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12" class="card-item"><span>所属部门</span><span>{{ o.front_dept || '无' }}</span></el-col>
                    <el-col :span="12" class="card-item"><span>描述</span><span>{{ o.resourceDescribe }}</span></el-col>
                  </el-row>
                </div>
                <div class="bottom clearfix">
                  <div class="card-update">更新时间：{{ parseTime(o.updateTime) }}</div>
                  <div class="card-collect">
                    <el-button v-show="!o.collected" type="text" class="btn-card"
                      @click.stop="handleCollect(o)">收藏</el-button>
                    <el-button v-show="o.collected" type="text" class="btn-card btn-disabled"
                      @click.stop="handleCancelCollect(o)">取消收藏</el-button>
                    <el-button v-show="!o.subscribed" type="text" class="btn-card"
                      @click.stop="handleSubscribe(o)">订阅</el-button>
                    <el-button v-show="o.subscribed" type="text" class="btn-card btn-disabled"
                      @click.stop="handleCancelSubscribe(o)">取消订阅</el-button>
                  </div>
                </div>
              </el-card>
            </el-col>
          </el-row>

          <pagination :total="total" :page.sync="queryParams.pageNo" :limit.sync="queryParams.pageSize"
            @pagination="getList" />
        </div>
        <!-- 详情 -->
        <div v-show="!is_show_card" class="datadir-detail">
          <div class="btn-back" @click="backShowCard">
            <div class="svg-back"></div>返回
          </div>
          <div class="detail-collect">
            <el-button type="text" v-show="!catalog.collected" @click.stop=handleCollect(catalog)>收藏</el-button>
            <el-button type="text" v-show="catalog.collected" @click.stop=handleCancelCollect(catalog)>取消收藏</el-button>
            <el-button type="text" v-show="!catalog.subscribed" @click.stop=handleSubscribe(catalog)>订阅</el-button>
            <el-button type="text" v-show="catalog.subscribed" @click.stop=handleCancelSubscribe(catalog)>取消订阅</el-button>

          </div>
          <div style="height: 30px;"></div>

          <!-- 导航 @tab-click="handleTabClick"-->
          <el-tabs v-model="activeTab">
            <el-tab-pane label="基本信息" name="first">
              <div class="basic-title">基本信息</div>
              <div class="card-tab" style="padding: 25px 45px;">
                <el-row>
                  <el-col :span="12" class="basic-item">
                    <span>所属部门：　</span><span>{{ catalog.front_dept || '无' }}</span>
                  </el-col>
                  <el-col :span="12" class="basic-item">
                    <span>资源名称：　</span><span>{{ catalog.name || '无' }}</span>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="12" class="basic-item">
                    <span>系统名称：　</span><span>{{ catalog.systemName || '无' }}</span>
                  </el-col>
                  <el-col :span="12" class="basic-item">
                    <span>职责事项：　</span><span>{{ catalog.dutyName || '无' }}</span>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="12" class="basic-item">
                    <span>所属主题：　</span><span>{{ catalog.front_topic || '无' }}</span>
                  </el-col>
                  <el-col :span="12" class="basic-item">
                    <span>所属基础：　</span><span>{{ catalog.front_basic || '无' }}</span>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="12" class="basic-item">
                    <span>目录状态：　</span><span>{{ formatFromDict(catalog.state, dict_resdir_state) }}</span>
                  </el-col>
                  <el-col :span="12" class="basic-item">
                    <span>更新频率：　</span><span>{{ formatFromDict(catalog.updateCycle, dict_update_cycle) }}</span></el-col>
                </el-row>
                <el-row>
                  <el-col :span="12" class="basic-item">
                    <span>组织来源：　</span><span>{{ catalog.deptName || '无' }}</span>
                  </el-col>
                  <el-col :span="12" class="basic-item">
                    <span>共享类型：　</span><span>{{ formatFromDict(catalog.shareType, dict_share_type) }}</span>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="12" class="basic-item">
                    <span>共享方式：　</span><span>{{ formatFromDict(catalog.shareWay, dict_share_way) }}</span>
                  </el-col>
                  <el-col :span="12" class="basic-item">
                    <span>发布时间：　</span><span>{{ parseTime(catalog.publishTime) }}</span>
                  </el-col>
                </el-row>
              </div>
              <div class="contain">
                <div class="basic-title">内容简介</div>
                <div class="card-tab" style="padding: 25px 45px;">
                  <el-row>
                    <el-col :span="24" class="basic-item" style="display: flex; align-items: start;">
                      <span>　　描述：</span>
                      <div class="basic-describe">{{ catalog.resourceDescribe }}</div>
                    </el-col>
                  </el-row>
                </div>
              </div>
              <div class="contain">
                <div class="basic-title">使用情况</div>
                <div class="card-tab" style="padding: 25px 45px;">
                  <el-row>
                    <el-col :span="12" class="basic-item"><span>浏览次数：　</span><span>{{ catalog.accessCount
                    }}</span></el-col>
                    <el-col :span="12" class="basic-item"><span>收藏次数：　</span><span>{{ catalog.collectCount
                    }}</span></el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12" class="basic-item"><span>订阅次数：　</span><span>{{ catalog.subCount }}</span></el-col>
                  </el-row>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="数据项" name="second">
              <!-- 查询结果 -->
              <el-table v-loading="loading" :data="catalog.dataItems" stripe border>
                <el-table-column align="center" width="60" label="序号" key="id" prop="id" />
                <el-table-column align="center" width="110" label="数据项名称" key="itemName" prop="itemName" />
                <el-table-column align="center" width="110" label="数据项描述" key="itemDesc" prop="itemDesc" />
                <el-table-column align="center" width="auto" label="数据类型" key="dataType" prop="dataType" />
                <el-table-column align="center" width="auto" label="数据长度" key="dataLength" prop="dataLength" />
                <el-table-column align="center" width="auto" label="数据分级" key="dataGrade" prop="dataGrade" :formatter="dataGradeFormat" />
                <el-table-column align="center" width="auto" label="共享类型" key="shareType" prop="shareType" :formatter="shareTypeFormat" />
                <el-table-column align="center" width="auto" label="共享条件" key="shareCondition" prop="shareCondition" :formatter="shareConditionFormat" />
                <el-table-column align="center" width="140" label="不予共享依据" key="notShareReason" prop="notShareReason" />
                <el-table-column align="center" width="auto" label="开放类型" key="openType" prop="openType" :formatter="openTypeFormat" />
                <el-table-column align="center" width="auto" label="开放条件" key="openCondition" prop="openCondition" />
                <el-table-column align="center" width="150" label="是否为核数据项" key="isCore" prop="isCore" :formatter="booleanFormat"/>
                <el-table-column align="center" width="auto" label="是否非空" key="isEmpty" prop="isEmpty" :formatter="booleanFormat"/>
                <el-table-column align="center" width="auto" label="取值范围" key="valueRange" prop="valueRange" />
              </el-table>
              <!-- <pagination v-show="total > 0" :total="total" :page.sync="queryParams.pageNo"
                :limit.sync="queryParams.pageSize" @pagination="getList" /> -->
            </el-tab-pane>
            <!-- <el-tab-pane label="数据分析" name="third"></el-tab-pane> -->
          </el-tabs>

        </div>
      </div>
    </div>

    <!-- 添加或修改参数配置对话框 -->
    <el-dialog title="订阅" :visible.sync="dialog_open" width="1600px" append-to-body>
      <el-form ref="form" :model="form" label-width="120px" :rules="rules">
        <el-row>
          <el-col :span="24">
            <el-form-item label="订阅" prop="purpose">
              <el-radio :label="'1'" v-model="form.purpose">学习</el-radio>
              <el-radio :label="'2'" v-model="form.purpose">调研</el-radio>
              <el-radio :label="'3'" v-model="form.purpose">工作</el-radio>
              <el-radio :label="'4'" v-model="form.purpose">业务</el-radio>
              <el-radio :label="'5'" v-model="form.purpose">社会性事务</el-radio>
              <el-radio :label="'6'" v-model="form.purpose">其他</el-radio>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="申请理由" prop="applyReason">
              <el-input v-model="form.applyReason" type="textarea" placeholder="请输入内容" maxlength="100"
                show-word-limit></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="部门" prop="deptId">
              <el-select v-model="form.deptId" placeholder="请选择">
                <el-option v-for="item in departList" :key="parseInt(item.id)" :label="item.name"
                  :value="parseInt(item.id)"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="有效时间" prop="expireAt">
              <el-date-picker v-model="form.expireAt" type="datetime" placeholder="请选择日期时间">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-table ref="form_table" v-loading="loading" :data="catalog.dataItems" stripe border>
            <el-table-column type="selection" width="45" />
            <el-table-column align="center" width="60" label="序号" key="id" prop="id" />
            <el-table-column align="center" width="115" label="数据项名称" key="itemName" prop="itemName" />
            <el-table-column align="center" width="115" label="数据项描述" key="itemDesc" prop="itemDesc" />
            <el-table-column align="center" width="auto" label="数据类型" key="dataType" prop="dataType" />
            <el-table-column align="center" width="auto" label="数据长度" key="dataLength" prop="dataLength" />
            <el-table-column align="center" width="auto" label="数据分级" key="dataGrade" prop="dataGrade" :formatter="dataGradeFormat" />
            <el-table-column align="center" width="auto" label="共享类型" key="shareType" prop="shareType" :formatter="shareTypeFormat" />
            <el-table-column align="center" width="auto" label="共享条件" key="shareCondition" prop="shareCondition" :formatter="shareConditionFormat" />
            <el-table-column align="center" width="140" label="不予共享依据" key="notShareReason" prop="notShareReason" />
            <el-table-column align="center" width="auto" label="开放类型" key="openType" prop="openType" :formatter="openTypeFormat" />
            <el-table-column align="center" width="auto" label="开放条件" key="openCondition" prop="openCondition" />
            <el-table-column align="center" width="150" label="是否为核数据项" key="isCore" prop="isCore" :formatter="booleanFormat"/>
            <el-table-column align="center" width="auto" label="是否非空" key="isEmpty" prop="isEmpty" :formatter="booleanFormat"/>
            <el-table-column align="center" width="auto" label="取值范围" key="valueRange" prop="valueRange" />
          </el-table>
          <!-- <pagination v-show="total > 0" :total="total" :page.sync="queryParams.pageNo" :limit.sync="queryParams.pageSize"
            @pagination="getList" /> -->
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleSendSub">确 定</el-button>
        <el-button @click="dialog_open = false">取 消</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import {
  listDatadir, detailDatadir, collectDatadir, subscribeDatadir,
  cancelCollectDatadir, cancelSubscribeDatadir
} from "@/api/system/catalog";
import { childCatalog, rootCatalog, userDepts } from "@/api/system/dept";
import { parseTime, praseStrEmpty } from "@/utils/ruoyi"
import dict from '../mixins/dict.js'
import Navbar from '@/components/common/Navbar.vue'
// import { Sidebar } from '@/components/Sidebar'

export default {
  name: "Portal",
  components: { Navbar },
  mixins: [dict],
  data() {
    return {
      // 遮罩层
      loading: true,
      // tree_loading: true,
      // 总条数
      total: 0,
      // 目录列表数据
      datadirList: [],
      // 部门下拉列表
      departList: [],
      // 编目根节点数据
      rootList: [],
      // 编目根节点对应关系
      rootMap: {},
      // 已订阅列表
      subscribedList: [],
      // 已收藏列表
      collectedList: [],
      // 弹出层标题
      title: "",
      // 部门树选项
      deptOptions: undefined,
      // 是否显示弹出层
      dialog_open: false,
      // 编目维度名称
      treeName: undefined,
      // 表单参数
      form: {},
      // 表单校验
      rules: {
        purpose: [{ required: true, message: "订阅用途不能为空", trigger: "blur" }],
        applyReason: [{ required: true, message: "描述不能为空", trigger: "blur" }],
        expireAt: [{ required: true, message: "有效时间不能为空", trigger: "blur" }],
        deptId: [{ required: true, message: "部门不能为空", trigger: "blur" }],
      },
      defaultProps: {
        children: "children",
        isLeaf: "leaf",
        label: "name"
      },
      // 查询参数
      queryParams: {
        pageNo: 1,
        pageSize: 10,
        name: '',
        catalogId: '1',
        includeChild: true,        // 查询子结点下所有目录
        orderColumn: 'updateTime', // updateTime updateCycle accessCount
        orderDirection: 'desc'
      },
      is_show_card: true,    // true展示列表, false展示详情
      activeTab: 'first',    // 正在展示的详情
      activeTree: 'first',   // 正在展示的编目
      catalog: {},           // 展开的目录信息
      // 列信息（权限）
      columns: [
        { key: 0, label: `序号`, visible: true },
        { key: 1, label: `目录名称`, visible: true },
        { key: 2, label: `用户昵称`, visible: true },
        { key: 3, label: `部门`, visible: true },
        { key: 4, label: `手机号码`, visible: true },
        { key: 5, label: `状态`, visible: true },
        { key: 6, label: `更新时间`, visible: true }
      ],
    };
  },
  watch: {
    // 根据名称筛选部门树
    treeName(val) {
      this.$refs.tree.filter(val);
    }
  },
  created() {
    // this.getsubscribedList();
    // this.getCollectedList();
    this.getTreeRoot();
    this.getDepartList();
    // this.getList();

  },
  methods: {
    // 切换结果展示
    handleCardView(obj) {
      detailDatadir(obj.id).then(res => {
        if(res && res.data){
          this.catalog = Object.assign({}, obj, res.data) // 部门、基础、主题，短时间内不频繁更新
        } else {
          this.catalog = obj
          this.$message.error("系统繁忙")
        }
      }, err => {
        this.$message.error(err.message || err)
      });
      this.activeTab = 'first'
      this.is_show_card = false
    },
    backShowCard(){
      this.is_show_card = !this.is_show_card
      this.handleQuery()
    },
    // 获取部门下拉列表
    getDepartList() {
      userDepts().then(response => {
        if (response.data) {
          this.departList = response.data || [];
        }
      }, err => {
        this.$message.error(err.message)
      });
    },
    // 查询门户目录列表
    getList(callback) {
      this.loading = true;
      listDatadir(this.queryParams).then(response => {
        if (response.data) {
          let arr = response.data.list || [];
          this.datadirFilter(arr)
          this.datadirList = arr
          this.total = response.data.total;
        }
        this.loading = false;
        callback && callback()
      }, err => {
        this.$message.error(err.message)
        callback && callback()
      });
    },
    // 处理所属部门、所属基础、所属主题
    // front_dept, front_basic, front_topic
    datadirFilter(arr) {
      arr.forEach(item => {
        if (item.catalogs instanceof Array) {
          item.catalogs.forEach(catalog => {
            let key = 'front_' + this.rootMap[catalog.catalogRootId]
            item[key] = catalog.catalogName
          })
        }
      })
    },
    // 查询编目维度下拉树结构
    getTreeChild(root, callback) {
      let root_obj = { catalogId: 1 }
      if (root instanceof Object) root_obj = root
      let root_id = root_obj.catalogId || 1
      childCatalog({ id: root_id }).then(response => {
        this.tree_loading = false
        let res_arr = this.formatTreeNode(response.data)
        res_arr.forEach(i => {
          i.id = i.id || i.catalogId
          i.name = i.name || i.catalogName
          i.parentId = i.parentId || i.catalogPid || 0
        })
        callback && callback(res_arr)
      }, err => {
        this.$message.error(err.message)
      });
    },
    getTreeFirstLever(root) {
      let root_obj = { catalogId: 1 }
      if (root instanceof Object) root_obj = root
      let res_arr = [root_obj]
      // 处理 deptOptions 参数
      this.deptOptions = [];
      this.deptOptions.push(...this.handleTree(res_arr, "id"));

      this.queryParams.catalogId = root_obj.catalogId
      this.getList() // 点击tab查询对应的目录列表
    },
    // 查询编目维度根节点，当然墙裂建议后端直接返回对应根目录维度
    getTreeRoot() {
      rootCatalog().then(response => {
        let res_arr = this.formatTreeNode(response.data)
        this.rootList = []
        res_arr.forEach(item => {
          this.rootList.push(item)
          // console.log('rootlist', this.rootList)
          if (/部门/.test(item.catalogName)) {
            return this.rootMap[item.catalogId] = 'dept'
          }
          if (/基础/.test(item.catalogName)) {
            return this.rootMap[item.catalogId] = 'basic'
          }
          if (/主题/.test(item.catalogName)) {
            return this.rootMap[item.catalogId] = 'topic'
          }
        })
        let dep = this.rootList.find(i => i.catalogName == "部门")
        if (dep instanceof Object) this.getTreeFirstLever(dep);
        this.getList()
      }, err => {
        this.$message.error(err.message)
      });
    },
    // 预处理树状结构
    formatTreeNode(arr) {
      if (!arr || !arr instanceof Array) return []
      arr.forEach(i => {
        i.id = i.id || i.catalogId
        i.name = i.name || i.catalogName
        i.parentId = i.parentId || i.catalogPid || 0
      })
      return arr
    },
    // 切换树tab
    updateTree(tab) {
      switch (tab.index) {
        case "0": {
          this.getTreeFirstLever(this.rootList.find(i => i.catalogName == "部门"));
          break;
        }
        case "1": {
          this.getTreeFirstLever(this.rootList.find(i => i.catalogName == "基础"));
          break;
        }
        case "2": {
          this.getTreeFirstLever(this.rootList.find(i => i.catalogName == "主题"));
          break;
        }
      }
    },
    // 筛选节点
    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    // 懒加载树
    loadNode(node, resolve) {
      console.log("lazy load", node.data, resolve)
      if (!node.data) return resolve([])
      this.getTreeChild(node.data, resolve)
    },
    // 节点单击事件
    handleNodeClick(data) {
      this.queryParams.catalogId = data.id;
      this.getList(() => { this.is_show_card = true; });  // 点击编目树后必展示card
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNo = 1;
      this.getList();
    },
    // 取消收藏
    handleCancelCollect(obj) {
      cancelCollectDatadir(obj.id).then(response => {
        this.$message.success('取消收藏成功')
        obj.collected = false
        this.getList(); // 刷新收藏次数
      }, err => {
        this.$message.error(err.message)
      })
    },
    // 取消订阅
    handleCancelSubscribe(obj) {
      cancelSubscribeDatadir(obj.id).then(response => {
        this.$message.success('取消订阅成功')
        obj.subscribed = false
      }, err => {
        this.$message.error(err.message)
      })
    },
    // 收藏目录
    handleCollect(o) {
      let obj = { dirId: praseStrEmpty(o.id) }
      collectDatadir(obj).then(response => {
        this.$message.success('收藏成功')
        o.collected = true
        this.getList(); // 刷新收藏次数
      }, err => {
        // console.log('err', err);  // TODO 异常处理
        this.$message.error(err.message)
      });
    },
    // 订阅目录
    handleSubscribe(obj) {
      // this.catalog = obj || {}
      detailDatadir(obj.id).then(response => {
        obj.dataItems = (!response.data || !response.data.dataItems) ? [] : response.data.dataItems
        this.catalog = obj
      }, err => {
        this.$message.error(err.message)
      });
      !this.catalog.dataItems && (this.catalog.dataItems = [])
      this.dialog_open = true
      this.form = {}
    },
    handleSendSub() {
      this.$refs['form'].validate(valid => {
        if (!valid) {
          return
        }
        if (this.$refs.form_table.selection.length === 0) {
          return this.$message.error('请选择数据项')
        }
        this.form.dirId = this.catalog.id
        this.form.expireAt = +this.form.expireAt // 格式化时间
        // 格式化数据项
        let dataArr = []
        this.$refs.form_table.selection.forEach(item => { dataArr.push({ dataItemId: item.id }) })
        this.form.subscribeDataItemReqVos = dataArr
        subscribeDatadir(this.form).then(response => {
          this.$message.success("订阅成功");
          this.dialog_open = false
          this.datadirList.find(i => i.id == this.catalog.id).subscribed = true
        }, err => {
          this.$message.error(err.message)
        });

      });
    },
  },

};
</script>
<style lang="less" scoped>
.page_contain {
  position: relative;
  height: 930px;
  margin: 0 20px;
}

/* 编目维度 */
.catalog_dimension {
  text-align: center;
  position: absolute;
  left: 0;
  padding: 0 10px !important;
  background-color: #06346e;
  /* border: 3px solid #05559b; */
  border-radius: 5px;
  height: 100%;
  width: 16%;
  background: url('../../assets/svg/bg_tree.svg');
  background-size: 100% 100%;
}

.line-decoration {
  position: absolute;
  top: 53px;
  left: 1%;
  width: 98%;
  height: 2px;
  background-color: #085ead;
}

.tab_tree {
  width: 94%;
  text-align: center;
  position: relative;
  left: 3%;
  top: 15px;
}

/* 搜索结果 */
.portal_container {
  position: absolute;
  right: 0;
  /* border: 2px solid #555; */
  width: 83%;
}

.card-item {
  display: flex
}

.card-item span,
.basic-item span {
  line-height: 45px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.basic-describe {
  width: 92%;
  display: inline-block;
  line-height: 35px;
  padding: 5px 20px;
}

.card-item span:first-child {
  flex: 1;
  color: #82CEFF;
}

.card-item span:last-child {
  flex: 3;
}

.card-title {
  display: inline-block;
  width: 80%;
  position: relative;
  font-size: 20px;
  padding: 0 14px;
  line-height: 45px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.card-head {
  position: relative;
  height: 45px;
}

.card-views {
  position: absolute;
  right: 0;
  bottom: 0;
  /* height: 36px; */
  display: inline-block;
  color: #a7b4cf;
  font-size: 14px;
  line-height: 45px;
}

.card-icon {
  color: #333;
  width: auto;
  height: 13px;
  margin: 16px 0;
  padding: 0 7px;
}

.card-collect {
  position: absolute;
  right: 15px;
  bottom: 10px;
}

.card-update {
  font-size: 16px;
  opacity: 0.7;
  position: absolute;
  left: 14px;
  bottom: 17px;
}

.btn-card {
  background: url('../../assets/svg/btn/collect.svg');
  background-size: 100% 100%;
  width: 80px;
  height: 32px;
  color: #fff;
  line-height: 9px;
}

/* 详情 */
.datadir-detail {
  position: relative;
  padding: 20px;
  width: 100%;
  /* height: 930px; */
  background-color: #123271;
}

.detail-collect {
  position: absolute;
  right: 15px;
  top: 10px;
}

.detail-collect .el-button {
  width: 80px;
  height: 40px;
  border: solid 1px #045b9f;
  border-radius: 4px;
  font-size: 18px;
  margin: 8px;
}

.btn-card:hover,
.detail-collect .el-button:hover {
  border: solid 1px #007cd5;
  background-color: #1f499b;
  color: #7ac4fd;
}

.btn-disabled,
.btn-disabled:hover {
  width: 100px;
  border: solid 1px #007cd5;
  background: none;
  color: #ccc;
}

.btn-back {
  width: 80px;
  height: 40px;
  padding: 5px 0;
  cursor: pointer;
  color: #7ac4fd;
}

.svg-back {
  display: inline-block;
  margin: 0 10px;
  width: 14px;
  height: 14px;
  background-image: url("../../assets/svg/btn/back.svg");
}

.svg-back:hover {
  background-image: url("../../assets/svg/btn/back_hover.svg");
}

.basic-title {
  background-color: #2650a2;
  padding: 13px;
}

/deep/.el-textarea__inner,
/deep/.el-input--prefix .el-input__inner {
  border: #1B6CAC 1px solid;
  border-radius: 3px;
  background-color: #092864;
  color: #fff;
}

/deep/.el-textarea .el-input__count {
  background-color: #092864;
}</style>
