import request from '@/utils/request'
import { praseStrEmpty } from "@/utils/ruoyi";
const adminurl = '/admin-api'

// 新增关联用户
export function addCatalogUser(data) {
  return request({
    url: adminurl + '/rdm/catalog/user',
    method: 'post',
    data: data
  })
}

// 删除关联用户
export function deleteCatalogUser(id) {
  return request({
    url: adminurl + '/rdm/catalog/user/' + praseStrEmpty(id),
    method: 'delete'
  })
}

// 查询关联用户
export function listCatalogUser(catalogId) {
  return request({
    url: adminurl + '/rdm/catalog/user/' + praseStrEmpty(catalogId),
    method: 'get'
  })
}

// 新增根编目
export function addRootCatalogRequest(data) {
  return request({
    url: adminurl + '/rdm/catalog/root',
    method: 'post',
    params: data
  })
}

// 新增非根编目
export function addCatalogRequest(data) {
  return request({
    url: adminurl + '/rdm/catalog',
    method: 'post',
    data: data
  })
}

// 编辑非根编目
export function editCatalogRequest(data) {
  let obj = {
    id: data.id,
    catalogName: praseStrEmpty(data.catalogName),
    catalogCode: praseStrEmpty(data.catalogCode),
    creditCode: praseStrEmpty(data.creditCode),
    ownId: praseStrEmpty(data.ownId),
    catalogDescription: praseStrEmpty(data.catalogDescription)
  }
  return request({
    url: adminurl + '/rdm/catalog/' + praseStrEmpty(data.id),
    method: 'put',
    data: obj
  })
}

// 删除编目，目前都是删除所有子类
export function deleteCatalogRequest(id) {
  let obj = { delchild: true }
  return request({
    url: adminurl + '/rdm/catalog/' + praseStrEmpty(id),
    method: 'delete',
    params: obj
  })
}

// 查询目录列表-门户
export function listDatadir(query) {
  return request({
    url: '/portal/datadir/page',
    method: 'get',
    params: query
  })
}

export function detailDatadir(query) {
  return request({
    url: '/portal/datadir/' + query,
    method: 'get',
    // params: query
  })
}

// 收藏目录
export function collectDatadir(data) {
  return request({
    url: adminurl + '/rdm/dircollect/collect',
    method: 'post',
    params: data
  })
}
// 订阅目录
export function subscribeDatadir(data) {
  return request({
    url: adminurl + '/exch/subscribe',
    method: 'post',
    data: data
  })
}
// 取消收藏目录
export function cancelCollectDatadir(id) {
  return request({
    url: adminurl + '/rdm/dircollect/cancel?dirId=' + id,
    method: 'delete',
    // params: data
  })
}
// 取消订阅目录
export function cancelSubscribeDatadir(id) {
  return request({
    url: adminurl + '/exch/subscribe/cancel?dirId=' + id,
    method: 'delete',
    // params: data
  })
}