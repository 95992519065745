<template>
  <div class="navbar">
    <div class="topTitle">
      <hamburger id="hamburger-container" :is-active="sidebar.opened" class="hamburger-container"
        @toggleClick="toggleSideBar" @click.native="clickHamburger"/>
      <img src="../../assets/common/zsrz.png" alt="">
      <!-- <span>共享能力价值分析平台</span> -->
    </div>
    <div class="right-menu">

      <!-- <el-dropdown class="avatar-container right-menu-item hover-effect" trigger="click">
        <div class="avatar-wrapper">
          <span v-if="nickname" class="user-nickname">{{ nickname }}</span>
          <i class="el-icon-caret-bottom" />
        </div>
        <el-dropdown-menu slot="dropdown">
          <router-link to="/user/profile">
            <el-dropdown-item>个人中心</el-dropdown-item>
          </router-link>
          <el-dropdown-item divided @click.native="logout">
            <span>退出登录</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown> -->
    </div>
    <div class="bottom-line"></div>

    <breadcrumb id="breadcrumb-container" class="breadcrumb-container" />
    <LeftMenu ref="menu"></LeftMenu>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Breadcrumb from '@/components/Breadcrumb'
import Hamburger from '@/components/Hamburger'
import SizeSelect from '@/components/SizeSelect'
import RuoYiGit from '@/components/RuoYi/Git'
import RuoYiDoc from '@/components/RuoYi/Doc'
import LeftMenu from './LeftMenu.vue'
import { getPath } from "@/utils/ruoyi";

export default {
  components: {
    Breadcrumb,
    Hamburger,
    LeftMenu,
    SizeSelect,
    RuoYiGit,
    RuoYiDoc
  },
  data(){ 
    return {
    }
  },
  computed: {
    ...mapGetters([
      'sidebar',
      'avatar',
      'nickname',
      'device'
    ]),
    setting: {
      get() {
        return this.$store.state.settings.showSettings
      },
      set(val) {
        this.$store.dispatch('settings/changeSetting', {
          key: 'showSettings',
          value: val
        })
      }
    }
  },
  methods: {
    toggleSideBar() {
      this.$store.dispatch('app/toggleSideBar')
    },
    clickHamburger(){
      this.$refs['menu'].show()
    },
    toPortal() {
      window.location.href = window.location.origin + '/portal'

    },
    async logout() {
      this.$modal.confirm('确定注销并退出系统吗？', '提示').then(() => {
        this.$store.dispatch('LogOut').then(() => {
          location.href = getPath('/index');
          //清除sessionStorage登录信息
          sessionStorage.clear()

        })
      }).catch(() => { });
    }
  }
}
</script>

<style lang="scss" scoped>
.navbar {
  height: 120px;
  overflow: hidden;
  position: relative;
  // background: #fff;
  box-shadow: 0px 1px 4px 0px rgba(0, 21, 41, 0.12);

  .topTitle {
    position: relative;
    height: 71px;
    line-height: 50px;
    font-size: 20px;
    float: left;

    // margin: 0 20px;
    img {
      position: absolute;
      left: 50px;
      top: 12px;
      width: 132px;
      height: 43px;
      vertical-align: middle;
    }

    span {
      font-size: 18px;
      font-weight: 800;
      color: transparent;
      background-image: linear-gradient(180deg, #69dcff, #085cde);
      background-clip: text;
      -webkit-background-clip: text;
    }
  }

  .right-menu {
    float: right;
    height: 100%;
    line-height: 50px;

    &:focus {
      outline: none;
    }

    .right-menu-item {
      display: inline-block;
      padding: 0 8px;
      height: 100%;
      font-size: 18px;
      color: #5a5e66;
      vertical-align: text-bottom;

      &.hover-effect {
        cursor: pointer;
        transition: background .3s;

        &:hover {
          background: rgba(0, 0, 0, .025)
        }
      }
    }

    .avatar-container {
      margin-right: 30px;

      .avatar-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        .user-avatar {
          cursor: pointer;
          width: 35px;
          height: 35px;
          border-radius: 50%;
        }

        .user-nickname {
          margin-left: 5px;
          font-size: 14px;
        }

        .el-icon-caret-bottom {
          cursor: pointer;
          position: absolute;
          right: -20px;
          top: 20px;
          font-size: 12px;
        }
      }
    }
  }

  .bottom-line {
    position: absolute;
    top: 71px;
    width: 100%;
    height: 4px;
    background-image: url('../../assets/common/line.png');
  }

  .breadcrumb-container {
    width: 120px;
    height: 50px;
    position: absolute;
    left: 20px;
    bottom: 0;
  }

  .hamburger-container {
    position: absolute;
    left: 4px;
    top: 8px;
    cursor: pointer;
  }

  // 导航

  .el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 200px;
    min-height: 400px;
    position: fixed;
  }

  .menu_page {
    position: fixed;
    top: 71px;
    left: 0;
    min-height: 100%;
    background-color: none;
    z-index: 99;
  }

  .el-menu {
    border: none;
  }

  .fa-margin {
    margin-right: 5px;
  }

  .el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 180px;
    min-height: 400px;
  }

  .el-submenu .el-menu-item {
    width: 0px;
  }

  .hiddenDropdown,
  .hiddenDropname {
    display: none;
  }

  a {
    text-decoration: none;
  }
}
</style>
