<template>
    <!-- 根编目管理 -->
    <el-dialog title="编目维度列表" :visible="root_dialog_open" width="1200px" append-to-body center @close="close">
        <div class="btn_add">
            <el-button size="mini" icon="el-icon-circle-plus" type="text" @click="addRow">新增</el-button>
        </div>
        <el-table :data="rootList" row-key="id" :key="rootTableKey">
            <el-table-column align="center" width="80px" label="序号" key="catalogId" prop="catalogId">
                <template slot-scope="scope">{{ scope.$index + 1 }}</template>
            </el-table-column>
            <el-table-column align="center" prop="catalogName" label="编目名称" min-width="80">
                <template slot-scope="scope">
                    <el-input v-if="scope.row.isEdit" v-model="scope.row.catalogName" placeholder="请输入内容"></el-input>
                    <span v-else>{{ scope.row.catalogName }}</span>
                </template>
            </el-table-column>
            <el-table-column align="center" min-width="100px" label="操作" class-name="small-padding fixed-width">
                <template v-slot="scope">
                    <el-button size="mini" type="text" v-if="!scope.row.isEdit" @click="updateRow(scope.row)">编辑</el-button>
                    <el-button size="mini" type="text" v-if="!scope.row.isEdit" @click="deleteRow(scope.row)">删除</el-button>
                    <el-button size="mini" type="text" v-if="scope.row.isEdit"
                        @click="handleAddRoot(scope.row)">保存</el-button>
                    <el-button size="mini" type="text" v-if="scope.row.isEdit"
                        @click="cancelRow(scope.row, scope.$index)">取消</el-button>
                    <!-- <el-button size="mini" type="text" @click="handleUpdate(scope.row)">上移</el-button>
                    <el-button size="mini" type="text" @click="handleUpdate(scope.row)">下移</el-button> -->
                </template>
            </el-table-column>
        </el-table>
        <div slot="footer" class="dialog-footer">
            <el-button type="primary" @click="save">保存</el-button>
            <el-button type="info" @click="close">取消</el-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
    name: "dialogRootCatalog",
    props: ['root_dialog_open', 'rootList', 'rootTableKey'],
    // data() {
    //     return {
    //         dialog_list: []
    //     }
    // },
    methods: {
        // 加一行空行
        addRow() {
            this.$emit('readyAddRoot')
        },
        // 关闭弹窗
        close() {
            this.$emit('closeDialog')
        },
        save() { },
        // 提交新建或编辑
        handleAddRoot(row) {
            if (row.isAdd) {
                this.$emit('addRoot', row)
            } else {
                this.$emit('editCatalog', row)
            }
        },
        deleteRow(row) {
            this.$emit('deleteRoot', row)
        },
        updateRow(row) {
            this.$emit('updateRoot', row)
        },
        cancelRow(row, index) {
            this.$emit('cancelRoot', row, index)
        }
    }
}
</script>

<style lang="less" scoped>
.btn_add {
    text-align: right;
    padding: 0 0 10px 0;
    color: #82ceff;
    cursor: pointer;
}

/deep/.el-input__inner {
    border: #1B6CAC 1px solid;
    border-radius: 3px;
    background-color: #092864;
    color: #fff;
}

/deep/.el-textarea .el-input__count {
    background-color: #092864;
}

/deep/ .el-button--info,
.el-button--info:focus,
.el-button--info:hover {
    background: #1f499b;
    border: 1px solid #045b9f;
}
</style>