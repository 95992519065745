<template>
  <div class="container">
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      label-width="120px"
      :validate-on-rule-change="false"
    >
      <el-form-item label="数据源类型" prop="dsTypeId">
        <el-select
          :disabled="edit"
          v-model="form.dsTypeId"
          placeholder="请选择"
          @change="onSourceTypeDidChange"
          @visible-change="getDatasource"
        >
          <el-option
            v-for="item in dataSourceType"
            :key="item.id"
            :label="item.typeName"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="数据源名称" prop="dsName">
        <el-input v-model="form.dsName" placeholder="请输入"></el-input>
      </el-form-item>

      <el-form-item label="所属部门" prop="deptId">
        <el-select
          v-model="form.deptId"
          placeholder="请选择"
          @visible-change="getDeptData"
        >
          <el-option
            v-for="item in deptList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="服务器名称" prop="serverId">
        <el-select
          v-model="form.serverId"
          placeholder="请选择"
          @visible-change="getServerList"
        >
          <el-option
            v-for="item in serverSource"
            :key="item.id"
            :label="item.serverName"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>

      <!-- 动态增减 -->
      <!-- <el-form-item label="数据库IP" prop="host">
        <el-input v-model="form.host" placeholder="请输入"></el-input>
      </el-form-item>

      <el-form-item label="端口号" prop="port">
        <el-input v-model="form.port" placeholder="请输入"></el-input>
      </el-form-item>

      <el-form-item label="数据库名称" prop="database">
        <el-input v-model="form.database" placeholder="请输入"></el-input>
      </el-form-item>

      <el-form-item label="字符集编码" prop="encoding">
        <el-select v-model="form.encoding" placeholder="请选择">
          <el-option
            v-for="item in dataSourceType"
            :key="item.name"
            :label="item.name"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="用户名" prop="username">
        <el-input v-model="form.username" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="密码" prop="password">
        <el-input
          type="password"
          placeholder="请输入"
          v-model="form.password"
          show-password
          auto-complete="new-password"
        ></el-input>
      </el-form-item> -->
      <el-form-item
        v-for="item in extraForm"
        :label="item.colDescription"
        :key="item.colName"
        :prop="`linkParams.${item.colName}`"
      >
        <el-select
          v-if="item.isSelect"
          v-model="form.linkParams[item.colName]"
          placeholder="请选择"
        >
          <el-option
            v-for="item in item.defaultValue"
            :key="item"
            :label="item"
            :value="item"
          ></el-option>
        </el-select>
        <el-input
          v-else
          v-model="form.linkParams[item.colName]"
          placeholder="请输入"
        ></el-input>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="submit">保存</el-button>
        <el-button type="primary" @click="testDB">测试</el-button>
        <!-- <el-button @click="resetForm('ruleForm')">取消</el-button> -->
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {userDepts} from '@/api/system/dept'
import {
  queryDatasource,
  linkparam,
  serverList,
  addData,
  update,
  test,
} from '@/api/system/datasource'

// 固定字段校验
const baseRules = {
  dsTypeId: [
    {
      required: true,
      message: '请选择数据源类型',
    },
  ],
  dsName: [
    {
      required: true,
      message: '请输入数据源名称',
    },
  ],
  deptId: [
    {
      required: true,
      message: '请选择部门',
    },
  ],
  serverId: [
    {
      required: true,
      message: '请选择服务器名称',
    },
  ],
}

const initialForm = {
  deptId: '',
  dsTypeId: '',
  dsName: '',
  serverId: '',
  linkParams: {},
}

export default {
  props: ['originData'],
  data() {
    return {
      form: {
        ...initialForm,
      },
      edit: false,
      extraForm: [],
      rules: baseRules,
      dataSourceType: [
        // {
        //   typeName: 'MYSQL',
        //   id: 1,
        // },
        // {
        //   typeName: 'ORACLE',
        //   id: 2,
        // },
        // {
        //   typeName: 'POSTGRESQL',
        //   id: 3,
        // },
        // {
        //   typeName: 'SQLSERVER',
        //   id: 4,
        // },
        // {
        //   typeName: 'SFTP',
        //   id: 5,
        // },
        // {
        //   typeName: 'FTP',
        //   id: 6,
        // },
      ],
      deptList: [],
      serverSource: [],
    }
  },
  created() {
    // console.log(this.$props.originData)
    // if (this.$props.originData) {
    // }
    this.getDatasource()
    this.getDeptData()
    this.getServerList()
  },
  methods: {
    // 获取数据源
    getDatasource() {
      // 在进入或者下拉时判断是否有数据
      // if (this.dataSourceType && this.dataSourceType.length > 0) return
      // queryDatasource()
      //   .then((res) => {
      //     console.log(res)
      //     this.dataSourceType = res.data
      //   })
      //   .catch((err) => {
      //     console.log(err)
      //   })
      const {datasource} = this.$store.getters
      if (!datasource || datasource.length == 0) {
        this.$store.dispatch('dict/loadDatasource').then((data) => {
          this.dataSourceType = data
        })
      } else {
        this.dataSourceType = datasource
      }
    },
    //获取部门下拉数据
    getDeptData() {
      if (this.deptList && this.deptList.length > 0) return
      userDepts()
        .then((res) => {
          // console.log(res)
          this.deptList = res.data
        })
        .catch((e) => {})
    },
    // 获取服务器列表
    getServerList() {
      if (this.deptList && this.deptList.length > 0) return
      serverList()
        .then((res) => {
          this.serverSource = res.data
        })
        .catch((e) => {
          console.log(e)
        })
    },
    // 数据类型变化
    onSourceTypeDidChange(id) {
      const item = this.dataSourceType.find((v) => v.id == id)
      if (item) {
        this.queryShowKeys({dataSourceType: item.typeName})
      }
    },
    // 查询数据源显示字段
    queryShowKeys(data, existParam) {
      linkparam(data)
        .then((res) => {
          const extraRules = {}
          const defaultLinkparam = {}
          res.data.forEach((v) => {
            v.isSelect = v.defaultValue instanceof Array
            defaultLinkparam[v.colName] = v.isSelect ? '' : v.defaultValue
            const rule = [
              {
                required: !!v.required,
                message: `${v.isSelect ? '请选择' : '请输入'}${
                  v.colDescription
                }`,
              },
            ]
            // const extraRule = this.regForm(v.colName)
            // extraRule && rule.push(extraRule)
            extraRules[`linkParams.${v.colName}`] = rule
          })
          this.extraForm = res.data
          this.rules = {...baseRules, ...extraRules}
          this.form.linkParams = existParam || defaultLinkparam
        })
        .catch((e) => {
          // console.log('eee', e)
        })
    },
    // 提交保存
    submit() {
      // console.log('submit', this.form)
      this.$refs.form.validate((success) => {
        if (success) {
          const data = {...this.form}
          data.linkParams = JSON.stringify(data.linkParams)
          const promise = this.edit ? update(data) : addData(data)
          promise
            .then((res) => {
              // console.log(e)
              this.$emit('refresh')
              // this.form = {...initialForm}
              this.$notify({
                type: 'success',
                title: '成功',
                message: res.message || '保存成功',
              })
            })
            .catch((e) => {
              this.$notify({
                type: 'error',
                title: '失败',
                message: e.message || '保存失败',
              })
            })
        }
      })
    },
    testDB() {
      console.log('test')
      this.$refs.form.validateField(
        [
          'dsTypeId',
          'port',
          'host',
          'database',
          'userName',
          'password',
          'encoding',
        ],
        (success) => {
          if (success) return
          const {dsTypeId, linkParams} = this.form
          const dataSourceType = this.dataSourceType.find(
            (v) => v.id == dsTypeId
          )?.typeName
          test({dataSourceType, ...linkParams})
            .then((res) => {
              console.log('test', res)
              this.$notify({
                title: '成功',
                message: res.message || '测试通过',
                type: 'success',
              })
            })
            .catch((e) => {
              this.$notify({
                title: '失败',
                message: e.message || '测试失败',
                type: 'error',
              })
            })
        }
      )
    },
    // 校验
    regForm(type) {
      switch (type) {
        case 'host':
          return {
            validator: (_, value, callback) => {
              const words = value.split('.')
              const err = new Error('请输入正确的ip地址')
              const reg = /^[0-9]{1,3}$/
              for (const word of words) {
                if (!reg.test(word)) {
                  callback(err)
                  return
                }
                const pInt = parseInt(word)
                if (pInt < 0 || pInt > 255) {
                  callback(err)
                  return
                }
              }
            },
            trigger: 'blur',
          }
        case 'port':
          return {
            pattern: /^[0-9]+$/,
            message: '请输入正确的端口号',
            trigger: 'blur',
          }
        default:
      }
    },
  },
  watch: {
    originData: {
      immediate: true,
      handler(newData) {
        if (!newData) return
        const linkParams = JSON.parse(newData.linkParams)
        this.form = {...newData}
        this.edit = true
        this.queryShowKeys({dataSourceType: newData.dsTypeName}, linkParams)
      },
    },
  },
}
</script>

<style lang="less" scoped>
.container {
  padding: 50px 30px 0;
  max-width: 600px;
  min-width: 400px;
}
</style>