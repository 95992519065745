import Vue from 'vue'
import VueRouter from 'vue-router'
import {routesList} from '@/router/routes'
import {setToken, getAccessToken} from '@/utils/auth'
// import {loginUrl} from '@/utils/constants'

const loginUrl = window.VUE_APP_LOGIN_URL || process.env.VUE_APP_LOGIN_URL
Vue.use(VueRouter)

// 防止连续点击多次路由报错
let routerPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch((err) => err)
}

const router = new VueRouter({
  mode: 'history',
  routes: routesList,
})

router.beforeEach((to, from, next) => {
  // debugger
  if (!getAccessToken()) {
    const {accessToken, refreshToken, updatePassword, ...rest} = to.query || {}
    if (accessToken) {
      setToken({accessToken, refreshToken, updatePassword})
      next({...to, query: rest})
      return
    }
    window.location.href = `${loginUrl}?redirect=${window.location.href}`
    return
  }
  if (to.path === '/login') {
    if (getAccessToken()) {
      next({path: '/exchange'})
      return
    }
    window.location.href = `${loginUrl}?redirect=${window.location.href}`
    return
  }
  next()
})

export default router
