<template>
  <div class="app-container">
    <navbar />
    <div class="page_contain">
      <!-- 左侧编目维度 -->
      <div class="database_dimension">
        <div>
          <el-input
            key="search"
            v-model="filterName"
            placeholder="请输入内容"
            clearable
            size="middle"
            prefix-icon="el-icon-search"
            style="margin: 20px 0; width: 85%; left: -8px"
          />
          <svg-icon icon-class="add" class="btn_add" @click="addDatasource" />
        </div>
        <div>
          <template>
            <div
              v-for="(item, index) in dataList"
              :key="item.dsId"
              class="listItem"
              @click="selectItem(index)"
            >
              <div :class="currentSelect === index ? 'selectItem' : 'item'">
                {{ item.dsName }}
              </div>
              <div class="operator">
                <svg-icon
                  icon-class="edit"
                  class="normal_btn"
                  @click.stop="editDatasource(index)"
                />

                <el-popconfirm
                  :title="`确认删除${item.dsName}吗？`"
                  @confirm="deleteDatasource(index)"
                >
                  <svg-icon
                    slot="reference"
                    icon-class="delete"
                    class="normal_btn"
                    @click.native.stop
                  />
                </el-popconfirm>
              </div>
            </div>
          </template>
        </div>
      </div>

      <!-- 右侧列表 -->
      <div class="content_dimension">
        <template v-if="type === showType.DETAIL">
          <detail :detailData="dataList[currentSelect]" />
        </template>
        <template v-else-if="type === showType.EDIT">
          <add
            :originData="dataList[currentSelect]"
            key="edit"
            v-on:refresh="getLists"
          />
        </template>
        <template v-else>
          <add key="add" v-on:refresh="getLists" />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from '@/components/common/Navbar.vue'
import detail from './components/detail.vue'
import add from './components/add.vue'
import {getList, deleteData} from '@/api/system/datasource'
import {debounce} from './utils'
export default {
  components: {
    Navbar,
    detail,
    add,
  },
  allList: [],

  data() {
    return {
      showType: {
        DETAIL: 'detail',
        EDIT: 'edit',
        ADD: 'add',
      },
      filterName: '',
      dataList: [],
      currentSelect: -1,
      type: '',
    }
  },
  created() {
    this.getLists()
  },
  // destroyed() {
  //   this.$off('refresh')
  // },
  methods: {
    getLists() {
      getList()
        .then((res) => {
          console.log(res)
          this.allList = res.data
          this.filterName = ''
          this.dataList = res.data
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 新增数据源
    addDatasource() {
      this.currentSelect = -1
      this.type = this.showType.ADD
    },
    // 编辑数据源
    editDatasource(index) {
      this.currentSelect = index
      this.type = this.showType.EDIT
      // console.log('edit')
    },
    //删除数据源某一条
    deleteDatasource(index) {
      const item = this.dataList[index]
      if (!item) return
      deleteData({dsId: item.dsId})
        .then((res) => {
          this.dataList.splice(index, 1)
          let newIdx = index - 1
          if (newIdx < 0) {
            if (this.dataList.length >= index) {
              newIdx = index
            } else {
              this.currentSelect = -1
              this.type = this.showType.ADD
              return
            }
          }
          this.currentSelect = newIdx
          this.$notify({
            title: '成功',
            message: res.message || '删除成功',
            type: 'success',
          })
        })
        .catch((e) => {
          this.$notify({
            title: '失败',
            message: e.message || '删除失败',
            type: 'error',
          })
        })
    },
    selectItem(index) {
      this.currentSelect = index
      this.type = this.showType.DETAIL
    },
  },
  watch: {
    filterName(newValue, oldValue) {
      console.log(newValue, oldValue)
      if (!newValue) {
        this.dataList = this.allList
      }
      debounce(() => {
        const reg = new RegExp(`${newValue}`)
        this.dataList = this.allList.filter((v) => reg.test(v.dsName))
      })()
    },
  },
}
</script>

<style lang="less" scoped>
.page_contain {
  position: relative;
  height: 930px;
  margin: 0 20px;
}

/* 编目维度 */
.database_dimension {
  text-align: center;
  position: absolute;
  left: 0;
  padding: 0 10px !important;
  background-color: #06346e;
  /* border: 3px solid #05559b; */
  border-radius: 5px;
  height: 100%;
  // width: 20%;
  width: 300px;
  background: url('../../assets/svg/bg_tree.svg');
  background-size: 100% 100%;

  .listItem {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 5px 10px;
    color: #fff;
    font-size: 1rem;
    height: 40px;
    line-height: 40px;
    .item {
      width: 70%;
      text-align: left;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .selectItem {
      color: #82ceff;
      .item;
    }
    .normal_btn {
      margin: 5px;
    }

    .operator {
      display: none;
    }
  }

  .listItem:hover {
    background-color: #82ceff80;
  }

  .listItem:hover .operator {
    display: block;
  }
}

.content_dimension {
  text-align: center;
  position: absolute;
  left: 320px;
  padding: 0 10px !important;
  background-color: #06346e;
  /* border: 3px solid #05559b; */
  border: 1px solid #067cdb;
  border-radius: 5px;
  height: 100%;
  width: calc(100% - 330px);
  min-width: 500px;
  // background: url('../../assets/svg/bg_tree.svg');
  // background-size: 100% 100%;
}

// /deep/ .el-popconfirm {
//   color: #606266;
// }

.btn_add {
  font-size: 21px;
  color: #82ceff;
}
</style>