import DataList from '@/pages/container/data-list/index.vue'
import Login from '@/pages/login.vue'
import Portal from '@/pages/portal/index.vue'
import DatadirManager from '@/pages/datadir-manager/index.vue'
import Catalog from '@/pages/catalog'
import Datadir from '@/pages/datadir'

export const routesList = [
  /* 门户首页 */
  {
    path: '/',
    redirect: '/exchange',
  },
  // /* 登录 */
  // {
  //     path: '/login',
  //     component: Login,
  // },
  // {
  //     path: '/exchange/index',
  //     component: DataList,
  // },
  {
    meta: {title: '数据目录'},
    path: '/exchange',
    component: Portal,
  },
  // 目录管理
  {
    path: '/exchange/datadir-manager',
    component: DatadirManager,
  },
  // 编目维度管理
  {
    meta: {title: '编目管理'},
    path: '/catalog',
    component: Catalog,
  },
  {
    meta: {title: '目录管理'},
    path: '/datadir',
    component: Datadir,
  },
  {
    meta: {title: '数据源管理'},
    path: '/datasource',
    component: () => import('@/pages/datasource/index.vue'),
  },

  // 交换共享中心
  {
    meta: {title: '平台监控'},
    path: '/exch-server',
    component: () => import('@/pages/exch-server/index.vue'),
  },
  {
    meta: {title: '订阅管理'},
    path: '/subscribe',
    component: () => import('@/pages/subscribe-manager/index.vue'),
  },
]
